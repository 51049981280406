import React from 'react';
import classNames from "classnames";
import Cookies from 'universal-cookie';
import {connect} from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Hotkeys from 'react-hot-keys';
import $ from 'jquery';
import loadScript from 'load-script';
//css and images
import './header.css';
import '../CheckoutPanel/checkoutPanel.scss';
import undo from './Undo.svg';
import redo from './Redo.svg';
import logo from './artfia_logo.png';
import download from './download_icon.png';
import cancel from './cancel.png';
import facebook_icon from './facebook.svg';
// import './twitter.svg';
import linkedin_icon from './linkedin.svg';
import pdf_icon from './pdf_icon.svg';
import png_icon from './png_icon.svg';
import jpg_icon from './jpg_icon.svg';
import tumblr_icon from './tumblr.svg';
import './up-arrow.svg';
import down_arrow from './publish_arrow.png';
import whatsapp_icon from './whatsapp.svg';
//import './google-plus.svg';
import placeholder from './placeholder.svg';
import verifyemail_icon from './Verify-Email.png'
import alarm from './alarm.png'
import alarm_download from './download.png'
import alarm_image from './image.png'
import alarm_link from './link.png'
import alarm_network from './network.png'
import alarm_printer from './printer.png'
import pinterest_icon from './pinterest.png';
import slack_icon from './slack_icon.png';
import dropbox_icon from './dropbox_icon.png';
import wechat_icon from './wechat.png';
import tick_icon from './tick.png';
import checkout_icon from './checkout.svg';
import save_icon from './save_icon.png';
import share_icon from './share_button.png';

import share_success from './share_success.gif';
//views
import IconButton from "../IconButton";
import IconRightButton from "../IconRightButton";
import FileDropdown from "../FileDropdown";
import FormPage from "../FormPage";
import Modal from '../Modal';
import SharePermission from "../SharePermission";
import TumblrShareButton from './TumblrShareButton';
import { addAddThisScript } from '../../utilities/addthis';
import { addPinterestScript } from '../../utilities/pinterest';

import {
    addElementByData,
    addFolder,
    applyWordwrapHint,
    bindProject,
    changeElementMenu,
    checkSocialMediaToken,
    checkUserSession,
    checkUserVerifyStatus,
    clearActiveElements,
    closeElementMenu,
    closeSideBar,
    exportProject,
    getFolderList,
    getProjectShare,
    loadCanvasData,
    // loadDefault,
    promptLogin,
    promptSlackLogin,
    redoHistory,
    resizePage,
    saveDesignerTemplate,
    savePreview,
    saveProject,
    sendVerificationEmail,
    setAccessLevel,
    setAlertBox,
    setAuthStatus,
    setIsPrintable,
    setModalRandom,
    setPartnershipLink,
    setPartnershipParam,
    setPaymentTerm,
    setProjectFolder,
    setProjectName,
    setProjectShare,
    setProjectShareTeam,
    setProjectTarget,
    setProjectWaterMark,
    setSelectFromTeamID,
    setTextEditMode,
    // setVerifyStatus,
    setZoomScaleByContainerSize,
    slackUploadFile,
    toggleItemVisibility,
    undoHistory
} from '../../actions';
import {fetchQuote, resetCheckoutStep, setCheckoutError} from '../../actions/checkoutActions';

import {LinkedinShareButton,} from 'react-share';
//import * as dev from '../../variable.js'
//import * as dev from '../../variable.js'

var QRCode = require('qrcode.react');
var Mixpanel = require('mixpanel');
var mixpanel = Mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`);

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';


class ProjectControlPanel extends React.PureComponent {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = ({
            downloading: false,
            next_btn_style: ["next_btn"],
            add_folder_btn_style: ["add_folder_btn", "disabled"],
            value: '',
            copied: false,
            project_title_class: '',
            projecttitle: props.project_name,
            resize_err_msg: '',
            resize_unit: "px",
            resize_width: "",
            resize_height: "",
            resize_modal_class: '',
            showList: 'publish_item',
            isResizeButtonDisabled: true,
            publish_name: '',
            publish_icon: '',
            facebookPage: [],
            selectedfbpage: '',
            designLink: window.location.protocol + '//' + window.location.host + '/design-builder/' + this.props.projectCode,
            modalIsOpen: false,
            facebook_page_post_link: 'https://facebook.com',
            facebookGroups: [],
            selectedfbgroup: '',
            fb_access_token: '',
            ready_to_show: false,
            modalTimer: null,
            anim: "",
            pinterest_session: null,
            pinterest_last_userid: null,
            individual_permission: [],
            team_permission: [],
            invitation_email: "",
            invitation_permission: "write",
            slack_channel_list: [],
            photo: '',
            name: '',
            slackCode: '',
            slack_access_token: '',
            slack_code: props.slack_code,
            share_disabled: false,
            whatsapp_link: '',
            wechat_link: '',
            folderList: null,
            current_time: "",
            invitation_btn_css: 'invitation_btn',
            showVerification: false,
            showPdfSelection: false,
            showCreditCardBox: false,
            showDownloadBtn: false,
            showPayDownloadBtn: false,
            showSharePermission: false,
            is_mobile: props.is_mobile,
            partnership_callback: null,
            new_folder_name: "",
            add_folder_loading: false,
            updateSharePermissionTimer: null,
            current_lang: props.current_lang,
            payment_term: props.payment_term,
        });
        this.setProjectWaterMark = this.props.setProjectWaterMark;
        this.handleShare = this.handleShare.bind(this);
        this.linkToLoginPage = this.linkToLoginPage.bind(this);
        this.alarmLogin = this.alarmLogin.bind(this);
        this.handleCloseAlertBox = this.handleCloseAlertBox.bind(this);

        this.handlePublish = this.handlePublish.bind(this);
        this.handlePrintClicked = this.handlePrintClicked.bind(this);
        this.handleDownloadPrintingFileClicked = this.handleDownloadPrintingFileClicked.bind(this);
        this.handleSaveClicked = this.handleSaveClicked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancelShare = this.handleCancelShare.bind(this);
        this.handleCancelDownload = this.handleCancelDownload.bind(this);
        this.handleCancelPublish = this.handleCancelPublish.bind(this);
        this.handleDownloadClicked = this.handleDownloadClicked.bind(this);
        this.designerSave = this.designerSave.bind(this);
        this.saveDesignerTemplate = props.saveDesignerTemplate;
        this.bindProject = props.bindProject;
        this.checkUserVerifyStatus = props.checkUserVerifyStatus;
        this.quitEngine = this.quitEngine.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleTitleInput = this.handleTitleInput.bind(this);
        this.fbLogin = this.fbLogin.bind(this);
        this.getfbPageList = this.getfbPageList.bind(this);
        this.getfbGroupList = this.getfbGroupList.bind(this);
        this.updateSharePermission = this.updateSharePermission.bind(this);
        this.slackLogin = this.slackLogin.bind(this);
        this.showUploadfile = this.showUploadfile.bind(this);
        this.sendVerificationEmail = this.sendVerificationEmail.bind(this);
        this.handleShowPdfType = this.handleShowPdfType.bind(this);
        this.handlePdfWatermark = this.handlePdfWatermark.bind(this);
        this.handleDownloadProcess = this.handleDownloadProcess.bind(this);
        this.saveJP = this.saveJP.bind(this);
        this.setPartnershipLink = this.props.setPartnershipLink.bind(this);
        this.setPartnershipParam = this.props.setPartnershipParam.bind(this);
        this.handleAddFolder = this.handleAddFolder.bind(this);
        this.handleAddFolderBtn = this.handleAddFolderBtn.bind(this);
        this.addFolder = this.props.addFolder.bind(this);
        this.createTeam = this.createTeam.bind(this);
        this.invitationEmailChange = this.invitationEmailChange.bind(this);
        this.invitationPermissionChange = this.invitationPermissionChange.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
        this.handleShareBtnHover1 = this.handleShareBtnHover.bind(this, 1);
        this.handleShareBtnHover0 = this.handleShareBtnHover.bind(this, 0);
        this.sendInvitationTeam = this.sendInvitationTeam.bind(this);
        this.fbpagepost = this.fbpagepost.bind(this);
        this.slackUploadFilepost = this.slackUploadFilepost.bind(this);
        this.hotkeyPressed = this.hotkeyPressed.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.handleFileButtonClicked = this.handleFileButtonClicked.bind(this);
        this.handleSavePreview = this.handleSavePreview.bind(this, null, false);
        this.handleMoveFolderClicked = this.handleMoveFolderClicked.bind(this);
        this.cancelResizeBox = this.cancelResizeBox.bind(this, 0);
        this.handleResize = this.handleResize.bind(this);
        this.handleResizeClicked = this.handleResizeClicked.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.slack_code) {
            var url_string = window.location.href; //window.location.href
            try{
                var url = new URL(url_string);
                var code = url.searchParams.get("code");
                //console.log(code);
                const cookies = new Cookies();
                if (code != "") {
                    cookies.set("slack_code", code);
                    window.close();
                }
                this.setState({slackCode: code})
            } catch(e) { console.error('componentDidMount', e); }
        }

        if (!this.props.currentSaveId || !(this.props.currentSaveId instanceof String)) {
            this.setState({autoSave: setInterval(this.autoSave.bind(this), 3000)});
        }

        /* -------fb init ---------*/
        window.fbAsyncInit = function () {
            try{
                window.FB.init({
                    appId: '1224695541025559', // prod: 571635203260183 //dev: 339958643228897
                    cookie: true,
                    xfbml: true,
                    version: 'v14.0'
                });
                // eslint-disable-next-line
            } catch(e) {

            }
        }.bind(this);

        // Load the SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        /*---------- end of fb init ----------*/

        /*---------- twitter ------------- */
        /*window.twttr = (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {};
            if (d.getElementById(id)) return t;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://platform.twitter.com/widgets.js";
            fjs.parentNode.insertBefore(js, fjs);

            t._e = [];
            t.ready = function (f) {
                t._e.push(f);
            };

            return t;
        }(document, "script", "twitter-wjs"));
        window.twttr.ready();*/
        /*----------- end of twitter ----------- */

        /*----------- pinterest --------------*/

        /*window.pAsyncInit = function () {
            window.PDK.init({
                appId: "5020673352658897058", // Change this
                cookie: true
            });
        };

        (function (d, s, id) {
            var js, pjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "//assets.pinterest.com/sdk/sdk.js";
            pjs.parentNode.insertBefore(js, pjs);
        }(document, 'script', 'pinterest-jssdk'));*/

        /*----------- end of pinterest --------*/

        /*----------- dropbox -------------*/

        loadScript(DROPBOX_SDK_URL, {
            attrs: {
                id: SCRIPT_ID,
                'data-app-key': "72bzka2dzjrx507"
            }
        });

        /*---------- end of dropbox ---------*/

        this.updateSharePermission();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.updateSharePermissionTimer) {
            clearInterval(this.state.updateSharePermissionTimer);
        }
        if (this.state.autoSave) {
            clearInterval(this.state.autoSave);
        }
    }

    updateSharePermission() {

        const self = this;

        if (this.props.engineReady) {
            this.props.getProjectShare(function (res) {
                if(!self._isMounted) return;
                if (res.status === "Y") {
                    var individuals = [];
                    var teams = [];

                    const individual_result = res.result.permission.individual;
                    const team_result = res.result.permission.team;

                    for (let i = 0; i < individual_result.length; i++) {
                        individuals.push({
                            id: individual_result[i].id,
                            name: individual_result[i].nickname,
                            permission: individual_result[i].permission,
                            scope: "individual",
                            email: individual_result[i].email,
                            photo: individual_result[i].photo,
                        });
                    }
                    for (let i = 0; i < team_result.length; i++) {
                        teams.push({
                            id: team_result[i].id,
                            name: team_result[i].name,
                            permission: team_result[i].permission,
                            scope: "team",
                            email: team_result[i].email,
                            photo: team_result[i].team_logo_path,
                        });
                    }
                    self.setState({
                        individual_permission: individuals,
                        team_permission: teams,
                        share_disabled: (self.props.permission === "read"),
                    });
                } else {
                    self.setState({
                        share_disabled: true,
                    });
                }

            });
        } else {
            setTimeout(function () {
                self.updateSharePermission();
            }, 1000);
        }
    }

    /*
    doSomethingBeforeUnload(){
        console.log("doSomethingBeforeUnload");

        this.handleSavePreview(function(){
            console.log("Preview Save Success");
        });

    }
    */
    UNSAFE_componentWillMount() {
        const self = this;
        //Modal.setAppElement('body');

        var timer = setTimeout(function () {
            /* self.alarmLogin(); */
            if (self._isMounted) {
                self.setState({
                    anim: "animate_icon"
                });
            } else {
                clearTimeout(timer);
            }
        }, 120000);

        self.props.setProjectName('');
        self.setState({
            ready_to_show: true,
            modalTimer: timer
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        //if (this._isMounted) {
        if (props.project_name) {
            if (this.state.projecttitle!==props.project_name)
            {
                this.setState({projecttitle: props.project_name});
            }
        } else if (this.state.project_title_class) {
            this.setState({project_title_class: ''})
        }

        if (props.showResizeBox && this.state.resize_modal_class!=='modal_active') {
            this.setState({resize_modal_class: 'modal_active'});
        } else if (this.state.resize_modal_class !== '') {
            this.setState({resize_modal_class: ''});
        }
        /*if (props.currentSaveId && props.currentSaveId.constructor.name === "String") {
            alert(props.currentSaveId);
        }*/

        //let newDate = new Date();
        if (!this.state.current_time) {
            this.setState({current_time: Date.now()});
        }
        //}

    }

    /*twitterLogin() {
        window.twttr.widgets.load();
    }*/

    autoSave() {
        if (!this.props.isSaving && this.props.currentSaveId > this.props.lastSaveId && this.props.engineReady && !this.state.downloading && this._isMounted) {
            if (this.state.partnership_callback != null) {
                this.handleSaveClicked(this.state.partnership_callback);
            } else {
                this.handleSaveClicked(null);
            }
        }
    }

    logData() {
        console.log(JSON.parse(JSON.stringify(this.props.pages)));
        console.log(JSON.stringify(this.props.pages));

    }

    handleFileButtonClicked() {
        this.props.toggleItemVisibility('fileDropdown');
    }

    handleSaveClicked(callback) {
        if (!this._isMounted) return;
        //project_title_input
        //console.log(JSON.stringify(this.props.pages));;
        //console.log(JSON.stringify(this.props));;
        //debugger;
        //alert(this.props);
        this.props.applyWordwrapHint();
        var projectName = document.getElementsByClassName("project_title_input");
        if (projectName != null && projectName.length) {
            projectName = projectName[0].value;
        } else {
            projectName = "";
        }
        //console.log("projectName: "+projectName);

        var data = {};
        var font_data = [];
        data.pages = this.props.pages ? JSON.parse(JSON.stringify(this.props.pages)) : [];
        data.pages.map(page => {
            if (page.elements && Array.isArray(page.elements))
            {
                page.elements = page.elements.filter((element) => {
                    if (element.type === "text" && font_data.indexOf(element.textFontFamily) === -1 && element.textFontFamily.indexOf('_') > -1) {
                        font_data.push(element.textFontFamily);
                    }
                    return element.type !== 'group' || !element.isVirtual;
                });
            } else {
                page.elements = [];
            }
            return page;
        });

        var width = 0;
        var height = 0;

        $('.page').each((index, element) => {
            if (index == 0) {
                var zoom_element = document.getElementsByClassName("zoom_text")[0];
                //var percent = (parseFloat(zoom_element.value)).toFixed(0);
                var percent = (parseFloat(zoom_element.value));
                width = element.offsetWidth * (100 / percent);
                height = element.offsetHeight * (100 / percent);
            }
        });
        // console.log(width+"x"+height);
        // console.log(this.props.permission);
        // console.log(this.props.recentlyUsedImages);
        // console.log(this.props.recentlyUsedPhotos);
        try{
            this.props.saveProject(data, this.props.currentSaveId, projectName, width, height, this.props.permission, font_data, this.props.brand_token, this.props.partnership_link, this.props.recentlyUsedImages, this.props.recentlyUsedPhotos, callback);
        } catch (e) {
            console.log(e)
        }

        /*
        this.handleSavePreview(function(){

        }, true);
        */
    }

    saveJP() {
        const self = this;

        const cookies = new Cookies();
        var code = cookies.get('code');

        if (this._isMounted)
            window.addEventListener('message', event => {
                    if (!self._isMounted) return;
                    if ((event.origin === `${process.env.REACT_APP_JP_DOMAIN}` || event.origin === `${process.env.REACT_APP_JPAU_DOMAIN}`) && self.props.partnership_link == 0) {
                        if (event.data === "Y") {
                            self.props.setPartnershipLink("1");
                            self.handleSaveClicked(null);
                            alert("Success");
                            window.removeEventListener('message', this);
                        }
                    } else {
                        // ProjectControlPanel message https://engine.artfia.com undefined LO::APPID {"type":"LO::APPID"}
                        console.error('ProjectControlPanel message', event.origin, JSON.stringify(self.props.partnership_link), self.props.partnership_link, JSON.stringify(event.data));
                        //console.log(`${process.env.REACT_APP_JP_DOMAIN}`);
                        //console.log(`${process.env.REACT_APP_JPAU_DOMAIN}`);
                    }
                }/*,
                {once: true}*/
            );

        if (!this.props.save_url) {
            console.error('ProjectControlPanel message no save_url', JSON.stringify(self.props.partnership_link), this.props.partnership_link, this.props.save_url);
        }
        window.open(this.props.save_url + '?code=' + code + "&param=" + self.props.partnership_param, 'Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no');
        /*
        if(self.props.callback_url.indexOf(":81")>0 || self.props.callback_url.indexOf("joinprint.com/")>0){
            window.open(`${process.env.REACT_APP_JPAU_SAVE}?code=`+code, 'Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no')
        }else{
            window.open(`${process.env.REACT_APP_JP_SAVE}?code=`+code, 'Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no')
        }
        */

    }

    designerSave(type) {

        this.props.toggleItemVisibility('fileDropdown');

        const cookies = new Cookies();
        var code = cookies.get('code');
        var pages = this.props.pages;
        const self = this;

        if (pages.length > 0) {

            this.saveDesignerTemplate(code, JSON.stringify({pages: pages}), type, this.props.width, this.props.height, function (res) {
                if (!self._isMounted) return;
                if (res.status === "Y") {
                    self.props.setAlertBox("Save Success");
                    self.props.toggleItemVisibility('alertBox');

                    if (res.access_level != "") {
                        if (parseInt(res.access_level) == 70) {
                            window.location = `${process.env.REACT_APP_API_HOST}`;
                        } else if (parseInt(res.access_level) > 70) {
                            self.quitEngine();
                        }
                    }

                } else {
                    self.props.setAlertBox("Save Fail");
                    self.props.toggleItemVisibility('alertBox');
                }
            });
        }

    }

    quitEngine() {

        const self = this;
        self.bindProject(function (res) {
            if (!self._isMounted) return;
            if (self.props.callback_url) {
                //window.location = self.props.callback_url;
                //alert("Generate Preview");
                //self.handleDownloadProcess("pdf", true);
                self.props.clearActiveElements();
                self.setState({
                    partnership_callback: function () {
                        if (!self._isMounted) return;
                        if (self.props.payment_term !== 'monthly') {
                            self.handleDownloadProcess("pdf", true);
                        } else {
                            window.location = self.props.callback_url;
                        }
                    }
                }, function () {
                    if (!self._isMounted) return;
                    self.handleSaveClicked(self.state.partnership_callback);
                });
                if (self.props.payment_term === 'monthly') {
                    window.location = self.props.callback_url;
                }
            } else {
                window.location = res;
            }

        }, true);
    }

    /*handleImport() {
        let input = prompt("Input:");
        let data = JSON.parse(input);
        if (data) {
            this.props.loadCanvasData(data);
        }
    }*/

    handleDownloadClicked() {
        const self = this;
        if (!this.props.engineReady) {
            //alert("Engine is loading. Please wait.");
            self.props.setAlertBox("Engine is loading. Please wait.");
            self.props.toggleItemVisibility('alertBox');
        } else if (this.props.authStatus === "member") {
            if (this.props.verifyStatus == "0") {
                this.checkUserVerifyStatus(function (res) {
                    if (res === "done") {
                        //debugger;
                        if (self.props.verifyStatus == "0") {
                            //self.props.verifyNickname;
                            // self.props.verifyEmail;
                            self.props.setAlertBox(self.props.current_lang === 'en' ? "Hi! Please verify your account first, mate!" : '你好！請先確認你的帳號！');
                            self.props.toggleItemVisibility('alertBox');
                            self.setState({showVerification: true});
                        } else {
                            self.setState({showList: 'publish_item'});
                            self.props.toggleItemVisibility('publishDropDown');
                            self.setState({showVerification: false});
                        }
                    }
                }, true);
            } else {
                this.props.toggleItemVisibility('downloadDropdown');
                this.setState({showPdfSelection: false});
                this.setState({showDownloadBtn: false});
                this.setState({showPayDownloadBtn: false});
                this.setState({showCreditCardBox: false});
                this.setState({showSharePermission: false});
            }
        } else {
            //waiman
            alert(this.props.current_lang === 'en' ? 'Please Login' : '請先登入');
            this.props.toggleItemVisibility('alarmLoginBox');

        }
        mixpanel.track('played_game');

    }

    handleDownload(fileType) {
        const self = this;
        var pdf_watermark_checkbox = $("#without-checkbox").prop("checked");
        self.props.setCheckoutError('');
        if (!!pdf_watermark_checkbox && fileType === "pdf" && (this.props.has_watermark)) {
            // Braintree legacy
            /*if (self.hostedFieldsInstance) {
                self.hostedFieldsInstance.tokenize(function (err) {
                    if (err) {
                        console.error(err.message);
                        self.props.setCheckoutError(err.message);
                    }
                });
            }*/
        } else {
            self.handleDownloadProcess(fileType, pdf_watermark_checkbox);
        }


    }

    handleCloseConfirmShare() {
        this.props.toggleItemVisibility('showConfirmShareBox');
    }

    handleDownloadProcess(fileType, pdf_watermark_checkbox) {
        this.props.toggleItemVisibility('downloadDropdown');
        var zoom_element = document.getElementsByClassName("zoom_text")[0];
        //var percent = (parseFloat(zoom_element.value)).toFixed(0);
        var percent = (parseFloat(zoom_element.value));
        //var ratio = 163/percent; //need get ratio
        // var ratio = 2.4;

        if (this.state.downloading) {
            if (process.env.NODE_ENV !== 'production') {
                console.log("Downloading");
            }
            return;
        }

        const self = this;

        var rand = Math.floor((Math.random() * 100));
        this.props.setModalRandom(rand);

        this.setState({
            next_btn_style: ["next_btn", "clicked"],
            partnership_callback: null,
        });

        var element = document.getElementsByClassName("page")[0];

        if (element !== undefined) {
            var width = element.offsetWidth * (100 / percent);
            var height = element.offsetHeight * (100 / percent);

            const cookies = new Cookies();
            var code = cookies.get('code');
            var api_token = cookies.get('api_token');

            this.setState({downloading: true});

            var param = {
                width: width,
                height: height,
                type: fileType,
                code: code,
                token: api_token,
                action: 'download',
            };

            const addthisPromise = addAddThisScript();
            this.props.exportProject(param, function (myJson) {
                if (myJson.status === "Y") {
                    self.setState({
                        downloading: false,
                        next_btn_style: ["next_btn"],
                    });
                    window._loq = window._loq || [];
                    window._loq.push(['tag', 'downloaded']);
                    if (fileType === 'png' || fileType === 'jpg') {
                        let result = window.open(myJson.image); //open image file (zip)
                        if (!result) alert('you have popup blocker turned on');

                        addthisPromise.then(() => {
                            self.props.toggleItemVisibility('showConfirmShareBox');
                        }).catch(() => {
                            self.props.setAlertBox("Exporting Succes");
                        });
                    } else {
                        /*if(self.props.callback_url == null && self.props.payment_term!=='monthly'){

                        }else */
                        if (self.props.payment_term === 'monthly') {
                            window.open(myJson.wo_pdf);
                        } else if (!self.props.callback_url) {
                            //window.open(myJson.url); //open pdf file
                            if (!!pdf_watermark_checkbox && fileType === "pdf") {
                                //console.log('no watermark pdf');
                                //console.log(myJson.wo_pdf);
                                window.open(myJson.wo_pdf); //download pdf file directly
                            } else {
                                //console.log('have watermark pdf');
                                //console.log(myJson.pdf);
                                window.open(myJson.pdf); //download pdf file directly
                            }
                            //self.props.toggleItemVisibility('publishDropDown');
                            //self.props.setAlertBox("<div>Download Success</div>");
                            //self.props.toggleItemVisibility('alertBox');
                            addthisPromise.then(() => {
                                self.props.toggleItemVisibility('showConfirmShareBox');
                            }).catch(() => {
                                self.props.setAlertBox("Exporting Succes");
                            });
                        } else if (self.props.callback_url) {
                            var now = Date.now();
                            window.location = self.props.callback_url + ((self.props.callback_url.indexOf('?') !== -1)?'':'?') + ((myJson.folder && myJson.folder!=='undefined')?"&folder=" + myJson.folder:'') + ((myJson.pages && myJson.pages!=='undefined')?"&pages=" + myJson.pages:'') + "&modified=" + now;
                        }

                    }
                } else if(myJson.error) {
                    self.setState({
                        downloading: false,
                    });
                    //self.props.toggleItemVisibility('showConfirmShareBox')
                    self.props.setAlertBox("Exporting Failed: "+myJson.error);
                    self.props.toggleItemVisibility('alertBox');
                } else {
                    //alert(myJson.msg);
                    self.setState({
                        downloading: false,
                        next_btn_style: ["next_btn"],
                    });
                    self.props.setAlertBox(myJson.msg);
                    self.props.toggleItemVisibility('alertBox');

                    if (myJson.msg === "Please login") {
                        self.props.toggleItemVisibility('alarmLoginBox');
                    }
                }
            });


        } else {
            self.setState({
                downloading: false,
                next_btn_style: ["next_btn"],
            });
        }
    }

    hotkeyPressed(keyName, e, handler) {
        if (document.activeElement && document.activeElement.tagName === 'INPUT') {
            return;
        }
        switch (handler.key) {
            // case "ctrl+shift+l":
            // case "command+shift+l":
            //     this.props.loadDefault();
            //     e.preventDefault();
            //     break;
            /*case "ctrl+shift+i":
            case "command+shift+i":
                this.handleImport();
                e.preventDefault();
                break;*/
            case "ctrl+z":
            case "command+z":
                this.props.undoHistory();
                e.preventDefault();
                break;
            case "ctrl+shift+z":
            case "command+shift+z":
                this.props.redoHistory();
                e.preventDefault();
                break;
            case "ctrl+s":
            case "command+s":
                this.handleSaveClicked(null);
                e.preventDefault();
                break;
            case "ctrl+shift+s":
            case "command+shift+s":
                this.logData();
                e.preventDefault();
                break;
        }
    }

    handleShare() {
        const self = this;
        if (!this.props.engineReady) {
            self.props.setAlertBox("Engine is loading. Please wait.");
            self.props.toggleItemVisibility('alertBox');
            //alert("Engine is loading. Please wait.");
        } else if (this.props.authStatus === "member") {
            if (this.props.verifyStatus == "0") {
                this.checkUserVerifyStatus(function (res) {
                    if (res === "done") {
                        if (self.props.verifyStatus == "0") {
                            self.props.setAlertBox(self.props.current_lang === 'en' ? "Hi! Please verify your account first, mate!" : '你好！請先確認你的帳號！');
                            self.props.toggleItemVisibility('alertBox');
                            self.setState({showVerification: true});
                        } else {
                            self.props.toggleItemVisibility('shareDropDown');
                        }
                    }
                }, true);
            } else {
                this.props.toggleItemVisibility('shareDropDown');

                if (!self.state.showSharePermission) {
                    if (this.state.updateSharePermissionTimer != null) {
                        //console.log("clear timer 1");
                        clearInterval(this.state.updateSharePermissionTimer);
                    }
                    var t1 = setInterval(function () {
                        //console.log("update share permission timer");
                        if (self.state.showSharePermission) {
                            self.updateSharePermission();
                        }
                    }, 2000);
                    //console.log("set timer");
                    self.setState({updateSharePermissionTimer: t1});

                } else {
                    if (this.state.updateSharePermissionTimer != null) {
                        //console.log("clear timer 2");
                        clearInterval(this.state.updateSharePermissionTimer);
                    }
                }
                self.setState({showSharePermission: !this.state.showSharePermission});

            }
        } else if (this.props.permission === "read") {
            //alert("You are not the project owner.");
            self.props.setAlertBox("You are not the project owner.");
            self.props.toggleItemVisibility('alertBox');
        } else {
            const alertmsg = self.props.current_lang === 'en' ? 'Please login first' : '請先登入';
            self.props.setAlertBox(alertmsg);
            self.props.toggleItemVisibility('alertBox');
        }

    }

    handlePublish() {
        const self = this;
        if (!this.props.engineReady) {
            //alert("Engine is loading. Please wait.");
            self.props.setAlertBox("Engine is loading. Please wait.");
            self.props.toggleItemVisibility('alertBox');
        } else if (this.props.authStatus === "member") {
            if (this.props.verifyStatus == "0") {
                this.checkUserVerifyStatus(function (res) {
                    if (res === "done") {
                        if (self.props.verifyStatus == "0") {
                            self.props.setAlertBox(self.props.current_lang === 'en' ? "Hi! Please verify your account first, mate!" : '你好！請先確認你的帳號！');
                            self.props.toggleItemVisibility('alertBox');
                            self.setState({showVerification: true});
                        } else {
                            self.setState({showList: 'publish_item'})
                            self.props.toggleItemVisibility('publishDropDown');
                        }
                    }
                }, true);
            } else {
                self.setState({showList: 'publish_item'});
                self.props.toggleItemVisibility('publishDropDown');
            }
        } else {
            //waiman
            alert('Please login');
            this.props.toggleItemVisibility('alarmLoginBox');

        }
    }

    handlePrintClicked() {
        const self = this;
        if (!this.props.engineReady) {
            //alert("Engine is loading. Please wait.");
            self.props.setAlertBox("Engine is loading. Please wait.");
            self.props.toggleItemVisibility('alertBox');
        } else if (this.props.authStatus === "member") {
            if (this.props.verifyStatus == "0") {
                this.checkUserVerifyStatus(function (res) {
                    if (res === "done") {
                        if (self.props.verifyStatus == "0") {
                            self.props.setAlertBox(self.props.current_lang === 'en' ? "Hi! Please verify your account first, mate!" : '你好！請先確認你的帳號！');
                            self.props.toggleItemVisibility('alertBox');
                            self.setState({showVerification: true});
                        } else {
                            self.props.toggleItemVisibility('shareDropDown');
                        }
                    }
                }, true);
            } else {
                this.props.toggleItemVisibility('checkout');
                if (!this.props.showCheckOut) {
                    this.props.closeElementMenu();
                } else {
                    this.props.changeElementMenu();
                }
                this.props.fetchQuote();
                this.props.resetCheckoutStep();
                this.props.clearActiveElements();
            }
        } else {
            let alertmsg = this.props.current_lang === 'en' ? 'Please login first' : '請先登入';
            this.props.setAlertBox(alertmsg);
            this.props.toggleItemVisibility('alertBox');
            //alert('Please login first');
        }
    }

    handleDownloadPrintingFileClicked() {
        var now = Date.now();
        var url = `${process.env.REACT_APP_MARKETPLACE_API_HOST}` + "/project/" + this.props.projectCode + '/printing_file?update=' + now;
        //window.location.href=`${process.env.REACT_APP_MARKETPLACE_API_HOST}` + "/project/"+ this.props.projectCode+'/printing_file?update=' + now;
        window.open(url);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleCancelShare() {
        this.props.toggleItemVisibility('shareDropDown');
    }

    handleCancelDownload() {
        this.props.toggleItemVisibility('downloadDropdown');
    }

    handleCancelPublish() {
        this.setState({showList: 'publish_item'})
    }

    handleCloseAlertBox() {
        this.props.toggleItemVisibility('alertBox');
        this.setState({showVerification: false});
    }

    alarmLogin(type) {

        const self = this;
        this.props.toggleItemVisibility('alarmLoginBox');
        if (type == 0) {
            self.setState({
                anim: ""
            });
            var timer = setTimeout(function () {
                /* self.alarmLogin(); */
                self.setState({
                    anim: "animate_icon"
                });

            }, 120000);
            self.setState({
                modalTimer: timer,
            });
        } else {
            clearTimeout(self.state.modalTimer);
            self.setState({
                modalTimer: null
            });
        }

    }

    linkToLoginPage() {
        const self = this;
        this.props.toggleItemVisibility('alarmLoginBox');
        this.props.promptLogin((status) => {
            //console.log("callback C");
            //console.log('Panel: '+status);
            if (status === 200) {
                self.props.setAuthStatus("member");

            }
        });
    }

    onMouseUp(event) {
        event.preventDefault();
        if (!this.state.projecttitle || this.state.projecttitle === this.props.productName + ' - Untitled') {
            this.setState({project_title_class: 'project_title_font_active', projecttitle: ''})
        }
        // this.props.setTextEditMode();
        event.stopPropagation();
    }

    handleTitleChange(event) {
        if (event.target.value !== "") {
            this.setState({projecttitle: event.target.value, project_title_class: 'project_title_font_active'});
            this.props.setProjectName(event.target.value);
        } else {
            this.setState({projecttitle: '', project_title_class: ''});
            this.props.setProjectName('');
        }
        // this.handleSaveClicked();
    }

    handleAddFolder(event) {

        this.setState({
            new_folder_name: event.target.value,
            add_folder_btn_style: (event.target.value ? ["add_folder_btn"] : ["add_folder_btn", "disabled"])
        });

    }

    handleAddFolderBtn() {
        const self = this;
        if (!this.state.new_folder_name) {
            return;
        }
        self.setState({
            add_folder_loading: true
        });
        this.props.addFolder(this.state.new_folder_name, function () {

            self.props.toggleItemVisibility('moveFolderBox');
            self.setState({
                new_folder_name: "",
                add_folder_btn_style: ["add_folder_btn", "disabled"],
                add_folder_loading: false,
            });
            alert("Move Folder Success");

        });
    }

    handleTitleInput(event) {
        //var element = event.target;
        var value = event.target.value;
        var currentPos = event.target.selectionStart;
        // eslint-disable-next-line
        var adjust_value = value.replace(/[`~!@#$%^&*()|+=?;:'",.<>{}[]\\\/]/gi, '');
        event.target.value = adjust_value;
        if (adjust_value !== value) {
            event.target.setSelectionRange(currentPos - 1, currentPos - 1);
        }
    }

    handleBlusr(event) {
        if (!event.target.value) {
            this.setState({projecttitle: this.props.productName + ' - Untitled', project_title_class: ''});
        }
    }

    /* start fb region */
    checkfbLoginStatus(value) {
        const self = this;
        this.props.checkSocialMediaToken('facebook', '', function (data) {
            if (data.status === "E") {
                self.fbLogin();
            } else {
                self.setState({fb_access_token: data.social_media_token});
                self.getfbLoginstatus(value);
            }
        });
    }

    setFacebookAccessToken(access_token, user_id, value) {
        let self = this;
        self.props.checkSocialMediaToken('facebook', access_token, function (data) {
            if (data.status === "E" && data.msg === "Invalid user") {
                console.error('FB login', data.msg);
                self.props.setAlertBox("please login again");
                self.props.toggleItemVisibility('alertBox');
                //alert("please login again");
            } else if (data.status === "E") {
                self.fbLogin(); // ???? should be removed
            } else {
                self.setState({fb_access_token: data.social_media_token});
                if (value === "fb_page") {
                    self.getfbPageList();
                } else {
                    self.getfbGroupList();
                }
            }
        });
    }

    getfbLoginstatus(value) {
        let self = this;
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                let user_id = response.authResponse.userID;
                let access_token = response.authResponse.accessToken;
                self.setFacebookAccessToken(access_token, user_id, value);
            } else if (response.status === 'not_authorized') {
                //login function
                self.fbLogin();
            } else {
                //login function
                self.fbLogin();
            }
        }, true);
    }

    fbLogin() {
        let self = this;
        window.FB.login(function (response) {
            if (response.authResponse) {
                // Get and display the user profile data
                if (process.env.NODE_ENV !== 'production') {
                    console.log('Welcome!  Fetching your information.... ');
                }
                let user_id = response.authResponse.userID;
                let access_token = response.authResponse.accessToken;
                self.setFacebookAccessToken(access_token, user_id, "fb_page");
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {scope: 'email, manage_pages, publish_pages'});
    }

    getfbPageList() {
        let self = this;
        window.FB.api(
            "/me/accounts",
            function (response) {
                if (response && !response.error) {
                    self.setState({
                        facebookPage: response.data,
                        showList: 'facebook_page_list',
                        publish_name: 'Facebook Page',
                        publish_icon: facebook_icon
                    })
                } else {
                    console.error(JSON.stringify(response), JSON.stringify(response.error), response.error);
                }
            }
        );
    }

    showfbPagePostBox(page) {
        if (this.state.showList === "facebook_page_list") {
            this.setState({
                showList: 'show_facebook_page_list',
                selectedfbpage: page.name,
                publish_name: 'Facebook Page',
                publish_icon: facebook_icon
            });
        } else {
            this.setState({
                showList: 'facebook_page_list',
                selectedfbpage: '',
                publish_name: 'Facebook Page',
                publish_icon: facebook_icon
            });
        }
    }

    fbpagepost(event) {
        event.preventDefault();
        const page_id = event.target[0].value;
        const access_token = event.target[1].value;
        const message = event.target[4].value;
        let self = this;
        const cookies = new Cookies();
        var code = cookies.get('code');
        window.FB.api('/' + page_id + '/photos', 'post', {
                url: 'https://www.artfia.com/images/project/' + code + '.png',
                message: message, access_token: access_token
            },
            function (response) {
                if (response && !response.error) {
                    self.props.toggleItemVisibility('publishDropDown');
                    const facebook_page_id = 'https://facebook.com/' + response.post_id;
                    self.setState({modalIsOpen: true, facebook_page_post_link: facebook_page_id});
                } else {
                    console.error(JSON.stringify(response), JSON.stringify(response.error), response.error);
                }
            }
        );
    }

    getfbGroupList(user_id) {
        const self = this;
        window.FB.api(
            "/" + user_id + "/groups",
            function (response) {
                if (response && !response.error) {
                    /* handle the result */
                    self.setState({
                        facebookGroups: response.data,
                        showList: 'facebook_group_list',
                        publish_name: 'Facebook Group',
                        publish_icon: facebook_icon
                    })
                }
            });
    }

    showfbGroupPostBox(group) {
        if (this.state.showList === "facebook_group_list") {
            this.setState({
                showList: 'show_facebook_group_list',
                selectedfbgroup: group.name,
                publish_name: 'Facebook Group',
                publish_icon: facebook_icon
            });
        } else {
            this.setState({
                showList: 'facebook_group_list',
                selectedfbgroup: '',
                publish_name: 'Facebook Group',
                publish_icon: facebook_icon
            });
        }
    }

    fbgrouppost(event) {
        event.preventDefault();
        const group_id = event.target[0].value;
        const access_token = this.state.fb_access_token;
        const message = event.target[4].value;
        let self = this;
        const cookies = new Cookies();
        var code = cookies.get('code');

        window.FB.api('/' + group_id + '/photos', 'post', {
                url: 'https://www.artfia.com/images/project/' + code + '.png',
                message: message, access_token: access_token
            },
            function (response) {
                if (response && !response.error) {
                    self.props.toggleItemVisibility('publishDropDown');
                    const facebook_page_id = 'https://facebook.com/' + response.post_id;
                    // console.log(facebook_page_id);
                    self.setState({modalIsOpen: true, facebook_page_post_link: facebook_page_id});
                } else {
                    console.error(JSON.stringify(response), JSON.stringify(response.error), response.error);
                }
            }
        );
    }

    /* end of fb region */

    /* start pdk region */
    /*getpdkSession() {
        var session = window.PDK.getSession();
        if (session) {
            this.setState({pinterest_session: session.accessToken});
            this.pdkMe();
        }
    }*/

    pdkLogin() {
        /*  const self = this;
          window.PDK.login({scope : "read_public,write_public", redirect_uri: "https://engine.artfia.com/"},
              function (response) {
                  if(response.session != undefined){
                      var pinterest_session = response.session.accessToken;
                      console.log("login success");
                      self.setState({pinterest_session:pinterest_session});
                      self.pdkMe();
                  }else{
                      console.log(response);
                      self.getpdkSession();
                  }
          });*/
        this.setPin();
    }

    /*  pdkLogout () {
          const self = this;
          window.PDK.logout(function(res){
              console.log("logout Success");
              alert("please try later");
          });
      }

      pdkMe() {
          const self = this;
          window.PDK.request('/v1/me/',{access_token: this.state.pinterest_session}, function(res){
              if(res && res.data && res.data.id === self.pinterest_last_userid) {
                  self.setPin();
              }
              else if (res && res.data) {
                 self.setState({pinterest_last_userid: res.data.id});
                 self.setPin();
              } else {
                  self.setState({pinterest_last_userid: null, pinterest_session: null})
                  self.pdkLogout();
              }
          });
      } */

    setPin() {
        if (process.env.NODE_ENV !== 'production') {
            console.log(this.state.pinterest_session);
        }
        //const cookies = new Cookies();
        //var code = cookies.get('code');
        //let newDate = new Date();
        var now = Date.now();
        const siteurl = `${process.env.REACT_APP_API_HOST}`;
        var imgurl = siteurl + "/images/project/" + this.props.projectCode + '.png?update=' + now;

        window.PDK.pin(imgurl);
    }

    /* end of pdk region */

    /* ------ slack ------*/
    slackLogin() {
        const self = this;
        this.props.checkSocialMediaToken('slack', '', function (data) {
            if (data.status === "E") {
                self.slackAuth();
            } else {
                self.setState({slack_access_token: data.social_media_token});
                self.getChannelList(data.social_media_token);
            }
        });
    }

    slackAuth() {
        this.props.promptSlackLogin((status) => {
            if (status === "OK") {
                const cookies = new Cookies();
                var code = cookies.get('slack_code');
                if (code) {
                    this.getSlackAccessToken(code);
                }
            }
        });
    }

    getSlackAccessToken(slackCode) {
        const self = this;
        fetch('https://slack.com/api/oauth.access?client_id=576304338454.575941323991&' +
            'client_secret=241b13caa276763eaed14634c4fc2903&' +
            'code=' + slackCode, {
            method: "GET"
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (myJson.error === "code_already_used") {
                    // ? code already used
                    console.error("slack code already used")
                } else if (myJson.error) {
                    self.slackAuth(); // ???? need to get new token
                } else {
                    self.props.checkSocialMediaToken('slack', myJson.access_token, function (data) {
                        if (data.status === "E" && data.msg === "Invalid user") {
                            console.error('Slack login', data.msg);
                            //alert("please login again");
                            self.props.setAlertBox("please login again");
                            self.props.toggleItemVisibility('alertBox');
                        } else if (data.status === "E") {
                            self.slackAuth(); // ???? should be removed
                        } else {
                            self.setState({slack_access_token: data.social_media_token})
                            self.getChannelList(data.social_media_token);
                        }
                    });
                }
            }).catch(e => {
            console.error('getSlackAccessToken',e);
            return e;
        });
    }

    getChannelList(slack_token) {
        const self = this;
        fetch('https://slack.com/api/channels.list?token=' + slack_token + '&pretty=1', {
            method: "GET"
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (myJson.error === "token_revoked") {
                    self.slackAuth(); /// ?? if no channel
                } else {
                    self.setState({
                        slack_channel_list: myJson.channels,
                        showList: 'slack_channel_list',
                        publish_name: 'Slack',
                        publish_icon: slack_icon
                    })
                }
            }).catch(e => {
            console.error('getChannelList', e);
            return e;
        });
    }

    showUploadfile(page) {
        if (this.state.showList === "slack_channel_list") {
            this.setState({
                showList: 'show_slack_channel_list',
                selectedfbpage: page.name,
                publish_name: 'Slack',
                publish_icon: slack_icon
            });
        } else {
            this.setState({
                showList: 'slack_channel_list',
                selectedfbpage: '',
                publish_name: 'Slack',
                publish_icon: slack_icon
            });
        }
    }

    slackUploadFilepost(event) {
        event.preventDefault();
        //const page_id = event.target[0].value;
        const file_title = event.target[3].value;
        const message = event.target[4].value;
        const page_name = event.target[5].value;
        let self = this;
        //const cookies = new Cookies();
        var slack_access_token = this.state.slack_access_token;
        this.props.slackUploadFile(slack_access_token, message, page_name, file_title, function (data) {
            if (data.ok) {
                alert("file has been successfully uploaded");
                self.props.toggleItemVisibility('publishDropDown');
            } else {
                console.error("slack upload error: " + data.error);
                //alert("something went wrong please try again later")
                self.props.setAlertBox("something went wrong please try again later");
                self.props.toggleItemVisibility('alertBox');
            }
        });
    }

    /*---- end of slack -----*/

    /*========== dropbox=========*/
    success() {
        //alert("Success! Files saved to your Dropbox.");
        this.props.setAlertBox("Success! Files saved to your Dropbox.");
        this.props.toggleItemVisibility('alertBox');
    }

    progress() {
    }

    cancel() {
    }

    error(errorMessage) {
        console.error('DropBox',errorMessage);
    }

    dropboxLogin() {
        const {
            success,
            progress,
            cancel,
            error
        } = this;

        //let newDate = new Date();
        var now = Date.now();
        const siteurl = `${process.env.REACT_APP_API_HOST}`;
        var imgurl = siteurl + "/images/project/" + this.props.projectCode + '.png?update=' + now;
        window.Dropbox.save(imgurl, {
            success,
            progress,
            cancel,
            error
        });
    }

    /*=======end of dropbox =========*/

    /*========= whatsapp ==========*/

    whatsappQRCode() {
        //const cookies = new Cookies();
        //var code = cookies.get('code');
        const siteurl = `${process.env.REACT_APP_API_HOST}`;
        //let newDate = new Date();
        var now = Date.now();
        var imgurl = siteurl + "/images/project/" + this.props.projectCode + '.png?update=' + now;
        this.setState({
            whatsapp_link: imgurl,
            showList: 'whatsapp_item_list',
            publish_name: "WhatsApp",
            publish_icon: whatsapp_icon
        })
    }

    /*====== end of whatsapp ========*/

    /*=========== wechat ============*/

    wechatQRCode() {
        //const cookies = new Cookies();
        //var code = cookies.get('code');
        const siteurl = `${process.env.REACT_APP_API_HOST}`;
        //let newDate = new Date();
        var now = Date.now();
        var imgurl = siteurl + "/share/"+ this.props.projectCode+'/view?update=' + now;
        //var imgurl = 'https://www.artfia.com/share/DnvJO2Au5fxbNeDdlyXkY/AAAAAA'
        this.setState({
            wechat_link: imgurl,
            showList: 'wechat_item_list',
            publish_name: 'WeChat',
            publish_icon: wechat_icon
        });
    }

    /*====== end of wechat ==========*/

    handleSavePreview(callback, skipPopMenu) {

        // console.log("handleSavePreview");

        if (!skipPopMenu) {
            this.props.clearActiveElements();
            this.props.toggleItemVisibility('fileDropdown');
        }

        //const self = this;

        var width = 0;
        var height = 0;

        $('.page').each((index, element) => {
            if (index == 0) {
                var zoom_element = document.getElementsByClassName("zoom_text")[0];
                //var percent = (parseFloat(zoom_element.value)).toFixed(0);
                var percent = (parseFloat(zoom_element.value));
                width = element.offsetWidth * (100 / percent);
                height = element.offsetHeight * (100 / percent);
                //console.log(width+"x"+height);
            }
        });

        this.props.savePreview(width, height, callback);
    }

    openModal() {

    }

    afterOpenModal() {
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    sendInvitation() {
        const self = this;
        if (!this.state.invitation_email) {
            //alert("Please input email address");
            self.props.setAlertBox("Please input email address");
            self.props.toggleItemVisibility('alertBox');
            return;
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = re.test(String(this.state.invitation_email).toLowerCase());
        if (!valid) {
            //alert("Invalid Email Format");
            self.props.setAlertBox("Invalid Email Format");
            self.props.toggleItemVisibility('alertBox');
            return;
        }
        if (this.state.sharing_lock) {
            return;
        }
        this.setState({
            sharing_lock: true,
        });
        this.props.setProjectShare(this.state.invitation_email, this.state.invitation_permission, "individual", function (res) {
            if (res.status === "Y") {
                //alert("Share Success");
                self.props.setAlertBox("");
                self.props.toggleItemVisibility('alertBox');
                self.props.setAlertBox('<div class="shareConfirmBox">' +
                    '<div class="shareConfirmTitle">' + (self.props.current_lang === 'en' ? 'Share Success' : '分享成功') + '</div>' +
                    '<div class="shareConfirmDesc">' + (self.props.current_lang === 'en' ? 'Your Design has been shared with ' + self.state.invitation_email + ' successfully' : '你的設計會同' + self.state.invitation_email + '分享') + '</div>' +
                    '<div><img class="share_gif" id="share_gif" src=' + share_success + ' width="200"/></div>' +
                    '</div>');
                setTimeout(function () {
                    document.getElementById("share_gif").classList.add("move");
                }, 100);
                self.setState({
                    invitation_email: "",
                    invitation_permission: "write",
                    sharing_lock: false,
                });
            }
        });
    }

    sendInvitationTeam(e) {
        const self = this;

        //var gif = share_success + "?v=" + (new Date().getTime());
        var team_name = e.target.getAttribute("team_name");

        this.props.setProjectShareTeam(e.target.id, e.target.value, "team", function (res) {

            if (res.status === "Y") {
                //alert("Share to team success");
                self.props.setAlertBox("");
                self.props.toggleItemVisibility('alertBox');
                self.props.setAlertBox('<div class="shareConfirmBox">' +
                    '<div class="shareConfirmTitle">' + (self.props.current_lang === 'en' ? 'Share Success' : '分享成功') + '</div>' +
                    '<div class="shareConfirmDesc">' + (self.props.current_lang === 'en' ? 'Your Design has been shared with ' + team_name + ' successfully' : '你的設計會同' + team_name + '分享') + '</div>' +
                    '<div><img class="share_gif" id="share_gif" src=' + share_success + ' width="200"/></div>' +
                    '</div>');
                setTimeout(function () {
                    document.getElementById("share_gif").classList.add("move");
                }, 100);

            } else {
                //console.log(res);
            }

        });
    }

    invitationEmailChange(e) {
        this.setState({
            invitation_email: e.target.value
        });
    }

    invitationPermissionChange(e) {
        this.setState({
            invitation_permission: e.target.value
        });
    }

    handleMoveFolderClicked() {

        const self = this;

        this.props.toggleItemVisibility('moveFolderBox');

        self.setState({
            folderList: null
        }, function () {
            self.props.getFolderList(function (data) {
                var folders = [];
                for (var i = 0; i < data.length; i++) {
                    folders.push({
                        id: data[i].id,
                        name: data[i].name,
                        preview: data[i].preview,
                        count: data[i].count,
                        update_at: data[i].update_at,
                        checked: data[i].checked,
                        loading: false
                    });
                }
                self.setState({
                    folderList: folders
                });
            });
        });


    }

    moveFolder(folder_id, idx) {
        const self = this;

        var list = this.state.folderList;
        list[idx].loading = true;

        this.setState({
            folderList: list
        });

        this.props.setProjectFolder(folder_id, function () {
            self.props.toggleItemVisibility('moveFolderBox');
            self.setState({
                folderList: [],
            });
            alert("Move Folder Success");
        });

    }

    cancelResizeBox() {
        this.props.toggleItemVisibility('resizePrompt');
    }

    handleResize(event) {
        event.preventDefault();
        var width = this.state.resize_width;
        var height = this.state.resize_height;
        if (event.target.id === "custom_unit") {
            this.setState({resize_unit: event.target.value});
        } else if (isNaN(event.target.value)) {
            var field = event.target.id === "custom_width" ? "width" : "height";
            this.setState({resize_err_msg: 'Please input a valid number of ' + field})
        } else if (event.target.id === "custom_width") {
            width = event.target.value;
            this.setState({resize_width: width});
        } else {
            height = event.target.value;
            this.setState({resize_height: height});
        }

        if (parseFloat(width) >= 0 && parseInt(height) >= 0) {
            this.setState({isResizeButtonDisabled: false})
        } else {
            this.setState({isResizeButtonDisabled: true})
        }
    }

    handleResizeClicked() {

        let width = this.state.resize_width;
        let height = this.state.resize_height;
        const unit = this.state.resize_unit;

        if (unit === "mm") {
            const value = 6;
            width = width * value;
            height = height * value;
        } else if (unit === "cm") {
            const value = 60;
            width = width * value;
            height = height * value;
        } else if (unit === "in") {
            const value = 150;
            width = width * value;
            height = height * value;
        }

        var cookie = new Cookies();
        cookie.set("unit", unit);
        cookie.set("custom_height", this.state.resize_height);
        cookie.set("custom_width", this.state.resize_width);

        this.props.resizePage(width, height);
        let zoomSize = this.props.getZoomContainerSize();
        this.props.setZoomScaleByContainerSize(zoomSize.width, zoomSize.height);
        this.cancelResizeBox();
        return false;
    }

    handleShareBtnHover(type) {
        if (type === 0) {
            //console.log("out");
            this.setState({
                invitation_btn_css: 'invitation_btn'
            });
        } else {
            //console.log("in");
            this.setState({
                invitation_btn_css: 'invitation_btn hover'
            });
        }
    }

    sendVerificationEmail() {
        const self = this;
        self.handleCloseAlertBox();
        this.props.sendVerificationEmail(function (myJson) {
            self.props.setAlertBox(myJson.msg ? myJson.msg : myJson.message);
            self.props.toggleItemVisibility('alertBox');
            self.setState({showVerification: false});
        });
    }

    handleShowPdfType() {
        if (this.state.showPdfSelection) {
            this.setState({showPdfSelection: false});
            this.setState({showDownloadBtn: false});
            this.setState({showPayDownloadBtn: false});
            this.setState({showCreditCardBox: false});
        } else {
            this.setState({showPdfSelection: true});
        }

    }

    handlePdfWatermark(type) {
        const self = this;
        if (type === "with") {
            if (($("#with-checkbox").prop("checked") == true) && (type === "with")) {
                $("#without-checkbox").prop("checked", false);
                self.setState({showDownloadBtn: true});
                this.setState({showPayDownloadBtn: false});
                self.setState({showCreditCardBox: false});
            }
        }

        if (($("#without-checkbox").prop("checked") == true) && (type === "without")) {
            if (this.props.has_watermark) {
                $("#with-checkbox").prop("checked", false);
                self.props.setCheckoutError('');
                self.setState({showDownloadBtn: false});
                self.setState({showCreditCardBox: true});

            } else {
                $("#with-checkbox").prop("checked", false);
                self.setState({showDownloadBtn: true});
                this.setState({showPayDownloadBtn: false});
                self.setState({showCreditCardBox: false});
            }
        }
        if (($("#without-checkbox").prop("checked") == false) && ($("#with-checkbox").prop("checked") == false)) {
            self.setState({showDownloadBtn: false});
            this.setState({showPayDownloadBtn: false});
            self.setState({showCreditCardBox: false});
        }
    }

    createTeam() {


        window.open(`${process.env.REACT_APP_API_HOST}/team`);


        this.updateSharePermission();

    }

    render() {
        /*
        const shareIndividualPermissions = this.state.individual_permission.map((page, index) => {
            var key = "individualShare"+index;
            return (
                    <SharePermission key={key}
                        id={this.state.individual_permission[index].id}
                        name={this.state.individual_permission[index].name}
                        permission={this.state.individual_permission[index].permission}
                        scope={this.state.individual_permission[index].scope}
                        photo={this.state.individual_permission[index].photo}
                        />
                );
        });
        */
        if (!this.props.getZoomContainerSize) {
            this.props.getZoomContainerSize = this.props.getZoomContainerSize.bind(this);
        }

        const shareIndividualPermissions = (
            <div className="individual_share_div">
                <div
                    className="share_to_individual">{this.props.current_lang === 'en' ? 'Share to individual' : '分享給朋友'}</div>
                <div className="individual_form_group">
                    <div className="left">
                        <input className="individual_email" id="individual_email" placeholder="Please enter email"
                               value={this.state.invitation_email} onChange={this.invitationEmailChange}
                               disabled={this.state.share_disabled}/>
                    </div>
                    <div className="right">
                        <select className="individual_permission_select" onChange={this.invitationPermissionChange}
                                disabled={this.state.share_disabled}>
                            <option value="write">{this.props.current_lang === 'en' ? 'Editable' : '可編輯'}</option>
                            <option value="read">{this.props.current_lang === 'en' ? 'View only' : '只限閱覽'}</option>
                        </select>
                    </div>
                </div>
                <div className="invitation_div">
                    <button className={this.state.invitation_btn_css}
                            onClick={this.sendInvitation}
                            onMouseEnter={this.handleShareBtnHover1}
                            onMouseLeave={this.handleShareBtnHover0}
                            disabled={this.state.share_disabled}>{this.props.current_lang === 'en' ? 'Send Invitation' : '發送邀請'}</button>
                </div>
            </div>
        );

        const shareTeamPermissions = (this.state.team_permission.length > 0 ? this.state.team_permission.map((page, index) => {
            var key = "teamShare" + index;
            return (
                <SharePermission key={key}
                                 id={page.id}
                                 name={page.name}
                                 permission1={page.permission}
                                 scope={page.scope}
                                 photo={page.photo}
                                 change={this.sendInvitationTeam}
                                 projectPermission={this.props.permission}
                />
            );
        }) : (<div className="before_team_share">
            <div>{this.props.current_lang === 'en' ? 'You do not have any team yet.' : '你仍未有團隊'}</div>
            <div className="create_team_btn"
                 onClick={this.createTeam}>{this.props.current_lang === 'en' ? 'Create Team Now !' : '新增你的團隊'}</div>
        </div>));

        const renderPages = this.state.facebookPage.map((page) => {
            return (
                <li className="list_style" key={1224695541025559}>
                    {this.state.showList === 'facebook_page_list' ?
                        <div onClick={this.showfbPagePostBox.bind(this, page)}>
                            <div className="publish_share_item">
                                <img src={placeholder} className="social_item_icon"/>
                                <div className="publish_item">{page.name}</div>
                            </div>
                        </div> : ""}
                    {(this.state.showList === 'show_facebook_page_list' && this.state.selectedfbpage === page.name) ?
                        <FormPage page={page} handleFormSubmit={this.fbpagepost} imgicon={placeholder}/>
                        : ""}
                </li>
            )
        });
        const renderGroups = this.state.facebookGroups.map((group) => {
            return (
                <li className="list_style" key={1224695541025559}>
                    {this.state.showList === 'facebook_group_list' ?
                        <div onClick={this.showfbGroupPostBox.bind(this, group)}>
                            <div className="publish_share_item">
                                <img src={placeholder} className="social_item_icon"/>
                                <div className="publish_item">{group.name}</div>
                            </div>
                        </div> : ""}
                    {(this.state.showList === 'show_facebook_group_list' && this.state.selectedfbgroup === group.name) ?
                        <FormPage page={group} handleFormSubmit={this.fbgrouppost} imgicon={placeholder}/>
                        : ""}
                </li>
            )
        });

        const slackChannel = this.state.slack_channel_list.map((page, index) => {
            return (
                <li className="list_style" key={index}>
                    {this.state.showList === 'slack_channel_list' ?
                        <div onClick={this.showUploadfile.bind(this, page)}>
                            <div className="publish_share_item">
                                <img src={placeholder} className="social_item_icon"/>
                                <div className="publish_item">{page.name}</div>
                            </div>
                        </div> : ""}
                    {(this.state.showList === 'show_slack_channel_list' && this.state.selectedfbpage === page.name) ?
                        <FormPage page={page} handleFormSubmit={this.slackUploadFilepost} imgicon={placeholder}/>
                        : ""}
                </li>
            )
        });
        let project_name = this.state.projecttitle ? this.state.projecttitle : '';
        let home = '';
        (this.props.project_target === "mp_product" || this.props.project_target === "mp_order") ? home = `${process.env.REACT_APP_MARKETPLACE_API_HOST}` : home = `${process.env.REACT_APP_API_HOST}`;

        const folderElements = (this.state.folderList != null && this.state.folderList.length > 0) ? this.state.folderList.map((element, index) =>
            (
                <div className="line folder" key={"folder_" + index}
                     onClick={this.moveFolder.bind(this, element.id, index)}>
                    <span
                        className={classNames("folder_col0", "folder_bg_" + ((parseInt(element.name.substring(0, 1).charCodeAt(0)) % 3) + 1))}>{element.name.substring(0, 1)}</span>
                    <span className="folder_col1">{element.name}</span>
                    <span className="folder_col2">
                        {element.loading ? <div className="lds-dual-ring"></div> : element.checked ? (
                            <img src={tick_icon}/>) : (<div></div>)}
                    </span>
                </div>
            )
        ) : ((this.state.folderList == null) ? <div>Loading...</div> : <div>You do not have any folder yet.</div>);

        /*if (this.props.showConfirmShareBox) {
            addAddThisScript();
        }*/

        //let pinterestPromise = Promise.resolve(true);
        if(this.props.showShareBox) {
            /*pinterestPromise = */addPinterestScript();
        }
        return (
            <Hotkeys
                keyName="backspace,ctrl+shift+l,command+shift+l,ctrl+shift+i,command+shift+i,ctrl+z,command+z,ctrl+shift+z,command+shift+z,ctrl+s,command+s,ctrl+shift+s,command+shift+s"
                onKeyDown={this.hotkeyPressed}
            >
                <Modal ariaHideApp={false} modalOpen={!this.props.is_project_loaded} oncloseModal={this.closeModal}
                       onafterOpenModal={this.afterOpenModal} isLoading={true}/>

                <Modal ariaHideApp={false} modalOpen={this.props.isDownloadingOutfile} oncloseModal={this.closeModal}
                       onafterOpenModal={this.afterOpenModal} isLoading={true} style={1}/>

                <Modal ariaHideApp={false} modalOpen={this.state.modalIsOpen} oncloseModal={this.closeModal}
                       onafterOpenModal={this.afterOpenModal}
                       facebook_page_post_link={this.state.facebook_page_post_link}/>
                <div className="header">
                    {this.state.ready_to_show ?
                        <div className="css_header">
                            <div className="joindesign_logo">
                                <a href={home}><img className="logo_img" src={logo}/></a>
                            </div>
                            <div className="left_header_group">
                                <div>
                                    <div className="file_header_item header_item file_menu">
                                        <IconButton text={this.props.current_lang === 'en' ? 'File' : '檔案'}
                                                    iconType={"transparent_button"} textColor={"#8c6ea8"}
                                                    onClick={this.handleFileButtonClicked} iconSize={20}/>
                                    </div>

                                    <FileDropdown visible={!!this.props.showFileDropdown}
                                                  handleDesingerSave={this.designerSave}
                                                  handleSaveAndNext={(this.props.authStatus && this.props.authStatus === 'guest' ? this.quitEngine : this.handleSaveClicked)}
                                                  getZoomContainerSize={this.props.getZoomContainerSize}
                                                  handleSavePreview={this.handleSavePreview.bind(this, null, false)}
                                                  handleMoveFolderClicked={this.handleMoveFolderClicked}/>

                                    <div
                                        className={`col-11 text-right modal modal_resize ${this.state.resize_modal_class}`}
                                        id="myModal">
                                        <div className="dropdown open modal-content reisze_modal-content">
                                            <span className="close" onClick={this.cancelResizeBox}>&times;</span>
                                            <div className="dropdown-menu dropdown-menu-right p-4">
                                                <form className="form-horizontal">
                                                    <div className="row resize_container">
                                                        <div className="resize_container_box">
                                                            <input id="custom_width"
                                                                   className="form-control resize_text"
                                                                   value={this.state.resize_width}
                                                                   onChange={this.handleResize} type="text"
                                                                   placeholder="width"/>
                                                        </div>
                                                        <div className="resize_container_box">
                                                            <input id="custom_height"
                                                                   className="form-control resize_text"
                                                                   value={this.state.resize_height}
                                                                   onChange={this.handleResize} type="text"
                                                                   placeholder="height"/>
                                                        </div>
                                                        <div className="resize_container_box">
                                                            <select id="custom_unit"
                                                                    className="form-control resize_text resize_select"
                                                                    value={this.state.resize_unit} placeholder="unit"
                                                                    onChange={this.handleResize}>
                                                                <option value="px">px</option>
                                                                <option value="in">in</option>
                                                                <option value="mm">mm</option>
                                                                <option value="cm">cm</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="text_danger" id="custom_size_warning">
                                                        {this.state.resize_err_msg}
                                                    </div>
                                                    <div className="row resize_container">
                                                        <div>
                                                            <div onClick={this.handleResizeClicked}
                                                                 className={classNames("btn", "resize_btn")}
                                                                 disabled={this.state.isResizeButtonDisabled}>{this.props.current_lang === 'en' ? 'Resize' : '更改尺寸'}</div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={classNames('icon_header_item')}>
                                    <IconButton iconType={"transparent_button"} textColor={"#8c6ea8"} iconSize={20}
                                                src={undo} inactive={!this.props.canUndo}
                                                onClick={this.props.undoHistory.bind(this)}/>
                                </div>
                                <div className={classNames('icon_header_item')}>
                                    <IconButton iconType={"transparent_button"} textColor={"#8c6ea8"} iconSize={20}
                                                src={redo} inactive={!this.props.canRedo}
                                                onClick={this.props.redoHistory.bind(this)}/>
                                </div>
                                {((this.props.project_target === "studio" && this.props.access_level > 70) || (this.props.access_level > 70)) ?
                                    <div className="cp_resize_btn"
                                         onClick={this.cancelResizeBox.bind(this)}>{this.props.current_lang === 'en' ? 'Resize' : '更改尺寸'}</div>
                                    : ""
                                }
                                <div className="save_div">
                                    <div className="save_btn" onClick={this.handleSaveClicked.bind(this, null)}>
                                        <img className="save_icon" src={save_icon}/>
                                    </div>
                                    <div className="save_status_text" onClick={this.handleSaveClicked.bind(this, null)}>
                                        {this.props.saveStatusText}
                                    </div>
                                </div>
                            </div>

                            <div className="center_header_group">
                                {this.props.authStatus}
                                {this.props.authStatus && this.props.authStatus === 'guest' && !this.props.callback_url && this.props.project_target === "studio" ?
                                    <div className="header_item">
                                        <div className="alarm_icon">
                                            <img src={alarm} onClick={this.alarmLogin.bind(this, 1)}
                                                 className={classNames("icon", this.state.anim)}/>
                                        </div>
                                    </div> : ""
                                }
                                {this.props.showAlarmLoginBox ?
                                    <div id="myModal" className="modal">
                                        <div className="modal-content">
                                            <span className="close"
                                                  onClick={this.alarmLogin.bind(this, 0)}>&times;</span>
                                            <div
                                                className="line">{this.props.current_lang === 'en' ? 'Why Register' : '為何註冊'}?
                                            </div>
                                            <div className="line"><img className="icon"
                                                                       src={alarm_download}/>{this.props.current_lang === 'en' ? 'Autosave your design' : '自動保存你的設計'}
                                            </div>
                                            <div className="line"><img className="icon"
                                                                       src={alarm_printer}/>{this.props.current_lang === 'en' ? 'Download the file for printing' : '下載要列印的文件'}
                                            </div>
                                            <div className="line"><img className="icon"
                                                                       src={alarm_network}/>{this.props.current_lang === 'en' ? 'Publish your design in social media channels' : '在社交媒體發佈你的設計'}
                                            </div>
                                            <div className="line"><img className="icon"
                                                                       src={alarm_link}/>{this.props.current_lang === 'en' ? 'Share the link and lets your colleague and friends edit your work' : '分享連結，讓你的同事和朋友編輯你的工作'}
                                            </div>
                                            <div className="line"><img className="icon"
                                                                       src={alarm_image}/>{this.props.current_lang === 'en' ? 'Gain more access to the our image library' : '取得更多圖像庫的訪問權限'}
                                            </div>
                                            <div align="center">
                                                <button onClick={this.linkToLoginPage}
                                                        className={classNames("btn", "btn-success")}>{this.props.current_lang === 'en' ? 'Login / Register' : '登入/註冊'}</button>
                                            </div>
                                        </div>
                                    </div> : ""
                                }
                                {this.props.showAlertBox ?
                                    <div id="alertModal" className="modal">
                                        <div className="modal-content">
                                            <span className="close"
                                                  onClick={this.handleCloseAlertBox.bind(this)}>&times;</span>
                                            <div className="line"></div>
                                            {this.state.showVerification ?
                                                <div align="center">
                                                    <img className="" src={verifyemail_icon} style={{width: '150px'}}/>
                                                    <h2 style={{color: '#666'}}>{this.props.current_lang === 'en' ? 'Verify Email' : '驗證郵箱'}</h2>
                                                    <div
                                                        style={{color: '#888'}}>{this.props.current_lang === 'en' ? 'Hey ' + this.props.verifyNickname + ', you’re almost ready to start share your design.' : 'Hi ' + this.props.verifyNickname + ', 麻煩驗證你的電子郵件地址，唔該哂 ~'}</div>
                                                    <div
                                                        style={{color: '#888'}}>{this.props.current_lang === 'en' ? 'Simply click the button below to verify your email address.' : ''}</div>
                                                    <br/>
                                                    <div
                                                        style={{color: '#888'}}>{this.props.current_lang === 'en' ? 'The email for your account is: “' + this.props.verifyEmail + '”' : '你帳戶的電子郵件是：' + this.props.verifyEmail + ''}</div>
                                                    <div
                                                        style={{color: '#888'}}>{this.props.current_lang === 'en' ? ' If your email address is incorrect, ' : '如果你的電子郵件地址不正確，請'}<a
                                                        href={home + '/account'}
                                                        target="_blank"
                                                        rel="noreferrer noopener noreferrer">{this.props.current_lang === 'en' ? 'please update it here.' : '按此處進行更新'}</a>
                                                    </div>

                                                    <br/>
                                                </div>
                                                :
                                                <div className="line alert_box_title"
                                                     dangerouslySetInnerHTML={{__html: this.props.alertBoxContent}}></div>
                                            }
                                            {this.state.showVerification ? <div align="center">
                                                <button onClick={this.sendVerificationEmail}
                                                        className={classNames("btn", "btn-custom")}>{this.props.current_lang === 'en' ? 'Send again' : '再次發送'}</button>
                                            </div> : <div align="center">
                                                <button onClick={this.handleCloseAlertBox.bind(this)}
                                                        className={classNames("btn", "btn-success", "alertBoxOK")}>OK
                                                </button>
                                            </div>}


                                        </div>
                                    </div> : ""
                                }
                                {this.props.showMoveFolderBox ?
                                    <div id="folderModal" className="modal">
                                        <div className="modal-content">
                                            <div className="close"
                                                 onClick={this.handleMoveFolderClicked.bind(this)}>&times;</div>
                                            <div className="line">Move to Folder</div>
                                            <div className="folder_elements">
                                                {folderElements}
                                            </div>
                                            <div className="add_folder">
                                                <span className="folder_add_header">Create one now: </span>
                                                <input className="add_folder_name" type="text"
                                                       placeholder="New Folder Name" onChange={this.handleAddFolder}/>
                                            </div>
                                            <div className="add_folder_btn_div">
                                                <button className={classNames(this.state.add_folder_btn_style)}
                                                        onClick={this.handleAddFolderBtn}>
                                                    {this.state.add_folder_loading ? <div className="lds-ellipsis">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div> : "Confirm"}
                                                </button>
                                            </div>
                                        </div>
                                    </div> : <div></div>
                                }


                                <div id="shareModal"
                                     className={classNames('modal', (this.props.showConfirmShareBox ? 'show' : 'hide'))}>
                                    <div className="modal-content">
                                        <div className="close" onClick={this.handleCloseConfirmShare.bind(this)}>&times;
                                            <span
                                                className="close_text">{this.props.current_lang === 'en' ? 'Close' : '關閉'}</span>
                                        </div>
                                        <div className="social_media_list">
                                            <div
                                                className="title">{this.props.current_lang === 'en' ? 'Thank you!' : '感謝!'}</div>
                                            <div
                                                className="desc">{this.props.current_lang === 'en' ? 'Help us spread the word and share us with your friends.' : '請幫忙分享給你的朋友'}</div>
                                            <div className="share_box">
                                                <div className="addthis_inline_share_toolbox"
                                                     data-url={`${process.env.REACT_APP_API_HOST}/sharetofacebook`}
                                                     data-media={`${process.env.REACT_APP_API_HOST}/images/jd_share_img.jpg`}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="right_header_group">
                            <span style={{overflow: 'hidden'}}>
                               <div className="project_title_box"
                                    style={this.props.callback_url == "" || this.props.payment_term == 'monthly' || this.props.access_level > 70 ? {} : {display: 'none'}}>
                                   <input maxLength="255" type="text" value={project_name || ''}
                                          className={classNames('project_title_text project_title_input project_title_font', this.state.project_title_class)}
                                          onFocus={this.onMouseUp.bind(this)} onBlur={this.handleBlusr.bind(this)}
                                          onChange={this.handleTitleChange} onInput={this.handleTitleInput}/>
                                   <div
                                       className={classNames('project_title_font project_title_div project_title_text', this.state.project_title_class)}>
                                       {project_name}
                                   </div>
                               </div>
                            </span>
                                {(this.props.callback_url == "" && this.props.project_target != "mp_product" && this.props.project_target != "mp_order" && this.props.payment_term != "monthly") ?
                                    <div className="header_item share">
                                        <div className="share_header_container">
                                            <IconRightButton text={this.props.current_lang === 'en' ? 'Share' : '分享'}
                                                             src={share_icon}
                                                             share_box_active={this.props.showShareBox ? true : false}
                                                             textColor={"#8c6ea8"} onClick={this.handleShare}
                                                             iconSize={20}/>
                                        </div>
                                        {this.props.showShareBox ?
                                            <div className="popup_contianer show_popup_contianer show_popup_contianer"
                                                 style={{right: '20px'}}>
                                                <div style={{width: '420px', display: 'block', cursor: 'default'}}>
                                                    <div className="share_popup_label">
                                                        <div className="popup_label_container share_permission_header">
                                                            <div className="label_content share_permission_header">
                                                                <div
                                                                    className="share_popup_header share_permission_header">
                                                                    <div className="share_pop_up_header_div">
                                                                        <div>{this.props.current_lang === 'en' ? 'Share to team members' : '與團隊成員共享'}</div>
                                                                        <div className="share_to_team_desc">
                                                                            {this.props.current_lang === 'en' ? 'Share designs with select people or group within you team' : '與團隊成員分享設計及設計'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="share_popup_close cursor_pointer"
                                                                     onClick={this.handleCancelShare}>
                                                                    <img className="cancel_img" src={cancel}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="share_popup_content_box">
                                                        <div className="share_popup_container">
                                                            <div className="share_content_item"
                                                                 style={{"display": "none"}}>
                                                                <div className="link_content"><span>Link</span></div>
                                                                <div
                                                                    className="link_content link_input_box link_input_margin cursor_pointer">
                                                                    <input className="link_input" type="text"
                                                                           placeholder="Link" id="link_input"
                                                                           value={window.location.protocol + '//' + window.location.host + '/design-builder/' + this.props.projectCode}
                                                                           onChange={this.handleChange} readOnly/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="share_content_item share_copy_link cursor_pointer"
                                                                style={{"display": "none"}}>
                                                                <CopyToClipboard
                                                                    text={window.location.protocol + '//' + window.location.host + '/design-builder/' + this.props.projectCode}
                                                                    onCopy={() => this.setState({copied: true})}>
                                                                    <span
                                                                        className="link_title">{this.state.copied ? 'Copied!' : 'Copy Link'}</span>
                                                                </CopyToClipboard>
                                                            </div>
                                                            <div
                                                                className="share_content_item share_permission_padding">
                                                                <div className="share_permissions_div">
                                                                    <div className="team_share_div">
                                                                        {shareTeamPermissions}
                                                                    </div>
                                                                    <div>
                                                                        {shareIndividualPermissions}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>
                                    : ''}

                                {((this.props.callback_url == "" || this.props.payment_term === 'monthly') && this.props.project_target !== "mp_product" && this.props.project_target !== "mp_order") ?
                                    <div className="header_item right_header_margin">
                                        <div className="share_header_container">
                                            <IconRightButton text={this.props.current_lang == 'en' ? 'Download' : '下載'}
                                                             src={download}
                                                             share_box_active={this.props.showDownloadBox ? true : false}
                                                             isLoading={this.props.isDownloadingOutfile}
                                                             textColor={"#8c6ea8"}
                                                             onClick={this.handleDownloadClicked}
                                                             iconSize={20}></IconRightButton>
                                        </div>
                                        {this.props.showDownloadBox ?
                                            <div
                                                className="popup_contianer show_popup_contianer show_popup_contianer download_popup_container"
                                                style={{right: '20px'}}>
                                                <div style={{width: '360px', display: 'block', cursor: 'default'}}>
                                                    <div className="share_popup_label">
                                                        <div className="popup_label_container">
                                                            <div className="label_content">
                                                                <div className="share_popup_header">
                                                                    <span>{this.props.current_lang === 'en' ? 'Download As' : '下載'}</span>
                                                                </div>
                                                                <div className="share_popup_close cursor_pointer"
                                                                     onClick={this.handleCancelDownload}>
                                                                    <img className="cancel_img" src={cancel}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="share_popup_content_box">
                                                        <div className="share_popup_container">
                                                            <ul className="picker_list">
                                                                <li className="list_style">
                                                                    <div
                                                                        onClick={this.handleDownload.bind(this, 'png')}>
                                                                        <div className="publish_share_item">
                                                                            <img src={png_icon}
                                                                                 className="social_item_icon"/>
                                                                            <div className="publish_item"><span
                                                                                className="publish_item_title">PNG</span>
                                                                                <span
                                                                                    className="publish_item_desc"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list_style">
                                                                    <div
                                                                        onClick={this.handleDownload.bind(this, 'jpg')}>
                                                                        <div className="publish_share_item">
                                                                            <img src={jpg_icon}
                                                                                 className="social_item_icon"/>
                                                                            <div className="publish_item"><span
                                                                                className="publish_item_title">JPG</span>
                                                                                <span
                                                                                    className="publish_item_desc"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list_style">
                                                                    <div
                                                                        onClick={this.handleDownload.bind(this, 'pdf')}>
                                                                        <div className="publish_share_item">
                                                                            <img src={pdf_icon}
                                                                                 className="social_item_icon"/>
                                                                            <div className="publish_item"><span
                                                                                className="publish_item_title">PDF</span>
                                                                                <span className="publish_item_desc">high quality multi-page document</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>
                                    : ''}

                                {(this.props.callback_url == "" && this.props.project_target !== "mp_product" && this.props.project_target !== "mp_order" && this.props.payment_term !== "monthly") ?
                                    <div className="header_item header_publish">
                                        <div className="share_header_container">
                                            <IconRightButton publish_class={true}
                                                             text={this.props.current_lang === 'en' ? 'Publish' : '發佈'}
                                                             src={down_arrow}
                                                             share_box_active={this.props.showPublishBox ? true : false}
                                                             textColor={"#8c6ea8"} onClick={this.handlePublish}
                                                             iconSize={20}/>
                                        </div>
                                        {this.props.showPublishBox && this.props.authStatus === "member" ?
                                            <div className="popup_contianer show_popup_contianer show_popup_contianer"
                                                 style={{right: '20px'}}>
                                                <div style={{width: '360px', display: 'block', cursor: 'default'}}>
                                                    <div className="share_popup_label">
                                                        <div className="popup_label_container">
                                                            {this.state.showList === "publish_item" ?
                                                                <div className="label_content">
                                                                    <div className="share_popup_header">
                                                                        <span>{this.props.current_lang === 'en' ? 'How would you like to publish?' : '你想怎樣發佈？'}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="label_content">
                                                                    <img src={this.state.publish_icon}
                                                                         className="social_item_icon"/>
                                                                    <div className="share_popup_header">
                                                                        <span>{this.state.publish_name}</span></div>

                                                                    <div className="share_popup_close cursor_pointer"
                                                                         onClick={this.handleCancelPublish}>
                                                                        <img className="cancel_img" src={cancel}/>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="share_popup_content_box">
                                                        <div className="share_popup_container">
                                                            {this.state.showList === "publish_item" ?
                                                                <ul className="picker_list">
                                                                    <li className="list_style">
                                                                        <div id="slack_api"
                                                                             onClick={this.whatsappQRCode.bind(this)}>
                                                                            <div className="publish_share_item">
                                                                                <img src={whatsapp_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div className="publish_item">Whatsapp
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list_style">
                                                                        <div id="slack_api"
                                                                             onClick={this.wechatQRCode.bind(this)}>
                                                                            <div className="publish_share_item">
                                                                                <img src={wechat_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div className="publish_item">WeChat
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list_style">
                                                                        <LinkedinShareButton
                                                                            url={`${process.env.REACT_APP_API_HOST}/share/${this.props.projectCode}/view?update=${this.state.current_time}`}
                                                                            quote={"artfia"}
                                                                            className="publish_share">
                                                                            <div className="publish_share_item">
                                                                                <img src={linkedin_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div className="publish_item">Linkedin
                                                                                </div>
                                                                            </div>
                                                                        </LinkedinShareButton>
                                                                    </li>
                                                                    <li className="list_style">
                                                                        <div id="slack_api"
                                                                             onClick={this.dropboxLogin.bind(this)}>
                                                                            <div className="publish_share_item">
                                                                                <img src={dropbox_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div className="publish_item">Dropbox
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list_style">
                                                                        <TumblrShareButton
                                                                            link={`${process.env.REACT_APP_API_HOST}/share/${this.props.projectCode}/view?update=${this.state.current_time}`}
                                                                            url={`${process.env.REACT_APP_API_HOST}/images/project/${this.props.projectCode}.png?update=${this.state.current_time}`}
                                                                            className="publish_share">
                                                                            <div className="publish_share_item">
                                                                                <img src={tumblr_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div className="publish_item">Tumblr
                                                                                </div>
                                                                            </div>
                                                                        </TumblrShareButton>
                                                                    </li>
                                                                    <li className="list_style">
                                                                        <div onClick={this.pdkLogin.bind(this)}>
                                                                            <div className="publish_share_item">
                                                                                <img src={pinterest_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div
                                                                                    className="publish_item">Pinterest
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list_style">
                                                                        <div
                                                                            onClick={this.checkfbLoginStatus.bind(this, 'fb_page')}>
                                                                            <div className="publish_share_item">
                                                                                <img src={facebook_icon}
                                                                                     className="social_item_icon"/>
                                                                                <div className="publish_item">Facebook
                                                                                    Page
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    {/*<li  className="list_style">
                                                            <div onClick={this.getfbLoginstatus.bind(this, 'fb_group')}>
                                                                <div className="publish_share_item">
                                                                    <img src={facebook_icon} className="social_item_icon"/>
                                                                    <div className="publish_item">Facebook Group</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                         <li  className="list_style">
                                                            <div id="slack_api" onClick={this.slackLogin.bind(this)}>
                                                                <div className="publish_share_item">
                                                                    <img src={slack_icon} className="social_item_icon"/>
                                                                    <div className="publish_item">Slack</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li  className="list_style">
                                                            <div id="twitter-wjs" onClick={this.twitterLogin.bind(this)}>
                                                                <div className="publish_share_item">
                                                                    <img src={twitter_icon} className="social_item_icon"/>
                                                                    <div className="publish_item">Twitter</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li  className="list_style">
                                                            <TwitterShareButton
                                                                url={'https://www.joindesign.com/images/project/'+this.props.projectCode+'.png'}
                                                                quote={"joindesign"} className="publish_share">
                                                                <div className="publish_share_item">
                                                                    <img src={twitter_icon} className="social_item_icon"/>
                                                                    <div className="publish_item">Twitter</div>
                                                                </div>
                                                            </TwitterShareButton>
                                                        </li>*/}
                                                                </ul> :
                                                                this.state.showList === "facebook_page_list" || this.state.showList === "show_facebook_page_list" ?
                                                                    <ul className="picker_list">
                                                                        {renderPages}
                                                                    </ul>
                                                                    : this.state.showList === "facebook_group_list" || this.state.showList === "show_facebook_group_list" ?
                                                                    <ul className="picker_list">
                                                                        {renderGroups}
                                                                    </ul>
                                                                    : this.state.showList === "slack_channel_list" || this.state.showList === "show_slack_channel_list" ?
                                                                        <ul className="picker_list">
                                                                            {slackChannel}
                                                                        </ul>
                                                                        : this.state.showList === "whatsapp_item_list" ?
                                                                            <ul className="picker_list">
                                                                                <li className="qr_text_publish">
                                                                                    <span>To share design on WhatsApp</span>
                                                                                </li>
                                                                                <li className="qr_code_publish">
                                                                                    <QRCode
                                                                                        value={this.state.whatsapp_link}
                                                                                        size="200"/>
                                                                                </li>
                                                                                <li className="qr_text_publish">
                                                                                    <span>Point your phone to this screen to capture the code</span>
                                                                                </li>
                                                                            </ul>
                                                                            : this.state.showList === "wechat_item_list" ?
                                                                                <ul className="picker_list">
                                                                                    <li className="qr_text_publish">
                                                                                        <span>To share design on Wechat</span>
                                                                                    </li>
                                                                                    <li className="qr_code_publish">
                                                                                        <QRCode
                                                                                            value={this.state.wechat_link}
                                                                                            size="200"/>
                                                                                    </li>
                                                                                    <li className="qr_text_publish">
                                                                                        <span>Point your phone to this screen to capture the code</span>
                                                                                    </li>
                                                                                </ul>
                                                                                : ""}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>
                                    : ''}
                                {(this.props.callback_url != "" && this.props.payment_term !== 'monthly' && this.props.callback_url != null && this.props.partnership_link == "0") ?
                                    <div className="header_item save">
                                        <div className="share_header_container">
                                            <IconRightButton
                                                text={this.props.current_lang === 'en' ? "Save Design" : '保存設計'}
                                                textColor={"#8c6ea8"} onClick={this.saveJP}
                                                iconSize={20}/>
                                        </div>
                                    </div>
                                    : ''}

                                {((this.props.project_target === "mp_order" || (this.props.project_target === "mp_product")) && this.props.access_level > 70) ?
                                    <div className="share_header_container">
                                        <IconRightButton text={'Download Printing Design'}
                                                         textColor={"#8c6ea8"}
                                                         onClick={this.handleDownloadPrintingFileClicked}
                                                         iconSize={20}/>
                                    </div>
                                    : ''}
                                {((this.props.callback_url != "" && this.props.callback_url != null) || this.props.project_target === "mp_product" || this.props.project_target === "mp_order") ?
                                    <div className="header_item header_next_btn">
                                        <div className="share_header_container test">
                                            <IconRightButton is_primary={true}
                                                             text={this.props.payment_term == 'monthly' ? (this.props.current_lang === 'en' ? "Save & Return" : '下一步') : (this.props.current_lang === 'en' ? "Next" : '下一步')}
                                                             textColor={"#8c6ea8"} onClick={this.quitEngine}
                                                             iconSize={20}
                                                             isDisabled={(this.props.isSaving || (this.props.currentSaveId > this.props.lastSaveId))}
                                                             disabled_text={this.props.currentSaveId && this.props.currentSaveId instanceof String? "Error": "Saving"}/>
                                        </div>
                                    </div>
                                    : ''}
                                {(this.props.is_printable == true && this.props.callback_url == "" && this.props.project_target !== "mp_product" && this.props.project_target !== "mp_order" && this.props.payment_term !== "monthly" && !this.state.is_mobile) ?
                                    <div className="header_item print">
                                        <div className="share_header_container">
                                            <IconRightButton is_primary={true}
                                                             text={this.props.current_lang === 'en' ? 'Print' : '印刷'}
                                                             src={checkout_icon} textColor={"#8c6ea8"}
                                                             onClick={this.handlePrintClicked}
                                                             iconSize={20}/>
                                        </div>
                                    </div>
                                    : ""}
                                {/*<div className="header_item right_header_margin" onClick={this.handleDownloadClicked.bind(this)} disabled={this.state.downloading}>
                            <span className="action_btn">Preview</span>
                        </div>*/}
                            </div>
                        </div> : ""}
                </div>
            </Hotkeys>
        )
    }
}

const mapStateToProps = state => ({
    pages: state.pages,
    isSaving: state.isSaving,
    currentSaveId: state.currentSaveId,
    lastSaveId: state.lastSaveId,
    saveStatusText: state.currentSaveId && state.currentSaveId instanceof String? "Error" : (state.isSaving ? (state.current_lang === 'en' ? 'Saving...' : '儲存') : (state.lastSaveId == state.currentSaveId ? (state.current_lang === 'en' ? 'All changes saved' : '所有變更已儲存') : (state.current_lang === 'en' ? 'Unsaved changes' : '未儲存修改'))),
    canUndo: state.histories.length && state.currentHistory > 0,
    canRedo: state.histories.length && state.currentHistory < state.histories.length - 1,
    projectCode: state.projectCode,
    isDownloadingOutfile: state.isDownloadingOutfile,
    showFileDropdown: state.itemsVisibility['fileDropdown'],
    access_level: state.access_level,
    project_target: state.project_target,
    authStatus: state.authStatus,
    verifyStatus: state.verifyStatus,
    verifyNickname: state.verifyNickname,
    verifyEmail: state.verifyEmail,
    alertBoxContent: state.alertBoxContent,
    project_name: state.project_name,
    show_project_popup: state.show_project_popup,
    showShareBox: state.itemsVisibility['shareDropDown'],
    showPublishBox: state.itemsVisibility['publishDropDown'],
    showDownloadBox: state.itemsVisibility['downloadDropdown'],
    showAlarmLoginBox: state.itemsVisibility['alarmLoginBox'],
    showAlertBox: state.itemsVisibility['alertBox'],
    showMoveFolderBox: state.itemsVisibility['moveFolderBox'],
    showConfirmShareBox: state.itemsVisibility['showConfirmShareBox'],
    showResizeBox: state.itemsVisibility['resizePrompt'],
    showCheckOut: state.itemsVisibility['checkout'],
    zoomScale: state.zoomScale,
    width: state.pages && state.pages.length ? state.pages[0].width : 0,
    height: state.pages && state.pages.length ? state.pages[0].height : 0,
    permission: state.permission,
    engineReady: state.engineReady,
    productName: state.productName,
    brand_token: state.brand_token,
    checkoutError: state.checkout.checkoutError,
    has_watermark: state.has_watermark,
    is_printable: state.is_printable,
    callback_url: state.callback_url,
    partnership_link: state.partnership_link,
    is_project_loaded: state.is_project_loaded,
    main_url: state.main_url,
    save_url: state.save_url,
    current_lang: state.current_lang,
    random: state.props,
    recentlyUsedImages: state.recentlyUsedImages,
    recentlyUsedPhotos: state.recentlyUsedPhotos,
    payment_term: state.payment_term,
    select_from_team_id: state.select_from_team_id,
    team: state.team,
    partnership_param: state.partnership_param,
});

const mapDispatchToProps = dispatch => ({
    checkUserVerifyStatus: (callback) => dispatch(checkUserVerifyStatus(callback)),
    checkUserSession: () => dispatch(checkUserSession()),
    clearActiveElements: () => dispatch(clearActiveElements()),
    //loadDefault: () => dispatch(loadDefault()),
    loadCanvasData: (data) => dispatch(loadCanvasData(data)),
    saveProject: (data, saveId, projectName, width, height, permission, font_data, brand_token, partnership_link, recentlyUsedImages, recentlyUsedPhotos, callback) => dispatch(saveProject(data, saveId, projectName, width, height, permission, font_data, brand_token, partnership_link, recentlyUsedImages, recentlyUsedPhotos, callback)),
    exportProject: (param, callback) => dispatch(exportProject(param, callback)),
    undoHistory: () => dispatch(undoHistory()),
    redoHistory: () => dispatch(redoHistory()),
    addElementByData: (data, point, page) => dispatch(addElementByData(data, point, page)),
    saveDesignerTemplate: (code, page, saveType, width, height, callback) => dispatch(saveDesignerTemplate(code, page, saveType, width, height, callback)),
    bindProject: (callback) => dispatch(bindProject(callback)),
    toggleItemVisibility: (itemCode) => dispatch(toggleItemVisibility(itemCode)),
    setTextEditMode: () => dispatch(setTextEditMode()),
    setProjectName: (project_name) => dispatch(setProjectName(project_name)),
    savePreview: (width, height, callback) => dispatch(savePreview(width, height, callback)),
    promptLogin: (callback) => dispatch(promptLogin(callback)),
    getProjectShare: (callback) => dispatch(getProjectShare(callback)),
    setProjectShare: (email, permission, scope, callback) => dispatch(setProjectShare(email, permission, scope, callback)),
    setProjectShareTeam: (team_id, permission, scope, callback) => dispatch(setProjectShareTeam(team_id, permission, scope, callback)),
    promptSlackLogin: (callback) => dispatch(promptSlackLogin(callback)),
    getFolderList: (callback) => dispatch(getFolderList(callback)),
    setProjectFolder: (folder_id, callback) => dispatch(setProjectFolder(folder_id, callback)),
    setAuthStatus: (authStatus) => dispatch(setAuthStatus(authStatus)),
    //setVerifyStatus: (verifyStatus, verifyNickname, verifyEmail) => dispatch(setVerifyStatus(verifyStatus, verifyNickname, verifyEmail)),
    setAccessLevel: (access_level) => dispatch(setAccessLevel(access_level)),
    setProjectTarget: (project_target) => dispatch(setProjectTarget(project_target)),
    setAlertBox: (alertBoxContent) => dispatch(setAlertBox(alertBoxContent)),
    resizePage: (width, height) => dispatch(resizePage(width, height)),
    setZoomScaleByContainerSize: (width, height) => dispatch(setZoomScaleByContainerSize(width, height, 'fit')),
    slackUploadFile: (slack_token, text, channels, file_title, callback) => dispatch(slackUploadFile(slack_token, text, channels, file_title, callback)),
    checkSocialMediaToken: (media_type, media_id, callback) => dispatch(checkSocialMediaToken(media_type, media_id, callback)),
    resetCheckoutStep: () => dispatch(resetCheckoutStep()),
    fetchQuote: () => dispatch(fetchQuote()),
    closeElementMenu: () => dispatch(closeElementMenu()),
    closeSideBar: () => dispatch(closeSideBar()),
    changeElementMenu: () => dispatch(changeElementMenu()),
    sendVerificationEmail: (callback) => dispatch(sendVerificationEmail(callback)),
    setCheckoutError: (message) => dispatch(setCheckoutError(message)),
    setProjectWaterMark: (has_watermark) => dispatch(setProjectWaterMark(has_watermark)),
    setIsPrintable: (is_printable) => dispatch(setIsPrintable(is_printable)),
    setPartnershipLink: (partnership_link) => dispatch(setPartnershipLink(partnership_link)),
    setPartnershipParam: (partnership_param) => dispatch(setPartnershipParam(partnership_param)),
    addFolder: (folder_name, callback) => dispatch(addFolder(folder_name, callback)),
    setModalRandom: (random) => dispatch(setModalRandom(random)),
    applyWordwrapHint: () => dispatch(applyWordwrapHint()),
    setPaymentTerm: (payment_term) => dispatch(setPaymentTerm(payment_term)),
    setSelectFromTeamID: (select_from_team_id, team) => dispatch(setSelectFromTeamID(select_from_team_id, team)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectControlPanel);
