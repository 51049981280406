import React, { Suspense, lazy } from 'react';
import './page.scss';

import {connect} from 'react-redux';
import classNames from "classnames";
import Element from '../Element';
// import ImageCropTool from '../ImageCropTool';
// import TransformTool from '../TransformTool';

import {adaptImageData, findGroupElementByChildElement, isWithinMargin} from "../../utilities/canvasData";
//css and images
import {
    addElementByData,
    applyDragImagePreview,
    createBackgroundAndApplyDragImagePreview,
    duplicatePage,
    movePageDown,
    movePageUp,
    setElementProperty,
    toggleItemVisibility
} from '../../actions';

import delete_icon from './Delete-page.svg';
import upper_icon from './Upper-page.svg';
import lower_icon from './lower-page.svg';
import copy_icon from './copy-page.svg';
import opaque_bg from './opaque.png';
import icon_comment from './icon_comment.png';

const ImageCropTool = lazy(() => import('../ImageCropTool'));
const TransformTool = lazy(() => import('../TransformTool'));

class Page extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            width: props.data.width,
            height: props.data.height,
            //elements: props.data.elements,
            idx: props.idx,
            print_only: props.print_only,
            no_side: props.no_side,
            // backgroundColor: props.data.backgroundColor,
        };
        //this.pageRef = React.createRef();
        this.drop = this.drop.bind(this);
        this.allowDrop = this.allowDrop.bind(this);
        this.drapStart = this.drapStart.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        if(props.data.width !== this.state.width || props.data.height !== this.state.height /* || JSON.stringify(props.data.elements) !== JSON.stringify(this.state.elements) */) {
            this.setState({
                width: props.data.width,
                height: props.data.height,
                // elements: props.data.elements
                // backgroundColor: props.data.backgroundColor,
            })
        }
    }

    containActiveElement() {
        if (!this.props.activeElements) return false;
        if (!this.props.activeElements.length) return false;
        for (let activeElement of this.props.activeElements) {
            for (let element of this.props.data.elements) {
                if (activeElement && activeElement.hash === element.hash) return true;
            }
        }
        return false;
    }

    shouldShowImageCropTool() {
        // console.log('shouldShowImageCropTool');
        return this.props.editMode === 'crop' && this.containActiveElement();
    }

    shouldShowTransformTool() {
        // console.log('shouldShowTransformTool');
        return (this.props.editMode === 'transform' || this.props.editMode === 'text') && this.containActiveElement() && !(this.props.activeElements.length === 1 && this.props.activeElements[0].type === 'background');
    }

    drop(ev) {
        if (this.props.permission === "read") {
            return false;
        }
        let rect = ev.currentTarget.getBoundingClientRect();
        let x_pos = ev.clientX - rect.left;
        let y_pos = ev.clientY - rect.top;
        if (this.props.dragImageData) {
            if ((this.props.dragImageData.type === 'background') || isWithinMargin(this.props.data, x_pos / this.props.zoomScale, y_pos / this.props.zoomScale)) {
                let background = this.props.data.elements.find(element => element.type === 'background');
                if (background) {
                    if (!background.photoUrl) {
                        background.imageWidth = this.props.data.width;
                        background.imageHeight = this.props.data.height;
                        background.imageOriginLeft = 0;
                        background.imageOriginTop = 0;
                    }
                    let newImageData = adaptImageData(background, this.props.dragImageData);
                    this.props.setElementProperty(background.hash, {
                        photoUrl: newImageData.photoUrl,
                        imageWidth: newImageData.imageWidth,
                        imageHeight: newImageData.imageHeight,
                        imageOriginLeft: newImageData.imageOriginLeft,
                        imageOriginTop: newImageData.imageOriginTop
                    });
                    this.props.applyDragImagePreview(null, null);
                    ev.preventDefault();
                    ev.stopPropagation();
                    return;
                }

            }
        }
        ev.preventDefault();

        let data = ev.dataTransfer.getData("text");
        if (data) {
            let pos = {centerX: x_pos / this.props.zoomScale, centerY: y_pos / this.props.zoomScale};
            let page = this.props.idx;
            this.props.addElementByData(data, pos, page);
        }
    }

    drapStart() {
        this.backgroundCache = null;
    }

    onDragLeave() {
        this.dragImageDataCache = null;
        let background = this.props.data.elements.find(element => element.type === 'background');
        if (background) {
            this.props.applyDragImagePreview(background.hash, null);
        }
    }

    allowDrop(ev) {
        const printBleedPadding = 1.5 / 25.4 * 300; // 1.5mm
        // console.log("allowDrop");
        if (this.props.permission === "read") {
            return false;
        }
        if (this.props.previewOnly) {
            return false;
        }
        ev.preventDefault();

        if (this.props.dragImageData) {
            const rect = ev.currentTarget.getBoundingClientRect();
            const x_pos = ev.clientX - rect.left;
            const y_pos = ev.clientY - rect.top;
            let background = null;
            if (this.backgroundCache) {
                background = this.backgroundCache;
            } else {
                background = this.props.data.elements.find(element => element.type === 'background');
                this.backgroundCache = background;
            }

            if ((this.props.dragImageData.type === 'background') || isWithinMargin(this.props.data, x_pos / this.props.zoomScale, y_pos / this.props.zoomScale)) {
                if (this.dragImageDataCache == this.props.dragImageData) {
                    return;
                }
                this.dragImageDataCache = this.props.dragImageData;
                if (background) {
                    if (!background.photoUrl) {
                        background.imageWidth = this.props.data.width + printBleedPadding * 2;
                        background.imageHeight = this.props.data.height + printBleedPadding * 2;
                        background.imageOriginLeft = -printBleedPadding;
                        background.imageOriginTop = -printBleedPadding;
                    }
                    let newImageData = adaptImageData(background, this.props.dragImageData);
                    this.props.applyDragImagePreview(background.hash, newImageData);
                    this.dragImageDataCache = this.props.dragImageData;
                } else {
                    this.dragImageDataCache = this.props.dragImageData;
                    this.props.createBackgroundAndApplyDragImagePreview(this.props.dragImageData, this.props.data.width, this.props.data.height);
                }
                ev.preventDefault();
                ev.stopPropagation();
            } else {
                if (background && this.dragImageDataCache) {
                    this.props.applyDragImagePreview(background.hash, null);
                }
                this.dragImageDataCache = null;
            }
        }
    }

    movePageUp() {
        this.props.movePageUp(this.props.data.hash);
        this.props.scrollToPage(this.props.idx - 1);
    }

    movePageDown() {
        this.props.movePageDown(this.props.data.hash);
        this.props.scrollToPage(this.props.idx + 1);
    }

    duplicatePage() {
        this.props.duplicatePage(this.props.data.hash);
        this.props.scrollToPage(this.props.idx + 1);
    }

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in this.props) {
            if (key === 'activeElements' || key === 'data') {
                if (JSON.stringify(this.props[key]) != JSON.stringify(nextProps[key])) {
                    return true;
                }
            } else {
                if (this.props[key] != nextProps[key]) {
                    return true;
                }
            }

        }
        for (let key in this.state) {
            if (this.state[key] != nextState[key]) {
                return true;
            }
        }
        return false;
    }

    render() {
        let self = this;
        let renderOverlayUpload = [];
        const renderElements = this.props.data.elements ? this.props.data.elements.map((element, index) => {
            if (typeof element === 'string') return '';
            const groupElement = findGroupElementByChildElement(this.props.data.elements, element);
            let elementData = null;
            if (groupElement) {
                elementData = {
                    ...element,
                    opacity: (typeof groupElement.opacity === 'undefined' ? 1 : groupElement.opacity) * (typeof element.opacity === 'undefined' ? 1 : element.opacity),
                }
            } else {
                elementData = element;
            }
            let overlayElement = elementData;
            if (self.props.isProductSlider && ['photo', 'background'/*, 'graphic', 'background'*/].indexOf(element.type) !== -1) {
                if (elementData.photoUrl) {
                    let photoUrl = elementData.photoUrl;
                    //let api_host = `${process.env.REACT_APP_API_HOST}`;
                    if ((photoUrl.indexOf(".svg") < 0) && (elementData.imageWidth * self.props.zoomScale > 0)) {
                        if (photoUrl.indexOf("https://jd-res.s3.ap-east-1.amazonaws.com/") !== -1) {
                            if (elementData.imageWidth * self.props.zoomScale < 1) {
                                photoUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                            } else {
                                photoUrl = photoUrl.replace("https://jd-res.s3.ap-east-1.amazonaws.com/", "https://d2b72aifdvmdso.cloudfront.net/production/") + (photoUrl.indexOf("?") === -1 ? "?" : '&') + "width=" + Math.ceil(elementData.imageWidth * self.props.zoomScale);
                            }
                        }
                        elementData = {...elementData, photoUrl}
                    }
                }
            }
            if (self.props.instantUpload && ['photo'/*, 'graphic', 'background'*/].indexOf(element.type) !== -1 && element.replaceable) {
                renderOverlayUpload.push(
                    <Element
                        instantUpload={self.props.instantUpload}
                        mode='overlay'
                        recalculateFont={false}
                        key={`overlay_${this.props.idx}_${index}`}
                        data={overlayElement}
                        id={`overlay_${this.props.idx}_${index}`}
                        zoomScale={this.props.zoomScale}
                    />
                );
            }
            return (<Element
                recalculateFont={self.props.recalculateFont}
                key={`${this.props.idx}_${index}`}
                previewOnly={this.props.previewOnly}
                print_only={this.state.print_only}
                data={elementData}
                id={`${this.props.idx}_${index}`}
                zoomScale={this.props.zoomScale}
            />);
        }) : [];

        let transformToolKey = this.props.editMode === 'text' ? (this.props.activeElements.map(activeElement => activeElement.hash).join('-') + '-transform-text-edit') : JSON.stringify(this.props.activeElements.map(element => ({
            ...element,
            wordwrapHint: null
        })));
        const imageCropTool = this.shouldShowImageCropTool() ? (<ImageCropTool/>) : null;
        const transformTool = this.shouldShowTransformTool() ? (
            <TransformTool key={transformToolKey} pageWidth={this.state.width * this.props.zoomScale}
                           pageHeight={this.state.height * this.props.zoomScale}
                           previewOnly={this.props.previewOnly}/>) : null;

        const currentPageNo = this.props.idx + 1;
        const hasNextPage = (this.props.totalPage - 1) > this.props.idx;
        const hasPrevPage = this.props.idx > 0;

        //const padding = 30;
        const marginStyle = {
            left: this.props.canvasPadding * this.props.zoomScale,
            top: this.props.canvasPadding * this.props.zoomScale,
            width: (this.state.width - this.props.canvasPadding * 2) * this.props.zoomScale,
            height: (this.state.height - this.props.canvasPadding * 2) * this.props.zoomScale,
        };
        const margin = this.props.isShowingMargin ? (
            <div className="page_margin">
                <div className="page_margin_bg" style={marginStyle}></div>
                <div className="page_margin_fg" style={marginStyle}></div>
            </div>
        ) : null;

        const printBleedPadding = 0;//1.5 / 25.4 * 300; // 1.5mm

        const printBleed = (this.state.print_only || this.props.isShowingPrintBleed) && !this.props.previewOnly ?  (<div
            className={classNames('overflow_class2', {colored: this.props.shouldShowColoredBleed}, {print_only: this.state.print_only})}
            style={{
                position: 'relative',
                width: (this.state.width + printBleedPadding * 2) * this.props.zoomScale,
                height: (this.state.height + printBleedPadding * 2) * this.props.zoomScale,
                top: -printBleedPadding * this.props.zoomScale,
                left: -printBleedPadding * this.props.zoomScale
            }}>
            <div className="overflow_class3" style={{
                left: printBleedPadding * this.props.zoomScale,
                top: printBleedPadding * this.props.zoomScale
            }}>
                {renderElements}
            </div>
        </div>) : null;

        let designBackgroundStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
        };
        let designBackgroundContainerStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
        };
        let pageMargin = {
            width: (this.state.width + printBleedPadding * 2) * this.props.zoomScale,
            height: (this.state.height + printBleedPadding * 2) * this.props.zoomScale,
            marginLeft: (this.state.print_only ? (printBleedPadding * this.props.zoomScale) : 0),
            marginRight: (this.state.print_only ? (printBleedPadding * this.props.zoomScale) : 0),
            marginTop: (this.state.print_only ? (printBleedPadding * this.props.zoomScale) : 0),
            marginBottom: (this.state.print_only ? (printBleedPadding * this.props.zoomScale) : 0),
        };
        if (this.shouldShowImageCropTool() || this.shouldShowTransformTool()) {
            pageMargin.zIndex = 10;
        }
        // let backgroundOffsetLeft = 0;
        // let backgroundOffsetTop = 0;
        if (this.props.currentDesignBackground) {
            let printingWidth = this.props.currentDesignBackground.design_canvas_location.width;
            let printingHeight = this.props.currentDesignBackground.design_canvas_location.height;
            let printingZoom = 1;
            if (this.state.width / printingWidth > this.state.height / printingHeight) {
                printingZoom = this.state.width / printingWidth;
            } else {
                printingZoom = this.state.height / printingHeight;
            }

            designBackgroundStyle.top = (-this.props.currentDesignBackground.design_canvas_location.top) * printingZoom * this.props.zoomScale;
            designBackgroundStyle.left = (-this.props.currentDesignBackground.design_canvas_location.left) * printingZoom * this.props.zoomScale;
            designBackgroundStyle.width = this.props.currentDesignBackground.design_background_width * printingZoom * this.props.zoomScale;
            designBackgroundStyle.height = this.props.currentDesignBackground.design_background_height * printingZoom * this.props.zoomScale;
            designBackgroundContainerStyle.width = designBackgroundStyle.width;
            designBackgroundContainerStyle.height = designBackgroundStyle.height;
            if (this.props.previewOnly) {
                delete pageMargin.marginTop;
                delete pageMargin.marginBottom;
            } else {
                pageMargin.marginTop += this.props.currentDesignBackground.design_canvas_location.top * printingZoom * this.props.zoomScale;
                pageMargin.marginBottom += (this.props.currentDesignBackground.design_background_height) * printingZoom * this.props.zoomScale - this.state.height * this.props.zoomScale;
            }
        }

        return (
            <div style={{marginTop: this.props.idx && !this.props.previewOnly?'10px':null}} translate="no">
                <div key="page_container" id={this.props.containerId ? this.props.containerId : "page_container"}
                     style={pageMargin} className={classNames("page_container", {print_only: this.state.print_only})}>
                    <div>
                        <div id={this.props.id} className="page"
                             style={{
                                 width: this.state.width * this.props.zoomScale,
                                 height: this.state.height * this.props.zoomScale,
                                 backgroundImage: `url(${opaque_bg})`,
                                 marginLeft: (this.state.print_only || this.state.no_side) ? 0 : 50
                             }}
                             onDrop={this.drop} onDragStart={this.drapStart.bind(this)}
                             onDragOver={this.allowDrop} onDragLeave={this.onDragLeave.bind(this)}>
                            {this.props.currentDesignBackground && designBackgroundContainerStyle.width && Math.ceil(designBackgroundContainerStyle.width)>1 ? (
                                <div style={designBackgroundStyle}>
                                    <img className={classNames("design-background")}
                                         style={designBackgroundContainerStyle}
                                         src={this.props.isProductSlider && this.props.currentDesignBackground.design_background.indexOf(".svg") === -1 && this.props.currentDesignBackground.design_background.indexOf(".gif") === -1 && this.props.currentDesignBackground.design_background.indexOf("https://jd-res.s3.ap-east-1.amazonaws.com/") !== -1 ? this.props.currentDesignBackground.design_background.replace("https://jd-res.s3.ap-east-1.amazonaws.com/", "https://d2b72aifdvmdso.cloudfront.net/production/") + (this.props.currentDesignBackground.design_background.indexOf("?") === -1 ? "?" : '&') + "width=" + Math.ceil(designBackgroundContainerStyle.width) : this.props.currentDesignBackground.design_background}/>
                                </div>) : null}
                            {printBleed}
                            <div
                                className={classNames("overflow_class", {hide: this.state.print_only}, {white_bg: (!this.props.currentDesignBackground || !this.props.currentDesignBackground.design_background) && (currentPageNo <= this.props.min_page)})}
                                style={{
                                    width: this.state.width * this.props.zoomScale,
                                    height: this.state.height * this.props.zoomScale
                                }}>
                                {renderElements}
                            </div>
                            {this.props.currentDesignBackground && this.props.currentDesignBackground.design_layout_background && designBackgroundContainerStyle.width && Math.ceil(designBackgroundContainerStyle.width)>1 ? (
                                <div style={designBackgroundStyle}>
                                    <img className={classNames("design-background")}
                                         style={designBackgroundContainerStyle}
                                         src={this.props.currentDesignBackground.design_layout_background.indexOf(".svg") === -1 && this.props.currentDesignBackground.design_layout_background.indexOf(".gif") === -1 && this.props.currentDesignBackground.design_layout_background.indexOf("https://jd-res.s3.ap-east-1.amazonaws.com/") !== -1 ? this.props.currentDesignBackground.design_layout_background.replace("https://jd-res.s3.ap-east-1.amazonaws.com/", "https://d2b72aifdvmdso.cloudfront.net/production/") + (this.props.currentDesignBackground.design_layout_background.indexOf("?") === -1 ? "?" : '&') + "width=" + Math.ceil(designBackgroundContainerStyle.width) : this.props.currentDesignBackground.design_layout_background}/>
                                </div>) : null}
                            {renderOverlayUpload.length ? <div
                                className={classNames("overflow_class", {hide: this.state.print_only}, {white_bg: !this.props.currentDesignBackground || !this.props.currentDesignBackground.design_background})}
                                style={{
                                    width: this.state.width * this.props.zoomScale,
                                    height: this.state.height * this.props.zoomScale
                                }}>
                                {renderOverlayUpload}
                            </div> : ''}
                            <Suspense fallback={<span></span>}>
                            {this.props.previewOnly ? null : imageCropTool}
                            {this.props.previewOnly ? null : transformTool}
                            </Suspense>
                            {margin}
                            <div className={classNames("danger_zone", {hide: !this.props.shouldShowDangerZone})}
                                 style={{
                                     outlineWidth: printBleedPadding * this.props.zoomScale,
                                     borderWidth: printBleedPadding * this.props.zoomScale,
                                     width: (this.state.width - printBleedPadding * 2) * this.props.zoomScale,
                                     height: (this.state.height - printBleedPadding * 2) * this.props.zoomScale
                                 }}>
                            </div>
                        </div>
                    </div>
                    <div className="hidden_container" style={{
                        width: this.state.width * this.props.zoomScale,
                        height: this.state.height * this.props.zoomScale
                    }}></div>
                    <div className={classNames("page_header", {hide: this.state.print_only || this.state.no_side})}>
                        <div className="page_title"></div>
                        {this.props.hidePageOption ? null : (
                            <div title="move down" className={classNames('page_header_item', {inactive: !hasPrevPage})}
                                 onClick={() => hasPrevPage ? this.movePageUp() : null}><img
                                className="page_sidebar_img" src={upper_icon}/></div>)}
                        {this.props.hidePageOption ? null : (
                            <div className="page_header_item"><span className="page_index">{currentPageNo}</span>
                            </div>)}
                        {this.props.hidePageOption ? null : (
                            <div title="move up" className={classNames('page_header_item', {inactive: !hasNextPage})}
                                 onClick={() => hasNextPage ? this.movePageDown() : null}><img
                                className="page_sidebar_img" src={lower_icon}/></div>)}
                        {(this.props.hidePageOption || this.props.totalPage >= this.props.max_page) ? null : (
                            <div className="page_header_item" onClick={this.duplicatePage.bind(this)}><img
                                src={copy_icon} className="page_sidebar_img"/></div>)}
                        {(this.props.hidePageOption || this.props.min_page >= this.props.totalPage) ? null : (<div
                            className={classNames('page_right_group', 'page_header_item', {inactive: !this.props.canDeletePage})}>

                            <div className="page_delete"
                                 onClick={e => this.props.canDeletePage ? this.props.handleDeletedPage(e, this.state.idx) : null}>
                                <img src={delete_icon}
                                     className={classNames('page_sidebar_img', this.props.del_btn_class)}/>
                            </div>
                        </div>)}
                        <div className="page_header_item">
                            <img className="icon_comment" src={icon_comment}/>
                        </div>
                    </div>
                </div>
                <div className="page_break"></div>
            </div>

        );
    }

}

const mapStateToProps = state => {
    // let currentDesignBackground = null;
    // if (state.currentProjectOptions && state.projectOptions && state.projectOptions.images) {
    //     let imageData = state.projectOptions.images.find(image => {
    //         let match = true;
    //         for (let option in state.currentProjectOptions) {
    //             let variation = state.currentProjectOptions[option];
    //             if (image[option] != variation) {
    //                 match = false;
    //                 break;
    //             }
    //         }
    //         return match;
    //     })
    //     if (imageData) {
    //         currentDesignBackground = imageData;
    //     }
    // }
    return {
        shouldShowColoredBleed: state.itemsVisibility['checkout'] && state.checkout.checkoutStep === 3,
        shouldShowDangerZone: state.itemsVisibility['checkout'] && state.checkout.checkoutStep === 4,
        totalPage: state.pages.length,
        activeElements: state.activeElements,
        canDeletePage: state.pages.length > 1,
        // zoomScale: state.zoomScale,
        editMode: state.editMode,
        isShowingPrintBleed: state.isShowingPrintBleed,
        isShowingMargin: state.isShowingMargin,
        canvasPadding: state.pages.length ? Math.min(state.pages[0].width, state.pages[0].height) * 0.1 : 0,
        dragImageData: state.dragImageData,
        permission: state.permission,
        // currentDesignBackground: currentDesignBackground,
        hidePageOption: state.hidePageOption,
        max_page: state.max_page,
        min_page: state.min_page,
    }
};

const mapDispatchToProps = dispatch => ({
    addElementByData: (data, point, page) => dispatch(addElementByData(data, point, page)),
    setElementProperty: (elementHash, changes) => dispatch(setElementProperty(elementHash, changes)),
    movePageUp: (hash) => dispatch(movePageUp(hash)),
    movePageDown: (hash) => dispatch(movePageDown(hash)),
    duplicatePage: (hash) => dispatch(duplicatePage(hash)),
    applyDragImagePreview: (hash, imageData) => dispatch(applyDragImagePreview(hash, imageData)),
    createBackgroundAndApplyDragImagePreview: (imageData, width, height) => dispatch(createBackgroundAndApplyDragImagePreview(imageData, width, height)),
    toggleItemVisibility: (itemCode) => dispatch(toggleItemVisibility(itemCode)),
});

Page.defaultProps = {
    recalculateFont: true,
    zoomScale: 1
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);
