import React from 'react';
//import $ from 'jquery';
import classNames from "classnames";
import {connect} from 'react-redux';
import {addElementByData, addNewText, getDesignElement} from '../../../actions';
import ContentLoader from 'react-content-loader'

import MenuElement from '../MenuElement';
import addIcon from '../add.png';
import '../add_hover.png';

import '../sideControlPanel.scss';

class TextElement extends React.PureComponent {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = {
            menu_elements: [],
            offset: 0,
            limit: 20,
            stopMore: false,
            loading: false,
            _myFavouriteFilter: this.props.myFavouriteFilter,
        };
        this.getDesignElement = props.getDesignElement.bind(this);
        this.refreshDesignElement = this.refreshDesignElement.bind(this);
        this.scrollEnd = this.scrollEnd.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.addHeading = this.addHeading.bind(this);

    }

    addHeading() {
        if (this.props.permission === "read") {
            return false;
        }
        this.props.addNewText();
        if (this.props.onItemSelected) {
            this.props.onItemSelected();
        }
    }

    refreshDesignElement(append) {

        var self = this;

        var offset = this.state.offset;
        if (!append) {
            this.setState({
                loading: true,
                offset: 0,
                stopMore: false,
                menu_elements: [],
            });
            offset = 0;
        }

        this.getDesignElement("text", 0, 0, "", offset, this.state.limit, {}, {}, function (json) {
            if (!self._isMounted) {
                return;
            }

            if (json.elements.length > 0) {
                var menu_elements = [];
                if (append) {
                    menu_elements = self.state.menu_elements;
                }
                for (var i = 0; i < json.elements.length; i++) {
                    menu_elements.push({
                        preview_url: json.elements[i].preview_url,
                        json: json.elements[i].json,
                        name_code: json.elements[i].name_code,
                        star: (json.elements[i].star === undefined ? false : json.elements[i].star),
                        element_id: json.elements[i].id,
                    });
                }
                self.setState({
                    menu_elements: menu_elements,
                    loading: false
                }, function () {
                    var element = self.refs.text_div;
                    if (element.scrollHeight < element.offsetHeight + 50 && !self.state.stopMore) {
                        self.loadMore();
                    }
                });
            } else {
                self.setState({
                    stopMore: true,
                    loading: false,
                });
            }

        }, this.props.hash);

    }

    loadMore() {
        this.setState({
            offset: this.state.offset + this.state.limit
        }, () => this.refreshDesignElement(true));
    }

    scrollEnd() {

        var element = this.refs.text_div;
        if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight) {
            if (!this.state.stopMore) {
                this.loadMore();
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.refreshDesignElement(false);
    }

    componentDidUpdate() {
        var self = this;
        if (this.state._myFavouriteFilter !== this.props.myFavouriteFilter) {
            this.setState({
                _myFavouriteFilter: self.props.myFavouriteFilter,
            });
            this.refreshDesignElement(false);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        const renderElements = this.state.menu_elements ? this.state.menu_elements.map((element, index) =>
            (<MenuElement key={index} type='text' idx={index} preview_url={element.preview_url}
                          name_code={element.name_code} json={element.json} star={element.star}
                          element_id={element.element_id} onItemSelected={this.props.onItemSelected}/>)
        ) : [];


        let loaderElements = [];
        let loaderHeight = 156;
        for (let i = 0; i < 10; i++) {
            loaderHeight += 156;
            loaderElements.push((
                <rect key={"text_loader_1_" + i} x="3" y={i * 156} rx="8" ry="8" width="152" height="150"/>
            ));
            loaderElements.push((
                <rect key={"text_loader_2_" + i} x="161" y={i * 156} rx="8" ry="8" width="152" height="150"/>
            ));
        }

        return (
            <div className="text_menu">
                <div className="text_heading_section">
                    <div className="text_heading_3" onClick={this.addHeading.bind(this)}>
                        <div className="add_text_div">
                            <div className="add_icon_container">
                                <img className="add_icon" src={addIcon}/>
                                <img className="add_icon_hover" src={addIcon}/>
                            </div>
                            {this.props.current_lang === 'en' ? 'Add Text Box' : '增加文字框'}
                        </div>
                    </div>
                </div>
                <div className="text_div" onScroll={this.scrollEnd.bind(this)} ref="text_div">
                    <div
                        className={classNames("empty_view", (this.state.loading || renderElements.length) ? 'hide' : '')}>
                        Sorry, we cannot find appropiate content to display, please try something else.
                    </div>
                    <div className="element_list">
                        {renderElements}
                    </div>
                    <div className={classNames((!this.state.loading ? 'hide' : ''))}>
                        <ContentLoader animate={true} width="316" height={loaderHeight} primaryColor="#E6E7E8">
                            {loaderElements}
                        </ContentLoader>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
    getDesignElement: (element_type, image_type, category_id, keyword, offset, limit, other, brand_token, callback, hash) => dispatch(getDesignElement(element_type, image_type, category_id, keyword, offset, limit, other, brand_token, callback, hash)),
    addElementByData: (data) => dispatch(addElementByData(data)),
    addNewText: () => dispatch(addNewText()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextElement);
