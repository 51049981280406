import React from 'react';
import classNames from "classnames";
//css
import './popup.css';
import arrow from './adjust-text-size.png';
import './arrow.png';
//views
import PickerPanel from '../PickerPanel';

class Popup extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        const is_english_font = !props.fonts_other.find(font => font.value === props.activeElements);
        this.state = ({
            show_english_font: is_english_font,
            show_other_font: !is_english_font,
            show_custom_font: false,
            font_type_modal: false,
            custom_font_name: '',
            font_type_name: is_english_font ? 'English Fonts' : 'Other Fonts'
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleFontClick = this.handleFontClick.bind(this);
        this.handleFontType = this.handleFontType.bind(this);
    }

    handleChange(e, selectedOption) {
        e.preventDefault();
        let obj = {
            [this.props.id]: selectedOption.value,
        };
        this.props.applyPropertyChange(obj);
    }

    handleInputChange(e) {
        e.preventDefault();
        let value = e.target.value;
        this.props.applyPropertyChange({"textFontSize": value})
    }

    handleClick() {
        this.props.handleFontList(this.props.id)
    }

    handleFontType() {
        var val = this.state.font_type_modal;
        if (!val) {
            this.setState({font_type_modal: true})
        } else {
            this.setState({font_type_modal: false})
        }
    }

    handleFontClick(e) {
        if (e.target.id !== "english_fonts" && e.target.id !== "other_font") {
            this.setState({
                show_custom_font: true,
                font_type_name: e.target.id,
                custom_font_name: e.target.id,
                show_other_font: false,
                show_english_font: false
            })
        } else if (e.target.id === "english_fonts") {
            this.setState({
                show_english_font: true,
                font_type_name: 'English Fonts',
                custom_font_name: "",
                show_other_font: false,
                show_custom_font: false
            })
        } else {
            this.setState({
                show_other_font: true,
                font_type_name: 'Other Fonts',
                custom_font_name: "",
                show_english_font: false,
                show_custom_font: false
            })
        }
        this.setState({font_type_modal: false})
    }

    render() {
        let selectedElements;
        if (this.props.id === "textFontFamily") {
            selectedElements = this.props.activeFonts ? this.props.activeFonts.filter(fontData => !!fontData.img).map((element, index) =>
                (<PickerPanel
                    key={index}
                    ref={(cd) => this.pageRef = cd}
                    data={element}
                    id={`${this.props.id}_${index}`}
                    handleChange={this.handleChange}
                    currentOption={this.props.activeElements}
                />)
            ) : [];
        }
        const renderElements = this.props.fonts && this.state.show_english_font ? (this.props.id === "textFontFamily"?this.props.fonts.filter(fontData => !!fontData.img):this.props.fonts).map((element, index) =>
            (<PickerPanel
                key={index}
                ref={(cd) => this.pageRef = cd}
                data={element}
                id={`${this.props.id}_${index}`}
                handleChange={this.handleChange}
                currentOption={this.props.activeElements}
            />)
        ) : [];

        const renderElementsOther = this.props.id === "textFontFamily" && this.props.fonts_other && this.state.show_other_font ? this.props.fonts_other.filter(fontData => !!fontData.img).map((element, index) =>
            (<PickerPanel
                key={index}
                ref={(cd) => this.pageRef = cd}
                data={element}
                id={`${this.props.id}_${index}`}
                handleChange={this.handleChange}
                currentOption={this.props.activeElements}
            />)
        ) : [];
        let renderBrandName = [];
        if (this.props.id === "textFontFamily" && this.props.custom_fonts && this.props.custom_fonts.length > 0) {
            renderBrandName = this.props.custom_fonts.map((brand, index) => {
                return (
                    <li key={index}
                        style={{display: brand.brand_font_list && brand.brand_font_list.length > 0 ? 'block' : 'none'}}
                        onClick={this.handleFontClick} id={brand.brand_name}
                        className="label_container custom_font">{brand.brand_name}</li>
                )
            });
        }


        let renderElementsUploaded = [];
        if (this.props.id === "textFontFamily" && this.props.custom_fonts && this.props.custom_fonts.length > 0) {
            renderElementsUploaded = this.props.custom_fonts.map((brand, i) => {
                return (
                    <div id={brand.brand_name} key={i}
                         style={{display: brand.brand_name === this.state.custom_font_name ? 'block' : 'none'}}>{brand.brand_font_list.filter(fontData => !!fontData.img).map((element, index) => {
                        return (
                            (<PickerPanel
                                key={index}
                                ref={(cd) => this.pageRef = cd}
                                data={element}
                                id={`${this.props.id}_${index}`}
                                handleChange={this.handleChange}
                                currentOption={this.props.activeElements}
                            />)
                        )
                    })}</div>
                )
            });
        }

        let fonts = this.props.fonts;
        if (this.props.id === "textFontFamily" && this.props.fonts_other && Array.isArray(this.props.fonts_other)) {
            fonts = fonts.concat(this.props.fonts_other);
        }

        var fontFamily = fonts.find(font => font.value === this.props.activeElements);

        var obj = {
            "fontFamily": fontFamily ?
                fontFamily.value :
                (this.props.fonts[0]?
                    this.props.fonts[0].value:
                    null),
        };

        const is_active = this.props.showList && this.props.showList.listname === this.props.id && this.props.id === "textFontSize" ? "active_btn" : "";
        const label_width_style = this.props.id === "textFontSize" ? "label_width_fsize" : "";
        return (
            <div className="property_panel" x-placement="bottom-start">
                <div className="property_title">
                    <div className="property_container">
                        <button className={classNames("property_btn", is_active)} onClick={this.handleClick}>
                            <div className="property_title_box">
                                <div className={classNames("label_width", label_width_style)}>
                                    {this.props.id === "textFontSize" ?
                                        <input className="property_label" style={obj} type="text" pattern="\d*"
                                               max="100"
                                               min="1" step="0.1" placeholder="– –" onFocus={e => e.target.select()}
                                               value={this.props.activeElements}
                                               onChange={e => this.handleInputChange(e)}/>
                                        :
                                        <span className="property_label" style={obj}>{this.props.activeElements}</span>
                                    }
                                </div>
                                <div className="img_class"><img src={arrow}/></div>
                            </div>
                        </button>
                    </div>
                </div>
                {this.props.showList && this.props.showList.listname === this.props.id ?
                    <div className={classNames("popup_contianer show_popup_contianer show_popup_contianer", this.props.id?this.props.id:'')}>
                        {this.props.id === "textFontFamily" || this.props.id === "textFontSize" ?
                            <div className="square_contianer s_contianer">
                                <div id="picker-panel" className="picker-panel">
                                    <div className="picker_list widget-list">
                                        {this.props.id === "textFontFamily" ? <div><span>{selectedElements}</span>
                                            <hr/>
                                            <div className="font_type_container" onClick={this.handleFontType}>
                                                <span className={classNames("font_type", this.state.font_type_modal ? 'toggled' : '')}>{this.state.font_type_name}</span>
                                                <div className="choose_font_type"
                                                     style={{display: this.state.font_type_modal ? 'block' : 'none'}}>
                                                    <ul>
                                                        {renderBrandName}
                                                        <li onClick={this.handleFontClick} id="english_fonts"
                                                            className="label_container english_fonts">English Fonts
                                                        </li>
                                                        <li onClick={this.handleFontClick} id="other_font"
                                                            className="label_container other_font">Other Fonts
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <hr/>
                                        </div> : ""}
                                        {renderElementsUploaded.length?<div>
                                            <ul>{renderElementsUploaded}</ul>
                                        </div>:''}
                                        {renderElements.length?<div style={{display: this.state.show_english_font ? 'block' : 'none'}}>
                                            <ul>{renderElements}</ul>
                                        </div>:''}
                                        {renderElementsOther.length?<div style={{display: this.state.show_other_font ? 'block' : 'none'}}>
                                            <ul>{renderElementsOther}</ul>
                                        </div>:''}

                                    </div>
                                </div>
                            </div>
                            :
                            <div className="rect_contianer s_contianer">

                            </div>
                        }

                    </div>
                    : ""}

            </div>
        )
    }
}

Popup.defaultProps = {
    fonts: [],
    fonts_other: [],
};

export default Popup;
