// pinterest.js

let injected = false;

export function addPinterestScript() {
    if (injected) {
        return Promise.resolve(true);
    }

    window.pAsyncInit = function () {
        window.PDK.init({
            appId: "5020673352658897058", // Change this
            cookie: true
        });
    };

    return new Promise((resolve, reject)=> {
        injected = true;
        if (document.getElementById('addthis')) {
            return resolve(true);
        }
        const pjs = document.getElementsByTagName('script')[0];
        const js = document.createElement('script');
        js.id = 'pinterest-jssdk';
        js.onload = function() {
            // Your code here
            resolve(true);
        };
        js.oerror = function(e) {
            injected = false;
            reject(e);
        };
        js.src = "//assets.pinterest.com/sdk/sdk.js";
        pjs.parentNode.insertBefore(js, pjs);
    });
}
