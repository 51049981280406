import React from 'react';
//import $ from 'jquery';
//import classNames from "classnames";
import {connect} from 'react-redux';
//css and images
import './sliderPicker.scss';
//import ImageComponent from '../Image';
import Button from '../Button';

import {toggleItemVisibility} from '../../../actions';

class SliderPicker extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.inputRef = null;
        React.createRef();
        this.state = {
            textspacingvalue_display: props.textspacingvalue * 100,
            textspacingvalue: props.textspacingvalue,
            lineheightvalue_display: props.lineheightvalue * 100,
            lineheightvalue: props.lineheightvalue
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            textspacingvalue_display: props.textspacingvalue * 100,
            textspacingvalue: props.textspacingvalue,
            lineheightvalue_display: props.lineheightvalue * 100,
            lineheightvalue: props.lineheightvalue,
            property: ''
        })
    }

    handleLetterSpacingTextChange(e) {
        if (e.target.value < 100) {
            this.applyValue('textLetterSpacing', e.target.value / 100);
        } else {
            this.applyValue('textLetterSpacing', 100 / 100);
        }
    }

    handleLetterSpacingChange(e) {
        this.applyValue('textLetterSpacing', e.target.value);
    }

    handleLineHeightTextChange(e) {
        if (e.target.value < 100) {
            this.applyValue('textLineHeightRatio', e.target.value / 100);
        } else {
            this.applyValue('textLineHeightRatio', 100 / 100);
        }
    }

    handleLineHeightChange(e) {
        if (e.target.value < 3) {
            this.applyValue('textLineHeightRatio', e.target.value);
        } else {
            this.applyValue('textLineHeightRatio', 3);
        }
    }

    applyValue(property, value) {
        if (property === "letterSpacing") {
            this.setState({
                textspacingvalue: value,
                property: 'textLetterSpacing',
                textspacingvalue_display: value * 100
            })
        } else {
            this.setState({lineheightvalue: value, property: 'textLineHeightRatio'})
        }

        let changes = {}
        changes[property] = parseFloat(value);
        this.props.applyPropertyChange(changes)
    }

    handleReset(e) {
        if (e.target.id === "letter_spacing_reset_btn") {
            this.applyValue('textLetterSpacing', this.props.textspacingdefault)
        } else {
            this.applyValue('textLineHeightRatio', this.props.lineheightdefault)
        }
    }

    render() {

        return (
            <div className="slider_control_container">
                <Button handleClick={this.props.toggleItemVisibility.bind(this, this.props.name)} id="Spacing"
                        key="Spacing" text="Spacing"/>
                {(this.props.itemsVisibility[this.props.name]) ? (<div className="slider_container">
                    <div key="ToolBar Second Layer" className="square_contianer s_contianer_align">
                        <label className="label_range_container">
                            <div className="spacing_label">Letter spacing</div>
                            <input type="number" name="letter_spacing" id="letter_spacing"
                                   value={this.state.textspacingvalue_display} onChange={(e) => {
                                this.handleLetterSpacingTextChange(e)
                            }} className="spacing_text_val"/>
                            <div className="label_range_width">
                                <input ref={(cd) => this.inputRef = cd} className="range range_transparency"
                                       type="range" min={this.props.textspacingmin} max={this.props.textspacingmax}
                                       step={(this.props.textspacingmax - this.props.textspacingmin) / 100}
                                       value={this.state.textspacingvalue} onChange={(e) => {
                                    this.handleLetterSpacingChange(e)
                                }}/>
                            </div>
                            <a className="reset_btn" id="letter_spacing_reset_btn"
                               onClick={this.handleReset.bind(this)}>Reset</a>
                        </label>
                        <label className="label_range_container">
                            <div className="spacing_label">Line height</div>
                            <input type="number" name="line_height" id="line_height"
                                   value={this.state.lineheightvalue_display} onChange={(e) => {
                                this.handleLineHeightTextChange(e)
                            }} className="spacing_text_val"/>
                            <div className="label_range_width">
                                <input ref={(cd) => this.inputRef = cd} className="range range_transparency"
                                       type="range" min={this.props.lineheightmin} max={this.props.lineheightmax}
                                       step={(this.props.lineheightmax - this.props.lineheightmin) / 100}
                                       value={this.state.lineheightvalue} onChange={(e) => {
                                    this.handleLineHeightChange(e)
                                }}/>
                            </div>
                            <a className="reset_btn" id="line_height_reset_btn"
                               onClick={this.handleReset.bind(this)}>Reset</a>
                        </label>
                    </div>
                </div>) : null}
            </div>
        );
    }


}

const mapStateToProps = state => ({
    itemsVisibility: state.itemsVisibility
});

const mapDispatchToProps = dispatch => ({
    toggleItemVisibility: (item) => dispatch(toggleItemVisibility(item))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SliderPicker);
