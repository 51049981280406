import acuminPro_bold_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/AcuminPro-Bold.svg';
import acuminPro_regular_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/AcuminPro.svg';
import arial_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Arial-01.svg';
import arial_black_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Arial black-01.svg';
import apple_chancery_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Apple Chancery-01.svg";
import apple_script_demo_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Apples Script Demo-01.svg";
import apple_sdgothicneosb00_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Apple SDGothicNeoSB00-01.svg";
import apple_sdgothicneor00_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Apple SDGothicNeoR00-01.svg";
import avenir_book_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Avenir-Book-01.svg";
import avenir_medium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Avenir-Medium-01.svg";
import avenirnext_bold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/AvenirNext-Bold-01.svg";
import adobesongstd_light_alphabetic_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/AdobeSongStd-Light-Alphabetic-01.svg";
//import adobefanheitistd_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/AdobeFanHeitiStd-Bold-Alphabetic-01.svg';
import alienLeague_bold_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Alien League Bold-01.svg';
import alternategotno2d_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/AlternateGotNo2D-01.svg';
import aptifer_slab_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Aptifer Slab-01.svg';
import alienleague_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Alien League II 3D-01.svg';
import aileron_regular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Aileron-Regular-01.svg";
import arcon_regular_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Arcon-01.svg';
import arweibei_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/ARWeiBei-01.svg";
import avante_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Avante-01.svg";
import ayar_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Ayar-01.svg";
import ayuthaya_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Ayuthaya-01.svg";
import bakery_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Bakery-01.svg";
import baskerville_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Baskerville.svg";
import baskervald_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Baskervald-01.svg";
import baskervald_italic_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Baskervald Italic-01.svg";
import bebasNeue_regular_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Bebas-Neue.svg';
import bodoniFLF_roman_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Bodoni-FLF.svg';
import birchstd_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/BirchStd-01.svg';
import bryantmedium_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Bryant Medium-01.svg';
import calibri_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Calibri-01.svg';
import cheveuxdange_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Chevevxdange-01.svg";
import colors_of_autumn_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Colors Of Autumn-01.svg";
import comic_sans_ms_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Comic Sans MS-01.svg";
import consolas_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Consolas-01.svg";
import courier_new_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Courier New-01.svg";
import credc_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/CREDC-01.svg";
import dan_hand_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Dan_Hand-01.svg";
import dead_island_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Dead Island-01.svg";
import dFHaiBao_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/DFHaiBao.svg';
import dFPHannotateW5_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/DFPHannotateW5-B5-TW.svg';
import dFLiKingHei_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/DFLiKingHei.svg';
import dimitri_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Dimitri-01.svg';
import dinbek_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/DINbek-01.svg';
import din_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Din-01.svg';
import dolce_vita_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/DOLCE VITA-01.svg";
import elo_hand_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Elo Hand-01.svg";
import fighting_spirit_2_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Fighting Spirit-2-01.svg";
import folk_solid_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FolkSolid-01.svg";
import freesans_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FreeSan-01.svg";
import futurabt_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FuturaBT-01.svg";
import futuralt_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Futura LT Light-01.svg';
import geared_slab_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Geared Slab-01.svg";
import georgia_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Georgia-01.svg";
import GillSans_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/GillSans.svg";
import glacialIndifference_regular_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Glacial-Indifference.svg';
import gochiHand_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Gochittand-01.svg';
import grandHotel_regular_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Grand-Hotel.svg';
import gretoon_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Gretoon-01.svg';
//import hanziPen_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/HanziPan-01.svg';
import helvetica_bold_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Helvetica-Bold-01.svg';
import helvetica_light_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Helvetica-Light-01.svg';
import helveticaneue_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/HelveticaNeue-01.svg";
import HelveticaNeueMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/HelveticaNeue-Medium.svg";
import himalaya_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Himalaya-01.svg";
//import hirakakustdn_alphanum_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/HiraKaKuStdN AlphaNum-01.svg";
//import hiraminpron_alphanum_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/HiraMinProN AlphaNum-01.svg";
import impact_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Impact-01.svg";
// import jamilah_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Jamilah.svg';
import jandaromantic_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Janda Romantic-01.svg";
import jeepers_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Jeepers-01.svg";
import jomolhari_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Jomolhari-01.svg";
import knewave_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Knewave.svg';
import kreon_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Kreon-01.svg';
import krungthep_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Krungthep-01.svg';
import labelleaurore_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/LaBelleAurore-01.svg';
import leagueGothic_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/League-Gothic.svg';
import lomowebpixel_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Lomowebpixel-01.svg";
import lucida_sans_unicode_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Lucida Sans Unicode-01.svg";
import marcsc_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Marcsc-01.svg";
import marvel_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Marvel-01.svg";
import markerfelt_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Marker Felt-01.svg";
import markerfeltThin_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MarkerFelt-Thin.svg";
import markerfeltWide_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MarkerFelt-Wide.svg";

import metropolis_regular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Metropolis Regular-01.svg";
import metropolis_semiBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Metropolis SemiBold-01.svg";
//import mishafi_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Mishafi-01.svg";
import Minion3Regular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Minion3-Regular.svg";
import Minion3Semibold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Minion3-Semibold.svg";
import MyriadProLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MyriadPro-Light.svg";
import myriadPro_boldcond_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/MyriadPro-BoldCond-01.svg';
// import mHeiHK_bold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Bold-01.svg";
// import mHeiHK_light_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Light-01.svg";
// import mHeiHK_medium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Medium-01.svg";
// import mHeiHK_xbold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Xbold-01.svg";
// import mLingWaiFHK_light_roman_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MLingWaiFHK Light.svg";
import neuzeit_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Neuzeit-01.svg';
import neuzeit_heavy_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Neuzeit Heavy-01.svg';
import nickainley_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Nickainley-01.svg';
import opensans_regular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/OpenSans Regular-01.svg";
import opensans_semibold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/OpenSans SemiBold-01.svg";
import palatino_linotype_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Palatino Linotype-01.svg";
import pingfanghk_light_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/PingFangHK Light-01.svg";
import pingfanghk_medium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/PingFangHK Medium-01.svg";
import pingfanghk_regular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/PingFangHK Regular-01.svg";
import pingfanghk_semibold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/PingFangHK Semibold-01.svg";
import playfairDisplay_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Playfair-Display.svg';
import playlist_script_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Playlist-Script.svg';
import romanSerif_oblique_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/RomanSerif-Oblique-01.svg';
import romanSerif_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/RomanSerif-01.svg';
import saf_regular_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/SAF-Regular-01.svg';
import sensei_medium_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Sensei.svg';
import sixCaps_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Six-Caps.svg';
// import sTHeitiTC_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/STHeitiTC-Light-01-01.svg';
import times_new_roman_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Times New Roman-01.svg";
import trade_gothic_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Trade Gothic-01.svg';
import trade_gothic_l_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Trade Gothic L-01.svg';
import trade_gothic_b_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Trade Gothic B-01.svg';
import trebuchet_ms_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Trebuchet MS-01.svg";
import ubuntu_bold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Ubuntu Bold-01.svg";
import ubuntu_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Ubuntu-01.svg";
import ubuntu_italic_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Ubuntu Italic-01.svg";
import verdana_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Verdana-01.svg";
import vastShadow_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/Vast-Shadow.svg';
import yumin_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/yumin-01.svg";
import zcool_addict_italic_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/ZCOOL Addict Italic-01.svg";
// import heiti_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Heiti-01.svg";
import fangsong_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FangSong-01.svg";
import dfyuanbold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/DFYuanBold-01.svg";
import source_han_sans from "../components/ElementControlPanel/FontPicker/fontStyleImg/Source Han Sans-01.svg";

import finaldemo_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/80erfinaldemo-01.svg";
import appleLiGothicmedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Apple LiGothic Medium.svg";
import AmaticBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Amatic Bold.svg";
import AmaticSC_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Amatic SC-01.svg";
import Aurella_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Aurella-01.svg";
import chalala_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/chalala-01.svg";
import CheriLiney_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Cheri Liney-01.svg";
import CHERI_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/CHERI-01.svg";
//import fzcuhuoyi_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FZCuHuoYi.svg";
import FirstCrush_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/First Crush-01.svg";
import peacechild_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/peacechild-beta-01.svg";
import PORKYS_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/PORKYS_-01.svg";
//import mqinghuahk_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MQingHuaHK.svg";
import sportsjersey_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/sportsjersey-01.svg";
import TheHistoriaDemo_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/TheHistoriaDemo-01.svg";

import HiraginoSansGB_W3_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/HiraginoSansGBW3.svg';
import HiraginoSansGB_W6_icon from '../components/ElementControlPanel/FontPicker/fontStyleImg/HiraginoSansGBW6.svg';
import WindsorBOLD_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/WindsorBOLD.svg";
import WindsorLtBTLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/WindsorLtBTLight.svg";
import MyriadProRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MyriadPro-Regular.svg";
import MyriadProSemibold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MyriadPro-Semibold.svg";
import MyriadProBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MyriadPro-Bold.svg";

import ArialRoundedMTLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Arial-Rounded-MT-Light.svg";
import ArialRoundedMT_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Arial_Rounded_MT.svg";
import ArialRoundedMTBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Arial_Rounded_MT_Bold.svg";
import ArialRoundedMTExtraBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Arial_Rounded_MT_ExtraBold.svg";
import AthelasRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Athelas-Regular.svg";
import AthelasBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Athelas-Bold.svg";
import KozGoPr6NRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/KozGoPr6N-Regular.svg";
import thonburi_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/thonburi.svg";
import ThonburiBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Thonburi_Bold.svg";
import CopperplateGothicLightRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Copperplate Gothic Light Regular.svg";

import ArnoProRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/ArnoPro-Regular.svg";
import AvantGardeBook_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/AvantGardeBook.svg";
import AvantGardeMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/AvantGardeMedium.svg";
import CenturyGothicRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/CenturyGothicRegular.svg";
import CenturyGothicBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/CenturyGothicBold.svg";
import Helvetica_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Helvetica.svg";
import ITCAvantGardeStdBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/ITCAvantGardeStd-Bold.svg";

import CHei2BoldHKSCS_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/CHei2-Bold-HKSCS.svg";
import CHei2XboldHKSCS_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/CHei2-Xbold-HKSCS.svg";
import DFMingBdHKBF_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/DFMing-Bd-HK-BF.svg";

import MHeiHKLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Light.svg";
import MHeiHKMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Medium.svg";
import MHeiHKBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Bold.svg";
import MHeiHKXbold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiHK-Xbold.svg";

import MHeiLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHei-Light.svg";
import MHeiMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHei-Medium.svg";

import MHeiSungHKUltraBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MHeiSungHK-UltraBold.svg";
import MSungHKLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MSungHK-Light.svg";
import MSungHKMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MSungHK-Medium.svg";

//import LogoMaruStdLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/LogoMaruStd-Light.svg";
//import LogoMaruStdMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/LogoMaruStd-Medium.svg";
//import LogoMaruStdBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/LogoMaruStd-Bold.svg";
import LogoMaruStdExtra_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/LogoMaruStd-Extra.svg";
import LogoMaruStdUltra_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/LogoMaruStd-Ultra.svg";

import HanWangMingMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/HanWangMingMedium.svg";
import HanWangMingBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/HanWangMingBold.svg";

import SourceHanSansCNRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSansCN-Regular.svg";
import SourceHanSansCNMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSansCN-Medium.svg";
import SourceHanSansCNBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSansCN-Bold.svg";

import FuturaStdMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FuturaStd-Medium.svg";
import FuturaStdBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FuturaStd-Bold.svg";
import FontsFreeNetFuturaStdHeavy_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FontsFree-Net-FuturaStdHeavy.svg";
import GillSansLight08_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/GillSans-Light-08.svg";

import SourceHanSerifCNRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSerifCN-Regular.svg";
import SourceHanSerifCNLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSerifCN-Light.svg";
import SourceHanSerifCNMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSerifCN-Medium.svg";
import SourceHanSerifCNBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SourceHanSerifCN-Bold.svg";

import NovareseBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Novarese_Bold.svg";
import NovareseBookItalic_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Novarese_BookItalic.svg";
import MinionProRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/MinionPro-Regular.svg";

import KohinoorBanglaMedium_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/KohinoorBangla-Medium.svg";
import KohinoorBanglaSemibold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/KohinoorBangla-Semibold.svg";
import KohinoorBanglaBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/KohinoorBangla-Bold.svg";

import PingFangSCLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/PingFang-SC-Light.svg";
import NotoSansCJKtcLight_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/NotoSansCJKtc-Light.svg";

import TwCenMT_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Tw-Cen-MT.svg";
import FZLTXHBB510_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/FZLTXHB--B51-0.svg";

import ITCAvantGardeStdBk_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/ITCAvantGardeStd-Bk.svg";
import ITCAvantGardeStdDemi_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/ITCAvantGardeStd-Demi.svg";
import NotoSansCJKjpBold_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/NotoSansCJKjp-Bold.svg";
import NotoSansCJKjpRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/NotoSansCJKjp-Regular.svg";
import SoukouMincho_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SoukouMincho.svg";
import NewCaledoniaLTStdRg_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/NewCaledoniaLTStdRg.svg";

import PhilosopherRegular_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/Philosopher-Regular.svg";
import AvenirNext_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/AvenirNext.svg";
import SimHei_icon from "../components/ElementControlPanel/FontPicker/fontStyleImg/SimHei.svg";

// import meta from './meta.json'
// module.exports =
export default
    {
      "engFonts": [
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AcuminPro Bold', label: 'Acumin Pro-Bold' , img: acuminPro_bold_icon, url: 'fonts/AcuminPro-Bold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AcuminPro Regular', label: 'Acumin Pro' , img: acuminPro_regular_icon, url: 'fonts/AcuminPro-Regular.woff'},
            // {fallbackUnicodeFont: 'DFHaiBao', value: 'AdobeSongStd Light Alphabetic', label: 'AdobeSongStd Light Alphabetic' , img: adobesongstd_light_alphabetic_icon, url: 'fonts/AdobeSongStd-Light-Alphabetic.woff'}, // we only can use otf, all trial converted ttf fail
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Aileron Regular', label: 'Aileron Regular' , img: aileron_regular_icon, url: 'fonts/Aileron-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AlienLeague', label: 'AlienLeague' , img: alienleague_icon, url: 'fonts/alienleagueii3d.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AlienLeague Bold', label: 'AlienLeague Bold' , img: alienLeague_bold_icon, url: 'fonts/alienleaguebold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AlternateGotNo2D', label: 'AlternateGotNo2D' , img: alternategotno2d_icon, url: 'fonts/AlternateGotNo2D.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AmaticSC-Regular', label: 'AmaticSC-Regular' , img: AmaticSC_icon, url: 'newfonts/AmaticSC-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Amatic-Bold', label: 'Amatic-Bold' , img: AmaticBold_icon, url: 'newfonts/Amatic-Bold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Apple Chancery', label: 'Apple Chancery' , img: apple_chancery_icon, url: 'fonts/Apple-Chancery.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Apples Script Demo', label: 'Apples Script Demo' , img: apple_script_demo_icon, url: 'fonts/ApplesScriptDemo.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Apple SDGothicNeoSB00', label: 'Apple SDGothicNeoSB00' , img: apple_sdgothicneosb00_icon, url: 'fonts/AppleSDGothicNeoSB00.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Apple SDGothicNeoR00', label: 'Apple SDGothicNeoR00' , img: apple_sdgothicneor00_icon, url: 'fonts/AppleSDGothicNeoR00.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Aptifer Slab', label: 'Aptifer Slab' , img: aptifer_slab_icon, url: 'fonts/AptiferSlabLTPro-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arcon Regular', label: 'Arcon Regular' , img: arcon_regular_icon, url: 'fonts/Arcon-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'ArnoPro-Regular', label: 'ArnoPro-Regular' , img: ArnoProRegular_icon, url: 'fonts/ArnoPro-Regular.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arial', label: 'Arial' , img: arial_icon, url: 'fonts/Arial.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arial Black', label: 'Arial Black' , img: arial_black_icon, url: 'newfonts/Arial Black.ttf?v=1'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arial-Rounded-MT-Light', label: 'Arial-Rounded-MT-Light' , img: ArialRoundedMTLight_icon, url: 'fonts/Arial-Rounded-MT-Light.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arial-Rounded-MT', label: 'Arial-Rounded-MT' , img: ArialRoundedMT_icon, url: 'fonts/Arial_Rounded_MT.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arial-Rounded-MT-Bold', label: 'Arial-Rounded-MT-Bold' , img: ArialRoundedMTBold_icon, url: 'fonts/Arial_Rounded_MT_Bold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Arial_Rounded_MT_ExtraBold', label: 'Arial_Rounded_MT_ExtraBold' , img: ArialRoundedMTExtraBold_icon, url: 'fonts/Arial_Rounded_MT_ExtraBold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Athelas-Regular', label: 'Athelas-Regular' , img: AthelasRegular_icon, url: 'fonts/Athelas-Regular.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AvantGardeBook', label: 'AvantGardeBook' , img: AvantGardeBook_icon, url: 'fonts/AvantGardeBook.otf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Athelas-Bold', label: 'Athelas-Bold' , img: AthelasBold_icon, url: 'fonts/Athelas-Bold.ttf'},
            // AvantGardeMedium ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AvantGardeMedium', label: 'AvantGardeMedium' , img: AvantGardeMedium_icon, url: 'fonts/AvantGardeMedium.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Avenir Book', label: 'Avenir Book' , img: avenir_book_icon, url: 'fonts/Avenir-Book.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Avenir Medium', label: 'Avenir Medium' , img: avenir_medium_icon, url: 'fonts/Avenir-Medium.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AvenirNext', label: 'AvenirNext' , img: AvenirNext_icon, url: 'fonts/AvenirNext.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'AvenirNext Bold', label: 'AvenirNext Bold' , img: avenirnext_bold_icon, url: 'fonts/AvenirNext-Bold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Aurella', label: 'Aurella' , img: Aurella_icon, url: 'fonts/Aurella.woff'},
            // Avante ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Avante', label: 'Avante' , img: avante_icon, url: 'fonts/avante.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Ayar', label: 'Ayar' , img: ayar_icon, url: 'fonts/ayar.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Ayuthaya', label: 'Ayuthaya' , img: ayuthaya_icon, url: 'fonts/Ayuthaya.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Bakery', label: 'Bakery' , img: bakery_icon, url: 'fonts/bakery.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Baskerville', label: 'Baskerville' , img: baskerville_icon, url: 'fonts/Baskerville-01.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Baskervald Italic', label: 'Baskervald Italic' , img: baskervald_italic_icon, url: 'fonts/BaskervaldADFStd-Italic.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Baskervald', label: 'Baskervald' , img: baskervald_icon, url: 'fonts/BaskervaldADFStd.woff?v=1'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'BebasNeue Regular', label: 'BebasNeue Regular' , img: bebasNeue_regular_icon, url: 'newfonts/BebasNeue-Regular.woff'},
            // BodoniFLF ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'BodoniFLF Roman', label: 'BodoniFLF Roman' , img: bodoniFLF_roman_icon, url: 'fonts/BodoniFLF-Roman.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'BirchStd', label: 'BirchStd' , img: birchstd_icon, url: 'fonts/BirchStd.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'BryantMedium', label: 'BryantMedium' , img: bryantmedium_icon, url: 'fonts/BryantMediumAltRegular.woff'},
            // Calibri ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Calibri', label: 'Calibri' , img: calibri_icon, url: 'fonts/Calibri.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'CenturyGothicRegular', label: 'CenturyGothicRegular' , img: CenturyGothicRegular_icon, url: 'fonts/CenturyGothicRegular.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'CenturyGothicBold', label: 'CenturyGothicBold' , img: CenturyGothicBold_icon, url: 'fonts/CenturyGothicBold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Cheveuxdange', label: 'Cheveuxdange' , img: cheveuxdange_icon, url: 'fonts/Cheveuxdange.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'chalala', label: 'chalala' , img: chalala_icon, url: 'fonts/chalala.woff'},
            // Cheri-Liney ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Cheri-Liney', label: 'Cheri-Liney' , img: CheriLiney_icon, url: 'fonts/CHERL_line.ttf'},
            // CHERI ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'CHERI', label: 'CHERI' , img: CHERI_icon, url: 'fonts/CHERI.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Colors Of Autumn', label: 'Colors Of Autumn' , img: colors_of_autumn_icon, url: 'fonts/Colors Of Autumn.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Comic Sans MS', label: 'Comic Sans MS' , img: comic_sans_ms_icon, url: 'fonts/COMIC.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Consolas', label: 'Consolas' , img: consolas_icon, url: 'fonts/Consolas.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Copperplate Gothic Light Regular', label: 'Copperplate Gothic Light Regular' , img: CopperplateGothicLightRegular_icon, url: 'fonts/Copperplate Gothic Light Regular.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Courier New', label: 'Courier New' , img: courier_new_icon, url: 'newfonts/Courier New.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'CREDC', label: 'CREDC' , img: credc_icon, url: 'fonts/CREDC.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Dan Hand', label: 'Dan Hand' , img: dan_hand_icon, url: 'fonts/Dan_Hand.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Dead Island', label: 'Dead Island' , img: dead_island_icon, url: 'fonts/Dead Island.woff'},
            // Dimitri ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Dimitri', label: 'Dimitri' , img: dimitri_icon, url: 'fonts/DIMITRI_.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'DINbek', label: 'DINbek' , img: dinbek_icon, url: 'fonts/DINbek-Medium.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Din', label: 'Din' , img: din_icon, url: 'fonts/ufonts.com_din-light.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Dolce Vita', label: 'Dolce Vita' , img: dolce_vita_icon, url: 'fonts/Dolce Vita.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Elo Hand', label: 'Elo Hand' , img: elo_hand_icon, url: 'fonts/Elo Hand.woff'},
            // Fighting Spirit 2 ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Fighting Spirit 2', label: 'Fighting Spirit 2' , img: fighting_spirit_2_icon, url: 'fonts/Fighting Spirit 2.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'finaldemo', label: 'finaldemo' , img: finaldemo_icon, url: 'fonts/finaldemo.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'First-Crush', label: 'First-Crush' , img: FirstCrush_icon, url: 'newfonts/First Crush.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Folk Solid', label: 'Folk Solid' , img: folk_solid_icon, url: 'fonts/Folk solid.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'FreeSans', label: 'FreeSans' , img: freesans_icon, url: 'fonts/FreeSans.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'FuturaBT', label: 'FuturaBT' , img: futurabt_icon, url: 'fonts/FuturaBT.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'FuturaLT', label: 'FuturaLT' , img: futuralt_icon, url: 'newfonts/Futura LT Light.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'FuturaStd-Medium', label: 'FuturaStd-Medium' , img: FuturaStdMedium_icon, url: 'fonts/FuturaStd-Medium.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'FuturaStd-Bold', label: 'FuturaStd-Bold' , img: FuturaStdBold_icon, url: 'fonts/FuturaStd-Bold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'FontsFree-Net-FuturaStdHeavy', label: 'FontsFree-Net-FuturaStdHeavy' , img: FontsFreeNetFuturaStdHeavy_icon, url: 'fonts/FontsFree-Net-FuturaStdHeavy.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Geared Slab', label: 'Geared Slab' , img: geared_slab_icon, url: 'fonts/GearedSlab.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Georgia', label: 'Georgia' , img: georgia_icon, url: 'newfonts/Georgia.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'GillSans-Light-08', label: 'GillSans-Light-08' , img: GillSansLight08_icon, url: 'fonts/GillSans-Light-08.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'GillSans', label: 'GillSans' , img: GillSans_icon, url: 'fonts/GillSans.otf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'GlacialIndifference Regular', label: 'GlacialIndifference Regular' , img: glacialIndifference_regular_icon, url: 'fonts/GlacialIndifference-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'GochiHand', label: 'GochiHand' , img: gochiHand_icon, url: 'fonts/GochiHand-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'GrandHotel Regular', label: 'GrandHotel Regular' , img: grandHotel_regular_icon, url: 'fonts/GrandHotel-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Gretoon', label: 'Gretoon' , img: gretoon_icon, url: 'fonts/Gretoon.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Helvetica Light', label: 'Helvetica Light' , img: helvetica_light_icon, url: 'newfonts/Helvetica-01.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Helvetica', label: 'Helvetica' , img: Helvetica_icon, url: 'fonts/helvetica.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Helvetica Bold', label: 'Helvetica Bold' , img: helvetica_bold_icon, url: 'newfonts/Helvetica-Bold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'HelveticaNeue', label: 'HelveticaNeue' , img: helveticaneue_icon, url: 'fonts/HelveticaNeue-Thin.woff'},
            // HelveticaNeue-Medium ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'HelveticaNeue-Medium', label: 'HelveticaNeue-Medium' , img: HelveticaNeueMedium_icon, url: 'fonts/HelveticaNeue-Medium.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Himalaya', label: 'Himalaya' , img: himalaya_icon, url: 'fonts/himalaya.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Impact', label: 'Impact' , img: impact_icon, url: 'newfonts/Impact.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'ITCAvantGardeStd-Bk', label: 'ITCAvantGardeStd-Bk' , img: ITCAvantGardeStdBk_icon, url: 'fonts/ITCAvantGardeStd-Bk.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'ITCAvantGardeStd-Bold', label: 'ITCAvantGardeStd-Bold' , img: ITCAvantGardeStdBold_icon, url: 'fonts/ITCAvantGardeStd-Bold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'ITCAvantGardeStd-Demi', label: 'ITCAvantGardeStd-Demi' , img: ITCAvantGardeStdDemi_icon, url: 'fonts/ITCAvantGardeStd-Demi.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Jamilah', label: 'Jamilah'/* , img: jamilah_icon*/, url: 'fonts/Jamilah.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'JandaRomantic', label: 'JandaRomantic' , img: jandaromantic_icon, url: 'fonts/JandaRomantic.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Jeepers', label: 'Jeepers' , img: jeepers_icon, url: 'fonts/Jeepers.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Jomolhari', label: 'Jomolhari' , img: jomolhari_icon, url: 'fonts/Jomolhari.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Knewave', label: 'Knewave' , img: knewave_icon, url: 'fonts/Knewave-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'KohinoorBangla-Medium', label: 'KohinoorBangla-Medium' , img: KohinoorBanglaMedium_icon, url: 'fonts/KohinoorBangla-Medium.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'KohinoorBangla-Semibold', label: 'KohinoorBangla-Semibold' , img: KohinoorBanglaSemibold_icon, url: 'fonts/KohinoorBangla-Semibold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'KohinoorBangla-Bold', label: 'KohinoorBangla-Bold' , img: KohinoorBanglaBold_icon, url: 'fonts/KohinoorBangla-Bold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Kreon', label: 'Kreon' , img: kreon_icon, url: 'fonts/Kreon.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Krungthep', label: 'Krungthep' , img: krungthep_icon, url: 'fonts/Krungthep.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'LaBelleAurore', label: 'LaBelleAurore' , img: labelleaurore_icon, url: 'fonts/LaBelleAurore.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'LeagueGothic', label: 'LeagueGothic' , img: leagueGothic_icon, url: 'fonts/LeagueGothic-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'lomowebpixel', label: 'lomowebpixel' , img: lomowebpixel_icon, url: 'fonts/ufonts.com_lomowebpixel-lt-std-5.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' , img: lucida_sans_unicode_icon, url: 'fonts/LucidaSansUnicode.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MarkerFelt Wide', label: 'MarkerFelt Wide' , img: markerfeltWide_icon, url: 'fonts/MarkerFelt-Wide.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MarkerFelt Thin', label: 'MarkerFelt Thin' , img: markerfeltThin_icon, url: 'fonts/MarkerFelt-Thin.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MarkerFelt', label: 'MarkerFelt' , img: markerfelt_icon, url: 'fonts/MarkerFelt.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Marcsc', label: 'Marcsc' , img: marcsc_icon, url: 'fonts/Marcsc.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Marvel', label: 'Marvel' , img: marvel_icon, url: 'fonts/Marvel-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Metropolis Regular', label: 'Metropolis Regular' , img: metropolis_regular_icon, url: 'fonts/Metropolis-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Metropolis SemiBold', label: 'Metropolis SemiBold' , img: metropolis_semiBold_icon, url: 'newfonts/Metropolis-SemiBold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Minion3-Regular', label: 'Minion3-Regular' , img: Minion3Regular_icon, url: 'fonts/Minion3-Regular.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Minion3-Semibold', label: 'Minion3-Semibold' , img: Minion3Semibold_icon, url: 'fonts/Minion3-Semibold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MinionPro-Regular', label: 'MinionPro-Regular' , img: MinionProRegular_icon, url: 'fonts/MinionPro-Regular.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MyriadPro-Light', label: 'MyriadPro-Light' , img: MyriadProLight_icon, url: 'fonts/MyriadPro-Light.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MyriadPro-Regular', label: 'MyriadPro-Regular' , img: MyriadProRegular_icon, url: 'fonts/MyriadPro-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MyriadPro-Semibold', label: 'MyriadPro-Semibold' , img: MyriadProSemibold_icon, url: 'fonts/MyriadPro-Semibold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MyriadPro-Bold', label: 'MyriadPro-Bold' , img: MyriadProBold_icon, url: 'fonts/MyriadPro-Bold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'MyriadPro BoldCond', label: 'MyriadPro BoldCond' , img: myriadPro_boldcond_icon, url: 'fonts/MyriadPro-BoldCond.woff'},
            // Novarese_Bold ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Novarese_Bold', label: 'Novarese_Bold' , img: NovareseBold_icon, url: 'fonts/Novarese_Bold.ttf'},
            // Novarese_BookItalic ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Novarese_BookItalic', label: 'Novarese_BookItalic' , img: NovareseBookItalic_icon, url: 'fonts/Novarese_BookItalic.ttf'},

            {fallbackUnicodeFont: 'DFHaiBao', value: 'Neuzeit', label: 'Neuzeit' , img: neuzeit_icon, url: 'fonts/Neuzeit.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Neuzeit Heavy', label: 'Neuzeit Heavy' , img: neuzeit_heavy_icon, url: 'fonts/Neuzeit_Heavy.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'NewCaledoniaLTStdRg', label: 'NewCaledoniaLTStdRg' , img: NewCaledoniaLTStdRg_icon, url: 'fonts/NewCaledoniaLTStdRg.ttf'},

            {fallbackUnicodeFont: 'DFHaiBao', value: 'Nickainley', label: 'Nickainley' , img: nickainley_icon, url: 'fonts/Nickainley.woff'},
            // OpenSans Regular ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'OpenSans Regular', label: 'OpenSans Regular' , img: opensans_regular_icon, url: 'fonts/OpenSans-Regular.ttf'},
            // OpenSans Semibold ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'OpenSans Semibold', label: 'OpenSans Semibold' , img: opensans_semibold_icon, url: 'fonts/OpenSans-Semibold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Palatino Linotype', label: 'Palatino Linotype' , img: palatino_linotype_icon, url: 'newfonts/Palatino Linotype.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'peacechild-beta', label: 'peacechild-beta' , img: peacechild_icon, url: 'newfonts/PEACECHILD-BETA.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'PingFangHK Light', label: 'PingFangHK Light' , img: pingfanghk_light_icon, url: 'fonts/PingFangHK-Light-Proportional-10.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'PingFangHK Medium', label: 'PingFangHK Medium' , img: pingfanghk_medium_icon, url: 'fonts/PingFangHK-Medium-Proportional-04.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'PingFangHK Regular', label: 'PingFangHK Regular' , img: pingfanghk_regular_icon, url: 'fonts/PingFangHK-Regular-Proportional-01.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'PingFangHK Semibold', label: 'PingFangHK Semibold' , img: pingfanghk_semibold_icon, url: 'fonts/PingFangHK-Semibold-Proportional-07.woff'},//woff2
            {fallbackUnicodeFont: 'DFHaiBao', value: 'PlayfairDisplay', label: 'PlayfairDisplay' , img: playfairDisplay_icon, url: 'fonts/PlayfairDisplay-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Playlist Script', label: 'Playlist Script' , img: playlist_script_icon, url: 'fonts/Playlist-Script.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Philosopher-Regular', label: 'Philosopher-Regular' , img: PhilosopherRegular_icon, url: 'fonts/Philosopher-Regular.ttf'},

            // PORKYS ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'PORKYS', label: 'PORKYS' , img: PORKYS_icon, url: 'newfonts/PORKYS_.ttf'},
            // RomanSerif ttf broken
            {fallbackUnicodeFont: 'DFHaiBao', value: 'RomanSerif', label: 'RomanSerif' , img: romanSerif_oblique_icon, url: 'fonts/RomanSerif.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'RomanSerif Oblique', label: 'RomanSerif Oblique' , img: romanSerif_icon, url: 'fonts/RomanSerif-Oblique.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'SAF Regular', label: 'SAF Regular' , img: saf_regular_icon, url: 'fonts/SAF-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Sensei Medium', label: 'Sensei Medium' , img: sensei_medium_icon, url: 'fonts/Sensei-Medium.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'SixCaps', label: 'SixCaps' , img: sixCaps_icon, url: 'fonts/SixCaps.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'sportsjersey', label: 'Sports Jersey' , img: sportsjersey_icon, url: 'newfonts/SportsJersey.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'TheHistoriaDemo', label: 'TheHistoriaDemo' , img: TheHistoriaDemo_icon, url: 'newfonts/TheHistoriaDemo.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'thonburi', label: 'thonburi' , img: thonburi_icon, url: 'fonts/thonburi.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Thonburi_Bold', label: 'Thonburi_Bold' , img: ThonburiBold_icon, url: 'fonts/Thonburi_Bold.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Times New Roman', label: 'Times New Roman' , img: times_new_roman_icon, url: 'fonts/TimesNewRoman.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Trade Gothic', label: 'Trade Gothic' , img: trade_gothic_icon, url: 'fonts/trade-gothic-lt-1361519976.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Trade Gothic L', label: 'Trade Gothic L' , img: trade_gothic_l_icon, url: 'fonts/TradeGothicLT-Light.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Trade Gothic B', label: 'Trade Gothic B' , img: trade_gothic_b_icon, url: 'fonts/TradeGothicLT-Bold.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Trebuchet MS', label: 'Trebuchet MS' , img: trebuchet_ms_icon, url: 'fonts/TrebuchetMS.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Tw-Cen-MT', label: 'Tw-Cen-MT' , img: TwCenMT_icon, url: 'fonts/Tw-Cen-MT.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Ubuntu', label: 'Ubuntu' , img: ubuntu_icon, url: 'fonts/Ubuntu-Light.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Ubuntu Bold', label: 'Ubuntu Bold' , img: ubuntu_bold_icon, url: 'fonts/Ubuntu-B.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Ubuntu Italic', label: 'Ubuntu Italic' , img: ubuntu_italic_icon, url: 'fonts/Ubuntu-LightItalic.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'Verdana', label: 'Verdana' , img: verdana_icon, url: 'newfonts/Verdana.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'VastShadow', label: 'VastShadow' , img: vastShadow_icon, url: 'fonts/VastShadow-Regular.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'WindsorBOLD', label: 'WindsorBOLD' , img: WindsorBOLD_icon, url: 'fonts/Windsor BOLD.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'WindsorLtBTLight', label: 'WindsorLtBTLight' , img: WindsorLtBTLight_icon, url: 'fonts/Windsor Lt BT Light.ttf'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'yumin', label: 'yumin' , img: yumin_icon, url: 'fonts/yumin.woff'},
            {fallbackUnicodeFont: 'DFHaiBao', value: 'ZCOOL Addict Italic', label: 'ZCOOL Addict Italic' , img: zcool_addict_italic_icon, url: 'fonts/ZCOOL Addict Italic 02.woff'},

        ],
        'otherFonts': [
            // {fallbackUnicodeFont: 'DFHaiBao', value: 'fonts_label', label: 'Other fonts', type:'heading'},
            {value: 'AdobeSongStd Light Alphabetic', label: 'AdobeSongStd Light Alphabetic' , img: adobesongstd_light_alphabetic_icon, url: 'fonts/AdobeSongStdL.otf'}, // we only can use otf, all trial converted ttf fail
            {value: 'AppleLiGothicMedium', label: '蘋果儷中黑' , img: appleLiGothicmedium_icon, url: 'fonts/AppleLiGothicMedium.woff'},
            {value: 'ARWeiBei', label: 'ARWeiBei' , img: arweibei_icon, url: 'fonts/ARWeiBei-TC.woff'},//woff2
            {value: 'FZCuHuoYi', label: '方正粗活意繁體' /*, img: fzcuhuoyi_icon*/, url: 'fonts/FZCuHuoYi.woff'},
            {value: 'dFYuanBold', label: '華康粗圓體' , img: dfyuanbold_icon, url: 'fonts/華康粗圓體.woff'},
            {value: 'DFHaiBao', label: '華康海報體' , img: dFHaiBao_icon, url: 'fonts/DFHaiBao.ttf'},
            {value: 'DFPHannotateW5', label: '手札體' , img: dFPHannotateW5_icon, url: 'fonts/DFPHannotateW5-B5-TW.woff'},
            {value: 'DFLiKingHei', label: '華康儷金黑' , img: dFLiKingHei_icon, url: 'fonts/DFLiKingHei.ttf'},

            {value: 'CHei2-Bold-HKSCS', label: 'CHei2-Bold-HKSCS' , img: CHei2BoldHKSCS_icon, url: 'fonts/CHei2-Bold-HKSCS.ttf'},
            {value: 'CHei2-Xbold-HKSCS', label: 'CHei2-Xbold-HKSCS' , img: CHei2XboldHKSCS_icon, url: 'fonts/CHei2-Xbold-HKSCS.ttf'},
            {value: 'DFMing-Bd-HK-BF', label: 'DFMing-Bd-HK-BF' , img: DFMingBdHKBF_icon, url: 'fonts/DFMing-Bd-HK-BF.ttf'},

            {value: 'FZLTXHB--B51-0', label: 'FZLTXHB--B51-0' , img: FZLTXHBB510_icon, url: 'fonts/FZLTXHB--B51-0.ttf'},
            {value: 'HanziPen', label: 'HanziPen'/* , img: hanziPen_icon*/, url: 'fonts/HanziPen.woff'},
            {value: 'HiraginoSansGBW3', label: 'HiraginoSansGBW3' , img: HiraginoSansGB_W3_icon, url: 'fonts/HiraginoSansGB-W3.ttf'},
            {value: 'HiraginoSansGBW6', label: 'HiraginoSansGBW6' , img: HiraginoSansGB_W6_icon, url: 'fonts/HiraginoSansGB-W6.ttf'},

            {value: 'HanWangMingMedium', label: 'HanWangMingMedium' , img: HanWangMingMedium_icon, url: 'fonts/HanWangMingMedium.ttf'},
            {value: 'HanWangMingBold', label: 'HanWangMingBold' , img: HanWangMingBold_icon, url: 'fonts/HanWangMingBold.ttf'},

            {value: 'KozGoPr6N-Regular', label: 'KozGoPr6N-Regular' , img: KozGoPr6NRegular_icon, url: 'fonts/KozGoPr6N-Regular.otf'},

            {value: 'LogoMaruStd-Light', label: 'LogoMaruStd-Light' /*, img: LogoMaruStdLight_icon*/, url: 'fonts/LogoMaruStd-Light.ttf'},
            {value: 'LogoMaruStd-Medium', label: 'LogoMaruStd-Medium' /*, img: LogoMaruStdMedium_icon*/, url: 'fonts/LogoMaruStd-Medium.ttf'},
            {value: 'LogoMaruStd-Bold', label: 'LogoMaruStd-Bold' /*, img: LogoMaruStdBold_icon*/, url: 'fonts/LogoMaruStd-Bold.ttf'},
            {value: 'LogoMaruStd-Extra', label: 'LogoMaruStd-Extra' , img: LogoMaruStdExtra_icon, url: 'fonts/LogoMaruStd-Extra.ttf'},
            {value: 'LogoMaruStd-Ultra', label: 'LogoMaruStd-Ultra' , img: LogoMaruStdUltra_icon, url: 'fonts/LogoMaruStd-Ultra.ttf'},

            {value: 'MHeiHK-Light', label: 'MHeiHK-Light' , img: MHeiHKLight_icon, url: 'fonts/MHeiHK-Light.ttf'},
            {value: 'MHeiHK-Medium', label: 'MHeiHK-Medium' , img: MHeiHKMedium_icon, url: 'fonts/MHeiHK-Medium.ttf'},
            {value: 'MHeiHK-Bold', label: 'MHeiHK-Bold' , img: MHeiHKBold_icon, url: 'fonts/MHeiHK-Bold.ttf'},
            {value: 'MHeiHK-Xbold', label: 'MHeiHK-Xbold' , img: MHeiHKXbold_icon, url: 'fonts/MHeiHK-Xbold.ttf'},

            {value: 'MHei-Light', label: 'MHei-Light' , img: MHeiLight_icon, url: 'fonts/MHei-Light.ttf'},
            {value: 'MHei-Medium', label: 'MHei-Medium' , img: MHeiMedium_icon, url: 'fonts/MHei-Medium.ttf'},
            {value: 'MHeiSungHK-UltraBold', label: 'MHeiSungHK-UltraBold' , img: MHeiSungHKUltraBold_icon, url: 'fonts/MHeiSungHK-UltraBold.ttf'},
            {value: 'MQingHuaHK', label: 'MQingHuaHK'/* , img: mqinghuahk_icon*/, url: 'fonts/MQingHuaHK.woff'},
            {value: 'MSungHK-Light', label: 'MSungHK-Light' , img: MSungHKLight_icon, url: 'fonts/MSungHK-Light.ttf'},
            {value: 'MSungHK-Medium', label: 'MSungHK-Medium' , img: MSungHKMedium_icon, url: 'fonts/MSungHK-Medium.ttf'},

            {value: 'NotoSansCJKjp-Bold', label: 'NotoSansCJKjp-Bold' , img: NotoSansCJKjpBold_icon, url: 'fonts/NotoSansCJKjp-Bold.ttf'},
            {value: 'NotoSansCJKjp-Regular', label: 'NotoSansCJKjp-Regular' , img: NotoSansCJKjpRegular_icon, url: 'fonts/NotoSansCJKjp-Regular.ttf'},
            {value: 'NotoSansCJKtc-Light', label: 'NotoSansCJKtc-Light' , img: NotoSansCJKtcLight_icon, url: 'fonts/NotoSansCJKtc-Light.ttf'},
            {value: 'SimHei', label: 'SimHei' , img: SimHei_icon, url: 'fonts/SimHei.ttf'},
            {value: 'Simsun', label: 'Simsun', url: 'fonts/simsun.ttf'},
            {value: 'SoukouMincho', label: 'SoukouMincho' , img: SoukouMincho_icon, url: 'fonts/SoukouMincho.ttf'},

            {value: 'SourceHanSansCN-Regular', label: 'SourceHanSansCN-Regular' , img: SourceHanSansCNRegular_icon, url: 'fonts/SourceHanSansCN-Regular.ttf'},
            {value: 'SourceHanSansCN-Medium', label: 'SourceHanSansCN-Medium' , img: SourceHanSansCNMedium_icon, url: 'fonts/SourceHanSansCN-Medium.ttf'},
            {value: 'SourceHanSansCN-Bold', label: 'SourceHanSansCN-Bold' , img: SourceHanSansCNBold_icon, url: 'fonts/SourceHanSansCN-Bold.ttf'},

            {value: 'SourceHanSerifCN-Regular', label: 'SourceHanSerifCN-Regular' , img: SourceHanSerifCNRegular_icon, url: 'fonts/SourceHanSerifCN-Regular.ttf'},
            // broken SourceHanSerifCN-Light
            {value: 'SourceHanSerifCN-Light', label: 'SourceHanSerifCN-Light' , img: SourceHanSerifCNLight_icon, url: 'fonts/SourceHanSerifCN-Light.ttf'},
            // broken SourceHanSerifCN-Medium
            {value: 'SourceHanSerifCN-Medium', label: 'SourceHanSerifCN-Medium' , img: SourceHanSerifCNMedium_icon, url: 'fonts/SourceHanSerifCN-Medium.ttf'},
            {value: 'SourceHanSerifCN-Bold', label: 'SourceHanSerifCN-Bold' , img: SourceHanSerifCNBold_icon, url: 'fonts/SourceHanSerifCN-Bold.ttf'},

            {value: 'PingFang-SC-Light', label: 'PingFang-SC-Light' , img: PingFangSCLight_icon, url: 'fonts/PingFang-SC-Light.ttf'},
            {value: 'Source Han', label: 'Source Han' , img: source_han_sans, url: 'https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff'},
            {value: 'Fangsong', label: 'Fangsong' , img: fangsong_icon, url: 'https://fonts.gstatic.com/ea/cwtexfangsong/v3/cwTeXFangSong-zhonly.woff'},
			//{value: 'Philosopher-Regular', label: 'Philosopher-Regular' , img: PhilosopherRegular_icon, url: 'fonts/Philosopher-Regular.ttf'},

            //{fallbackUnicodeFont: 'DFHaiBao', value: 'MHeiHK Bold', label: 'MHeiHK Bold' , img: mHeiHK_bold_icon, url: 'newfonts/MHeiHK-Xbold-Roman.ttf'},
            //{fallbackUnicodeFont: 'DFHaiBao', value: 'MHeiHK Light', label: 'MHeiHK Light' , img: mHeiHK_light_icon, url: 'fonts/MHeiHK-Light.ttf'},
            //{fallbackUnicodeFont: 'DFHaiBao', value: 'MHeiHK Medium', label: 'MHeiHK Medium' , img: mHeiHK_medium_icon, url: 'fonts/MHeiHK-Medium.ttf'},
            //{fallbackUnicodeFont: 'DFHaiBao', value: 'MHeiHK Xbold', label: 'MHeiHK Xbold' , img: mHeiHK_xbold_icon, url: 'fonts/MHeiHK-Xbold.ttf'},
            //{fallbackUnicodeFont: 'DFHaiBao', value: 'MLingWaiFHK Light Roman', label: 'MLingWaiFHK Light Roman' , img: mLingWaiFHK_light_roman_icon, url: 'fonts/MLingWaiFHK-Light-Roman.ttf'},
            //{fallbackUnicodeFont: 'DFHaiBao', value: 'MQingHuaHK', label: 'MQingHuaHK' , img: mqinghuahk_icon, url: 'fonts/MQingHuaHK.ttf'},
            //{fallbackUnicodeFont: 'DFHaiBao', value: 'STHeitiTC', label: 'STHeitiTC' , img: sTHeitiTC_icon, url: 'fonts/STHeitiTC-Light-01.ttf'}
            // {fallbackUnicodeFont: 'DFHaiBao', value: 'Heiti', label: 'Heiti' , img: heiti_icon, url: 'https://fonts.gstatic.com/ea/cwtexhei/v3/cwTeXHei-zhonly.woff'}
        ]
    }
