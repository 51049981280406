import React from 'react';
//import Cookies from 'universal-cookie';
//import { connect } from 'react-redux';
//import classNames from "classnames";

class ProgressBarFilterComponent extends React.PureComponent {

    render() {
        return (
            <div className="progress_bar_filter" style={{width: `${this.props.percentage}%`}}></div>
        );
    }
}

export default ProgressBarFilterComponent;
