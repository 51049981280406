import React from 'react';
import classNames from "classnames";

//css
import './image.css';

export default class Image extends React.PureComponent {
    render() {
        const style = this.props.is_active ? "active" : "";
        const imgStyle = this.props.size ? {width: this.props.size, height: this.props.size} : {};
        return (
            <button className={classNames("btn", style)} onClick={this.props.handleClick} onMouseDown={this.props.handleMouseDown}>
                <span className="list_box"><img src={this.props.icon} className="image" style={imgStyle} /></span>
            </button>
        )
    }
}
