import React from 'react';
import classNames from "classnames";
import { connect } from 'react-redux';
//import $ from 'jquery';

//import Popup from '../ElementControlPanel/Popup'

//css and images
import './zoomControl.scss';
import './Zoom.svg';
import check_icon from '../ElementControlPanel/PickerPanel/check-mark.png'

import {
  setZoomScale, toggleItemVisibility,
  setTextEditMode,
  setZoomScaleByContainerSize
} from '../../actions';

class ZoomControl extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
          currentOption: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.toggleZoomList = this.toggleZoomList.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    componentDidMount () {
      this.zoomToFit('fit')
      this.setState({
        currentOption: 'fit'
      })
    }

    toggleZoomList(e) {
      e.preventDefault();
      this.props.toggleItemVisibility('zoomDropDown');
    }

    setZoomScale(zoomScale) {
      this.setState({currentOption: null});
      this.props.setZoomScale(zoomScale);
    }

    zoomToFit(mode) {
      if (this.props.print_only) {
        this.props.setZoomScaleByContainerSize(this.props.getZoomContainerSize().width, null, mode);
      } else {
        this.setState({currentOption: mode});
        this.props.setZoomScaleByContainerSize(this.props.getZoomContainerSize().width, this.props.getZoomContainerSize().height, mode);
      }

    }

    onMouseUp(event) {
        // this.props.setTextEditMode();
        event.stopPropagation();
    }

    handleChange(event) {
        const zoomScale = event.target.value/100;
        this.setState({currentOption: null});
        this.props.setZoomScale(zoomScale);
    }

    render() {
        return (
            <div>
                <div className={classNames("zoom_main_container", {hide: this.props.print_only})}>
                    <div className="zoom_label zoom_label_style">
                        <div>
                            <button className="zoom_btn btn_boader btn_outline" onClick={this.toggleZoomList}>
                                <div className="enlarge_designtext">
                                    <input className="zoom_text" type="number" max="100" min="-100" step="1" value={(this.props.zoomScale * 100).toFixed(0)} onMouseUp={this.onMouseUp}  onChange={this.handleChange}/>
                                    <span className="zoom_text_per">%</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className={classNames("zoomList", {'hide': !this.props.showZoomlist})}>
                        <div className="square_contianer s_contianer">
                            <div id="picker-panel" className="picker-panel">
                                <ul className="picker_list">
                                    <li  className="list_style" onMouseUp={() => this.setZoomScale(0.5)}><button className="list_btn">
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">50%</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption || this.props.zoomScale !== 0.5 ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.setZoomScale(1)}><button className="list_btn">
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">100%</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption || this.props.zoomScale !== 1 ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.setZoomScale(1.5)}><button className="list_btn" onClick={() => this.setZoomScale(1.5)}>
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">150%</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption || this.props.zoomScale !== 1.5 ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.setZoomScale(2)}><button className="list_btn" onClick={() => this.setZoomScale(2)}>
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">200%</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption || this.props.zoomScale !== 2 ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.setZoomScale(3)}><button className="list_btn" onClick={() => this.setZoomScale(3)}>
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">300%</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption || this.props.zoomScale !== 3 ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.setZoomScale(5)}><button className="list_btn" onClick={() => this.setZoomScale(5)}>
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">500%</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption || this.props.zoomScale !== 5 ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.zoomToFit('fit')}><button className="list_btn" onClick={() => this.zoomToFit('fit')}>
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">Fit</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption !== 'fit' ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                    <li  className="list_style" onMouseUp={() => this.zoomToFit('fill')}><button className="list_btn" onClick={() => this.zoomToFit('fill')}>
                                        <div className="list_box">
                                            <div className="label_container"> <span className="list_title">Fill</span></div>
                                            <span><img src={check_icon} className={classNames("zoom_check_icon", {'hide': this.state.currentOption !== 'fill' ? true : false})} /></span>
                                        </div>
                                    </button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
  zoomScale: state.zoomScale,
  pages: state.pages,
  showZoomlist: state.itemsVisibility['zoomDropDown']
});

const mapDispatchToProps = dispatch => ({
  setZoomScale: (scale) => dispatch(setZoomScale(scale)),
  toggleItemVisibility: (itemCode) => dispatch(toggleItemVisibility(itemCode)),
  setTextEditMode: () => dispatch(setTextEditMode()),
  setZoomScaleByContainerSize: (width, height, mode) => dispatch(setZoomScaleByContainerSize(width, height, mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoomControl);
