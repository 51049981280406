import React from 'react';
//import $ from 'jquery';
import classNames from "classnames";
import {connect} from 'react-redux';
import {getDesignElement} from '../../../actions';
import ContentLoader from 'react-content-loader'

import MenuElement from '../MenuElement';

import '../sideControlPanel.scss';

import Masonry from 'react-masonry-component';

class LayoutElement extends React.PureComponent {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = {
            menu_elements: [],
            offset: 0,
            limit: 20,
            stopMore: false,
            loading: false,
            solid_bg_panel_show: false,
            _myFavouriteFilter: this.props.myFavouriteFilter,
        };
        this.getDesignElement = props.getDesignElement.bind(this);
        this.refreshDesignElement = this.refreshDesignElement.bind(this);
        this.scrollEnd = this.scrollEnd.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    refreshDesignElement(append) {

        var self = this;

        var offset = this.state.offset;
        if (!append && this._isMounted) {
            this.setState({
                offset: 0,
                loading: true,
                stopMore: false,
                menu_elements: [],
            });
            offset = 0;
        }

        this.getDesignElement("layout", 0, 0, "", offset, this.state.limit, {
            width: this.props.width,
            height: this.props.height,
        }, {}, function (json) {
            if (self._isMounted) {
                if (json.elements.length > 0) {
                    var menu_elements = [];
                    if (append) {
                        menu_elements = self.state.menu_elements;
                    }
                    for (var i = 0; i < json.elements.length; i++) {
                        menu_elements.push({
                            preview_url: json.elements[i].preview_url,
                            previews: json.elements[i].previews,
                            json: json.elements[i].json,
                            name_code: json.elements[i].name_code,
                            star: (json.elements[i].star === undefined ? false : json.elements[i].star),
                            element_id: json.elements[i].id,
                        });
                    }
                    self.setState({
                        menu_elements: menu_elements,
                        loading: false,
                    }, function () {

                        //var element = this.refs.layout_div;
                        var element = document.getElementsByClassName("menu_container")[0];
                        if (element.scrollHeight <= element.offsetHeight && !this.state.stopMore) {
                            self.loadMore();
                        }

                    });
                } else {
                    self.setState({
                        stopMore: true,
                        loading: false,
                    });
                }
            }

        }, this.props.hash);

    }

    loadMore() {
        if (!this._isMounted) return;
        this.setState({
            offset: this.state.offset + this.state.limit
        }, () => this.refreshDesignElement(true));
    }

    scrollEnd() {
        var element = document.getElementsByClassName("menu_container")[0];
        if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
            if (!this.state.stopMore) {
                this.loadMore();
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        var self = this;
        this.refreshDesignElement(false);
        /*
        this.masonry.on('layoutComplete', function(){
            console.log("Completed");
        });
        */
        var menu_container = document.getElementsByClassName("menu_container")[0];
        menu_container.addEventListener('scroll', function () {
            self.scrollEnd();
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
        var menu_container = document.getElementsByClassName("menu_container")[0];
        if (window.getEventListeners) {
            window.getEventListeners(menu_container).scroll.map(event => {
                menu_container.removeEventListener('scroll', event.listener);
                return event;
            })
        }
    }

    componentDidUpdate() {
        var self = this;
        if (this.state._myFavouriteFilter !== this.props.myFavouriteFilter) {
            this.setState({
                _myFavouriteFilter: self.props.myFavouriteFilter
            });
            this.refreshDesignElement(false);
        }
    }

    handleExpand(element) {
        let templates = [];
        element.json.pages.forEach((page, key) => {
            let newTemplate = JSON.parse(JSON.stringify(element.json));
            newTemplate.pages = [];
            newTemplate.pages.push(page);
            templates.push({
                preview_url: (element.previews && element.previews[key]) ? element.previews[key] : element.preview_url,
                name_code: element.name_code,
                json: newTemplate
            });
        });
        this.setState({
            showExpandView: true,
            expandedItems: templates,
        });
    }

    handleExpandBackClicked() {
        this.setState({
            showExpandView: false,
            expandedItems: null,
        });
    }

    render() {

        let renderElements = null;

        if (this.state.showExpandView) {
            renderElements = this.state.expandedItems ? this.state.expandedItems.map((element, index) =>
                (<MenuElement key={'expand' + index} idx={index} preview_url={element.preview_url}
                              name_code={element.name_code} json={element.json} type="layout"
                              onItemSelected={this.props.onItemSelected}/>)
            ) : [];
        } else {
            renderElements = this.state.menu_elements ? this.state.menu_elements.map((element, index) =>
                (<MenuElement key={index} idx={index} preview_url={element.preview_url}
                              name_code={element.name_code} json={element.json} type="layout"
                              handleExpand={this.handleExpand.bind(this, element)}
                              canExpand={!!element.previews}
                              star={element.star} element_id={element.element_id}
                              onItemSelected={this.props.onItemSelected}/>)
            ) : [];
        }

        /*
        renderElements = this.state.menu_elements ? this.state.menu_elements.map((element, index) =>
              (<MenuElement key={index} idx={index} preview_url={element.preview_url}
                  name_code={element.name_code} json={element.json} type="layout"
                  handleExpand={this.handleExpand.bind(this, element)} canExpand={element.previews?true:false}
                  star={element.star} element_id={element.element_id}/>)
            ) : [];
        */

        let loaderElements = [];
        let loaderHeight = 158;
        for (let i = 0; i < 10; i++) {
            loaderHeight += 140;
            loaderElements.push((
                <rect key={"text_loader_1_" + i} x="5" y={i * 140} rx="8" ry="8" width="148" height="130"/>
            ));
            loaderElements.push((
                <rect key={"text_loader_2_" + i} x="163" y={i * 140} rx="8" ry="8" width="148" height="130"/>
            ));
        }
        /*
		return (
            <div className="image_menu layout_menu">
                <div className="layout_div" onScroll={this.scrollEnd.bind(this)} ref="layout_div">
                    {this.state.showExpandView ?
                        (<a href="#" className="side-control-panel__back-btn" onClick={this.handleExpandBackClicked.bind(this)}>&lt;Back</a>)
                        : null
                    }

                    <div className={classNames("empty_view", (this.state.loading || renderElements.length) ? 'hide' : '')}>
                        Sorry, we cannot find appropiate content to display, please try something else.
                    </div>
                    <div className={classNames((!this.state.loading ? 'hide' : ''))}>
                        <ContentLoader animate={true} width="316" height={loaderHeight} primaryColor="#262944" secondaryColor="#2e3045">
                        {loaderElements}
                        </ContentLoader>
                    </div>
                    <div className="element_list">
                        {renderElements}
                    </div>
                </div>
            </div>
        );
        */

        const masonryOptions = {
            transitionDuration: 0
        };

        const imagesLoadedOptions = {background: '.layout_div'};

        return (
            <div className="layout_container" style={{width: '100%', height: '100%'}}>
                {this.state.showExpandView ?
                    (<a href="#" className="side-control-panel__back-btn"
                        onClick={this.handleExpandBackClicked.bind(this)}>&lt;Back</a>)
                    : null
                }
                <Masonry
                    className={'layout_div'} // default ''
                    elementType={'ul'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    imagesLoadedOptions={imagesLoadedOptions} // default {}
                    ref={function (c) {
                        this.masonry = this.masonry || c.masonry;
                    }.bind(this)}
                >
                    {renderElements}
                </Masonry>
                <div className={classNames((!this.state.loading ? 'hide' : ''))}>
                    <ContentLoader animate={true} width="316" height={loaderHeight} primaryColor="#E6E7E8">
                        {loaderElements}
                    </ContentLoader>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    width: state.pages.length ? state.pages[0].width : 0,
    height: state.pages.length ? state.pages[0].height : 0,
    myFavouriteFilter: state.myFavouriteFilter,
    hash: state.hash,
});

const mapDispatchToProps = dispatch => ({
    getDesignElement: (element_type, category_id, sub_category_id, keyword, offset, limit, other, brand_token, callback, hash) => dispatch(getDesignElement(element_type, category_id, sub_category_id, keyword, offset, limit, other, brand_token, callback, hash)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LayoutElement);
