import React from 'react';
//import $ from 'jquery';
import classNames from "classnames";
import {connect} from 'react-redux';
import no_img from './no_img.png';
import delete_icon from '../../Page/Delete-page.svg';

import star_unselected from './icon-heart-unselected.png';
import star_selected from './icon-heart-selected.png';

import '../sideControlPanel.scss';

import {
    addElementByData,
    clearActiveElements,
    resetCanvas,
    resetFocusPage,
    saveElementId,
    setDragImageData,
    setMyFavourite,
    showUploadeditemactionbox,
    updateRecentUsedImages
} from '../../../actions';

//import Cookies from 'universal-cookie';

class MenuElement extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            preview_url: props.preview_url,
            json: props.json,
            name_code: props.name_code,
            img_id: props.img,
            filename: props.file_name,
            api_token: props.api_token,
            idx: props.idx,
            show_cover: (props.show_cover == null ? false : props.show_cover),
            load: false,
            is_show: false,
            //canExpand: json && json.pages && json.pages.length > 1,
            canExpand: !!props.canExpand,
            star: props.star == null ? false : props.star,
            element_id: props.element_id,
        };
        if (props.thumbnail_url) {
            this.state.thumbnail_url = props.thumbnail_url;
        }
        //console.log(props.json);
        this.selectElement = this.selectElement.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        //this.onload = this.onload.bind(this);
        this.onload = props.onload;
        this.handleonFocus = this.handleonFocus.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleMyFavourite = this.handleMyFavourite.bind(this);
    }

    selectElement() {

        if (this.state.canExpand && this.props.handleExpand) {
            this.props.handleExpand();
            return;
        }
        if (this.props.permission === "read") {
            return false;
        }

        //this.state.json.type = this.props.type;

        if (this.props.type==='mine') {
            if (this.state.json.pages[0].elements[0].imageWidth===undefined){
                //ev.preventDefault();
                return false;
            }
        }

        if (this.props.type === 'layout') {

            //console.log(this.state.json.pages.length);

            var needConfirm = false;
            try {
                if (this.state.json.pages.length > 1 && this.props.pages[0].elements.length > 1) {
                    needConfirm = true;
                } else if (this.props.pages[this.props.focusPageId].elements.length > 1) {
                    needConfirm = true;
                }
            } catch (e) {
                console.log(e);
            }

            if (needConfirm) {
                var result = window.confirm(this.props.current_lang === 'en' ? "Are you sure overwrite your existing design with this template?" : '你確定以此模板覆蓋現有的設計嗎？');
                if (result) {
                    if (this.state.json.pages.length > 1) {
                        this.props.resetCanvas();
                    } else {
                        this.props.resetFocusPage();
                    }
                } else {
                    return;
                }
            } else {
                if (this.state.json.pages.length > 1) {
                    this.props.resetCanvas();
                } else {
                    this.props.resetFocusPage();
                }
            }

        }

        this.props.addElementByData(JSON.stringify(this.state.json));
        if (this.props.username && (this.props.img_type === "image" || this.props.type === "image")) {
            this.props.updateRecentUsedImages(this.state.element_id, this.state.json.pages[0].elements[0].type)
        }
        if (this.props.onItemSelected) {
            this.props.onItemSelected();
        }
    }

    dragStart(ev) {
        if (this.state.canExpand && this.props.handleExpand) {
            this.props.handleExpand();
            return;
        }
        //console.log(this.props.permission);
        if (this.props.permission === "read") {
            return false;
        }
        if (this.props.type === 'layout' && this.state.json.pages.length > 1) {
            ev.preventDefault();
            return false;
        }
        this.props.clearActiveElements();
        //console.log("drag");
        var json = this.state.json;
        if (json) {
            json.type = this.props.type;

            if(json.type==='mine') {
                let ratioY = 1, ratioX = 1;

                if (json.pages[0].elements[0].imageWidth===undefined){
                    ev.preventDefault();
                    return false;
                }

                if (json.pages[0].height>json.pages[0].elements[0].height) {
                    ratioY = json.pages[0].elements[0].height / json.pages[0].height
                }

                if (json.pages[0].width>json.pages[0].elements[0].width) {
                    ratioX = json.pages[0].elements[0].width / json.pages[0].width
                }

                let ratio = 1;
                if (ratioY > ratioX) {
                    ratio = ratioX
                } else {
                    ratio = ratioY
                }
                if (ratio>1.05) {
                    json.pages[0].elements[0].imageWidth = json.pages[0].elements[0].width = json.pages[0].width * ratio;
                    json.pages[0].elements[0].imageHeight = json.pages[0].elements[0].height = json.pages[0].height * ratio;
                }
            }

            ev.dataTransfer.setData("text", JSON.stringify(json));
            if (json && json.pages && json.pages.length === 1 && json.pages[0].elements && json.pages[0].elements.length === 1) {
                if (json.pages[0].elements[0].type === 'photo' || json.pages[0].elements[0].type === 'graphic' || json.pages[0].elements[0].type === 'background') {
                    json.pages[0].elements[0].lowResImage = this.state.preview_url;
                    json.pages[0].elements[0].imgObj = new Image();
                    json.pages[0].elements[0].imgObj.onload = function () {
                        delete json.pages[0].elements[0].lowResImage;
                        delete json.pages[0].elements[0].imgObj;
                    };
                    json.pages[0].elements[0].imgObj.src = json.pages[0].elements[0].photoUrl;
                    this.props.setDragImageData(json.pages[0].elements[0]);
                }
            }
            if (json && json.elements && json.elements.length === 1) {
                if (json.elements[0].type === 'photo' || json.elements[0].type === 'graphic' || json.elements[0].type === 'background') {
                    json.elements[0].lowResImage = this.state.preview_url;
                    json.elements[0].imgObj = new Image();
                    json.elements[0].imgObj.onload = function () {
                        delete json.elements[0].lowResImage;
                        delete json.elements[0].imgObj;
                    };
                    json.elements[0].imgObj.src = json.elements[0].photoUrl;
                    this.props.setDragImageData(json.elements[0]);
                }
            }
        }
    }

    dragEnd() {
        if (this.state.canExpand && this.props.handleExpand) {
            this.props.handleExpand();
            return;
        }
        if (this.props.permission === "read") {
            return false;
        }
        this.props.setDragImageData(null);
    }

    handleonFocus(e) {
        this.props.showUploadeditemactionbox(parseInt(e.target.dataset.id, 10));
        this.setState({is_show: false});
    }

    handleDelete() {
        this.props.handleDeleteImg(this.state.img_id ? this.state.img_id : this.state.filename);
    }

    handleMyFavourite() {
        //alert("Change Favourite");

        this.props.setMyFavourite(this.state.element_id, !this.state.star);

        this.setState({
            star: !this.state.star,
        });

    }

    textView(preview_url, name_html, thumbnail_url) {

        if (!preview_url) {
            preview_url = no_img;
        }

        if (!thumbnail_url) {
            thumbnail_url = preview_url;
        }

        var img_div = (<img
            className={classNames("element_img", (this.props.pending_resize ? 'pending_resize' : ''), (this.props.type === "background" ? 'background_element' : ''))}
            src={thumbnail_url} onClick={this.selectElement} draggable="true"
            onDragStart={this.dragStart} onDragEnd={this.dragEnd}
            onLoad={this.onload} onError={this.onload}/>);

        const is_show = this.props.uploadedItemsVisibility && (!!this.props.uploadedItemsVisibility.is_display) && (this.props.uploadedItemsVisibility.actionBoxId === this.state.idx) ? 'block' : 'none';

        return (
            <div className="element_div">
                {this.props.type === "mine" ?
                    <div className="element_dotdiv" data-id={this.state.idx} onClick={this.handleonFocus}>
                        <div className="delete_popup_container" style={{display: is_show}}>
                            <div>
                                <div className="delete_style_class">
                                    <div className="delete_container">
                                        <ul className="upload_item_list">
                                            <li className="upload_item">
                                                <div className="upload_file_name_container">
                                                    <div>
                                                        <span className="file_name">{this.state.filename}</span>
                                                    </div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li className="list_item" onClick={this.handleDelete}>
                                                <div className="upload_file_name_container">
                                                    <div>
                                                        <img src={delete_icon}
                                                             className={classNames('page_sidebar_img', this.props.del_btn_class)}/>
                                                        <span
                                                            className="delete_txt">{this.props.current_lang ? 'Delete' : '刪除'}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""}
                <div className="img_container">
                    {this.state.element_id !== undefined && this.state.star !== -1 ? <div className="my_favourite">
                        <img src={this.state.star === 1 ? star_selected : star_unselected}
                             onClick={this.handleMyFavourite}/>
                    </div> : ""}
                    {img_div}
                </div>
                {name_html}
                <div className={classNames(["element_cover", (this.state.show_cover ? "show" : "")])}>&nbsp;</div>
            </div>
        );

    }

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in this.props) {
            if (key === 'pages') {
                continue;
            } else if (key === 'focusPageId') {
                continue;
            } else {
                if (JSON.stringify(this.props[key]) !== JSON.stringify(nextProps[key])) {
                    // console.log('MenuElement shouldComponentUpdate true 1 pages', key, this.props[key], nextProps[key], nextProps.json); //json
                    return true;
                }
            }

        }
        if (!this.props.json || (nextProps.json && JSON.stringify(this.props.json)!==JSON.stringify(nextProps.json))) {
            // console.log('MenuElement shouldComponentUpdate true json', nextProps.json); // MineElement has late update in json for image dimension
            return true;
        }

        for (let key in this.state) {
            if (JSON.stringify(this.state[key]) !== JSON.stringify(nextState[key])) {
                // console.log('MenuElement shouldComponentUpdate true 2 pages', key, this.state[key], nextState[key], nextProps.json);
                return true;
            }
        }
        // console.log('MenuElement shouldComponentUpdate false pages', nextProps.pages);
        return false;
    }

    render() {
        var preview_url = this.state.preview_url;
        var thumbnail_url = this.state.thumbnail_url ? this.state.thumbnail_url : this.state.preview_url;
        var name_code = this.state.name_code;

        var name_html = "";
        if (name_code) {
            name_html = (<div className="element_title noselect">{name_code}</div>);
        }

        return this.textView(preview_url, name_html, thumbnail_url);
    }

}

const mapStateToProps = state => ({
    permission: state.permission,
    current_lang: state.current_lang,
    uploadedItemsVisibility: state.uploadedItemsVisibility,
    pages: state.pages,
    focusPageId: state.focusPageId,
    username: state.username,
    recently_used_img_list: state.recently_used_img_list
});

const mapDispatchToProps = dispatch => ({
    clearActiveElements: () => dispatch(clearActiveElements()),
    addElementByData: (data) => dispatch(addElementByData(data)),
    resetCanvas: () => dispatch(resetCanvas()),
    setDragImageData: (imageData) => dispatch(setDragImageData(imageData)),
    resetFocusPage: () => dispatch(resetFocusPage()),
    showUploadeditemactionbox: (boxId) => dispatch(showUploadeditemactionbox(boxId)),
    setMyFavourite: (element_id, star) => dispatch(setMyFavourite(element_id, star)),
    saveElementId: (element_id, photo_element_id, user_id, callback) => dispatch(saveElementId(element_id, photo_element_id, user_id, callback)),
    updateRecentUsedImages: (element_id, imgtype) => dispatch(updateRecentUsedImages(element_id, imgtype)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MenuElement);
