import React from 'react';
import classNames from "classnames";
import {connect} from 'react-redux';

import './positionPicker.css';

import {getPageIndexByElementHash} from './../../../utilities/canvasData';
//view
import ListComponent from "../List";
import ButtonComponent from "../Button";

class PositionPickerTool extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            position: props.alignment,
            showPositionPicker: true,
            pageIdx: props.pageIdx,
            elementIdx: props.elementIdx,
            elementLength: props.elLength,
            forwardButtonDisabled: (props.elLength - 1) === props.elementIdx,
            backwardButtonDisabled: props.elementIdx === 0,
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        var isDisableForwardbtn = (props.elLength - 1) === props.elementIdx;
        var isDisableBackwardbtn = props.elementIdx === 0;
        if(props.alignment !== this.state.position || isDisableForwardbtn !== this.state.forwardButtonDisabled || isDisableBackwardbtn !== this.state.backwardButtonDisabled)
        this.setState({
            alignment: props.alignment,
            forwardButtonDisabled: isDisableForwardbtn,
            backwardButtonDisabled: isDisableBackwardbtn
        })
    }

    setPosition(position) {
        this.setState({position: position});
        this.props.handlePosition({"position": position});
    }

    toggleAlignmentPicker() {
        //this.setState({showPositionPicker: !this.state.showPositionPicker});
        this.props.handleFontList(this.props.id)
    }

    render() {
        const style = this.props.showList && this.props.showList.listname === this.props.id ? "show_popup_contianer" : "";
        const is_active = !!(this.props.showList && this.props.showList.listname === this.props.id);
        return (
            <div className="toolbar_panel_container">
                <ButtonComponent handleClick={() => this.toggleAlignmentPicker()}
                                 text={this.props.current_lang === 'en' ? "Position" : '排列順序'} is_active={is_active}/>

                <div className={classNames("popup_contianer_align position_right_container", style)}>
                    <div className="square_contianer s_contianer_align">
                        <div id="picker-panel" className="picker-panel">
                            <ListComponent text={this.props.current_lang === 'en' ? "Forwards" : '置前'}
                                           handleClick={this.setPosition.bind(this, 'forward')}
                                           inactive={!this.props.canForward}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Backwards" : '置後'}
                                           handleClick={this.setPosition.bind(this, 'backward')}
                                           inactive={!this.props.canBackward}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "To Front" : "移至最前"}
                                           handleClick={this.setPosition.bind(this, 'to_front')}
                                           inactive={!this.props.canForward}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "To Back" : "移至最後"}
                                           handleClick={this.setPosition.bind(this, 'to_back')}
                                           inactive={!this.props.canBackward}/>
                            <hr/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Top" : '上'}
                                           handleClick={() => this.props.handleAlignment('top')}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Middle" : '上下置中'}
                                           handleClick={() => this.props.handleAlignment('middle')}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Bottom" : '下'}
                                           handleClick={() => this.props.handleAlignment('bottom')}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Left" : '左'}
                                           handleClick={() => this.props.handleAlignment('left')}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Center" : '中心'}
                                           handleClick={() => this.props.handleAlignment('center')}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Right" : '右'}
                                           handleClick={() => this.props.handleAlignment('right')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let canForward = false;
    let canBackward = false;
    let activeElements = state.activeElements.filter(element => element && element.type !== 'group' && element.type !== 'background');

    if (activeElements.length>0 && activeElements[0]) {
        let pageId = getPageIndexByElementHash(state.pages, activeElements[0].hash);
        let pageElements = state.pages[pageId] ? state.pages[pageId].elements.filter(element => element && element.type !== 'group' && element.type !== 'background') : [];

        let maxIndex = -1;
        let minIndex = 999999;
        pageElements.forEach((element, index) => {
            if (state.activeElements.includes(element.hash)) {
                if (maxIndex < index) {
                    maxIndex = index;
                }
                if (minIndex > index) {
                    minIndex = index;
                }
            }
        });
        if (maxIndex < pageElements.length - 1) {
            canForward = true;
        }
        if (minIndex > 0) {
            canBackward = true;
        }
    }/* else if (activeElements) {
        console.error(JSON.stringify(activeElements))
    }*/
    return {
        canForward,
        canBackward,
        current_lang: state.current_lang
    };
};

export default connect(mapStateToProps)(PositionPickerTool);
