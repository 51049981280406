import React from 'react';
import classNames from "classnames";
//css
import './list.css';

export default class List extends React.PureComponent {

    handleClick() {
        if (!this.props.inactive) {
            this.props.handleClick();
        }
    }

    render() {
        const style = this.props.is_active ? "active_btn" : "";
        const header_style = this.props.style === "mainHeader" ? "header_fonts header_border" : "btn_text_width";

        return (
            <button className={classNames("btn_text_container", style, header_style, {inactive: this.props.inactive})} onClick={this.handleClick.bind(this)}>
                <div className="list_box">
                    <div className="label_container">
                        <span className="list_title">{this.props.text}</span>
                    </div>
                </div>
            </button>
        );
    }
}
