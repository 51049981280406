import React from 'react';
import classNames from "classnames";
import {connect} from 'react-redux';

//view
import ListComponent from "../List";
import ButtonComponent from "../Button";

//import { getPageIndexByElementHash, getObjByHash } from  './../../../utilities/canvasData';

class FlipControl extends React.PureComponent {

    flip(direction) {
        this.props.handleFlipImage(direction);
    }

    toggleFlipControl() {
        //this.setState({showPositionPicker: !this.state.showPositionPicker});
        this.props.handleFontList(this.props.id)
    }

    render() {
        const style = this.props.showList && this.props.showList.listname === this.props.id ? "show_popup_contianer" : "";
        const is_active = !!(this.props.showList && this.props.showList.listname === this.props.id);
        return (
            <div className="toolbar_panel_container">
                <ButtonComponent handleClick={() => this.toggleFlipControl()}
                                 text={this.props.current_lang === 'en' ? "Flip" : '鏡射'} is_active={is_active}/>

                <div className={classNames("popup_contianer_align position_left_container", style)}>
                    <div className="square_contianer s_contianer_align">
                        <div id="picker-panel" className="picker-panel">
                            <ListComponent text={this.props.current_lang === 'en' ? "Flip Horizontally" : '水平鏡射'}
                                           handleClick={this.flip.bind(this, 'horizontal')}/>
                            <ListComponent text={this.props.current_lang === 'en' ? "Flip Vertically" : '垂直鏡射'}
                                           handleClick={this.flip.bind(this, 'vertical')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        current_lang: state.current_lang
    }
};

export default connect(mapStateToProps)(FlipControl);
