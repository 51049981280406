import React from 'react';
//import { TwitterPicker, ChromePicker   } from 'react-color';
//import classNames from "classnames";

//import elementControlPanel from '../elementControlPanel.css';

export default class TextColorPicker extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            textColor: props.textColor,
            showColorPicker: props.showColorPicker,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        if(props.showColorPicker !== this.state.showColorPicker || props.textColor !== this.state.textColor) {
            this.setState({
                textColor: props.textColor,
                showColorPicker: props.showColorPicker,
            });
        }
    }

    toggleColorPicker() {
        this.props.handleFontList(this.props.id, this.props.property, this.state.textColor);
    }

    handlePickerChange(color) {
        this.setState({textColor: color.hex});
        if (this.props.property) {
            let obj = {};
            obj[this.props.property] = color.hex;
            this.props.applyPropertyChange(obj);
        } else {
            this.props.applyPropertyChange({"textColor": color.hex});
        }
    }

    render() {
        const currentColor = {
            background: 'repeating-linear-gradient(to right,' + this.state.textColor + ' 0%,' + this.state.textColor + ' 100%)'
        };
        //const style = this.props.showList && this.props.showList.listname === this.props.id ? "show_popup" : "";
        return (
            <div key="color_picker" className="color_picker_btn">
                <button className="bg_color_picker_btn" onClick={() => this.toggleColorPicker()}>
                    <div className="bg_color_picker_div">
                        <div className="bg_color_title" style={currentColor}>
                        </div>
                    </div>
                </button>
                {/* <div className={classNames('color_picker_panel', style)}>
                <TwitterPicker colors={['#000000', '#FFFFFF', '#FF6900', '#FCB900', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7']} color={this.state.textColor} onChange={ this.handlePickerChange.bind(this) }/>
                <ChromePicker    />
            </div> */}
            </div>

        );
    }
}
