//import { combineReducers } from 'redux';

const app = (state = {
  shippingCountries: null,
  paymentCurrencies:null,
  detectedUserCountry: null,
  quote: null,
  variations: {},
  priceOption: {},
  currencyOption: {},
  currency:{},
  checkoutStep: 1,
  address: {},
  isCheckoutLoading: false,
  checkoutError: null,
  createdOrder: null,
  addressError: null,
  printFile: null,
  terms: {
    confirm: false,
    tnc: false,
  }
}, action) => {
  switch (action.type) {
    case 'SET_SHIPPING_COUNTRY_LIST':
      return {...state, shippingCountries: action.shippingCountries};
     case 'SET_PAYMENT_CURRENCY_LIST':
      return {...state, paymentCurrencies: action.paymentCurrencies};
    case 'SET_DETECTED_USER_COUNTRY':
      return {...state, detectedUserCountry: action.country};
    case 'SET_QUOTE':
      return {...state, quote: action.quote?JSON.parse(JSON.stringify(action.quote)):action.quote};
    case 'SET_VARIATION':
      var variations = JSON.parse(JSON.stringify(state.variations));
      variations[action.key] = action.value;
      return {...state, variations: variations};
    case 'SET_PRICE_OPTION':
      return {...state, priceOption: action.value};
    case 'SET_SHIPPING_METHOD':
      return {...state, shippingMethod: action.shippingMethod};
    case 'SET_CURRENCY':
      return {...state, currencyOption: action.curr};
    case 'NEXT_CHECKOUT_STEP':
        let newStep = state.checkoutStep;
        if (newStep < 7) {
        newStep++;
      }
      return {...state, checkoutStep: newStep};
    case 'RESET_CHECKOUT_STEP':
      return {...state, checkoutStep: 1, createdOrder: null, checkoutError: null, terms: {confirm: false, tnc: false}, printFile: null};
    case 'PREV_CHECKOUT_STEP':
        newStep = state.checkoutStep;
        if (newStep > 1) {
        newStep--;
      }
      return {...state, checkoutStep: newStep};
    case 'SET_ADDRESS':
      var address = JSON.parse(JSON.stringify(state.address));
      address[action.field] = action.value;
      return {...state, address: address};
    case 'SET_PRINTFILE':
      return {...state, printFile: JSON.parse(JSON.stringify(action.printFile))};
    case 'SET_CHECKOUT_LOADING':
      return {...state, isCheckoutLoading: action.isLoading};
    case 'SET_CHECKOUT_ERROR':
      return {...state, checkoutError: action.error};
    case 'SET_CHECKOUT_ORDER':
      return {...state, createdOrder: action.order};
    case 'SET_ADDRESS_ERROR':
      return {...state, addressError: action.error};
    case 'SET_TERM_CONFIRM':
      var terms = JSON.parse(JSON.stringify(state.terms));
      terms[action.term] = action.confirm;
      return {...state, terms};
    default:
      return state
  }

};

export default app
